//list all datasets.
import * as angular from 'angular';
import { serverUrl } from "../../../../config";

import { Grid } from "ag-grid-community";
import 'ag-grid-enterprise';

var datasets_list = ['$scope', 'DatasetService', 'ProjectService', 'CommonService', '$uibModal', '$window',
    function (scope, DatasetService, ProjectService, CommonService, $uibModal, $window) {
        console.log('dataset list controller called');
        scope.datasets = DatasetService.getDatasetsList();

        scope.changeFilterIcons = function (event) {
            // We need to call this onFilterModified and onBodyScroll, as the elements are removed/added
            // to the DOM when scrolling horizontally on wide datasets.
            // Can also pass $scope.dataAgGridOptions to this function rather than an event.
            let filters = scope.agGridOptions.api.getFilterModel();
            console.log(filters);

            for (let col of event.api.columnController.columnDefs) {
                for (const span of document.querySelectorAll("span")) {
                    if (span.textContent.includes(col.field) || span.textContent.includes(col.headerName)) {
                        // Naming conventions vary, so lets check all of these to see if the column has a filter
                        if (col.Label in filters || col.headerName in filters || col.field in filters) {
                            // If column has a filter change the icon color
                            // @ts-ignore
                            let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];
                            console.log(icon);
                            icon.style.color = "#007bff";
                        }
                        else {
                            // Remove the color prop from the icon if there is no filter
                            // @ts-ignore
                            let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];// filterIcon.children[0];
                            if (icon && icon.style) {
                                icon.style.color = "#bdbdbd";
                            }
                        }
                    }
                }
            }
        };

        scope.datasets.$promise.then(function () {

            angular.forEach(scope.datasets, function (dataset, key) {
                //need to bump this to get the route
                DatasetService.configureDataset(dataset, scope);
            });

            var visibleDatasets = [];

            scope.datasets.forEach(function (dataset) {
                //need to bump this to get the route
                DatasetService.configureDataset(dataset, scope);

                //hide if the flag is flipped
                if (!dataset.Config.Hide)
                    visibleDatasets.push(dataset);
            });

            scope.datasets = visibleDatasets;

            var agCellRendererName = function (params) {
                return '<div>' +
                    '<a title="' + params.node.data.Description
                    + '" href="' + params.node.data.activitiesRoute + '/' + params.node.data.Id + '">'
                    + params.node.data.Name + '</a>' +
                    '</div>';
            };

            var agColumnDefs = [
                { field: 'Name', headerName: 'Dataset Name', sort: 'asc', cellRenderer: agCellRendererName, width: 300, menuTabs: ['filterMenuTab'], filter: 'text' },
                { field: 'ProjectName', headerName: 'Project', width: 300, menuTabs: ['filterMenuTab'], filter: true },
                { field: 'DatastoreName', headerName: 'Type', width: 300, menuTabs: ['filterMenuTab'], filter: true },
            ];

            scope.agGridOptions = {
                columnHoverHighlight: false,
                animateRows: true,
                overlayLoadingTemplate: '<img src="assets/images/FishLoading.gif" />',
                suppressPropertyNamesCheck: true,
                // showToolPanel: false,
                columnDefs: agColumnDefs,
                rowData: undefined,
                debug: false,
                onGridReady: function (params) {
                    params.api.sizeColumnsToFit();
                },
                onBodyScroll: function (event) {
                    scope.changeFilterIcons(event);
                },
                onFilterModified: function (event) {
                    scope.changeFilterIcons(event);
                },
                defaultColDef: {
                    editable: false,
                    sortable: true,
                    resizable: true,
                    icons: {
                        menu: "<span class='material-symbols-outlined' id='filter-icon' title='Filter'>filter_alt</span>", filter: "<span></span>",
                    }
                },
            };

            var ag_grid_div = document.querySelector('#datasets-list-grid') as HTMLElement;    //get the container id...
            scope.ag_grid = new Grid(ag_grid_div, scope.agGridOptions); //bind the grid to it.

            scope.agGridOptions.api.setRowData(scope.datasets);
        });
    }
];

export default datasets_list;
