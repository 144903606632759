import * as angular from 'angular';

import { Grid } from "ag-grid-community";
import 'ag-grid-enterprise';

var admin_view = ['$scope', '$uibModal', 'DatasetService', 'AdminService',
  function ($scope, $uibModal, DatasetService, AdminService) {

    if (!$scope.Profile.isAdmin()) {
      angular.rootScope.go("/unauthorized");
    }


    $scope.datastoresIgnore = ["ActivitySystemFields", "LocationSystemFields"];

    $scope.datastores = DatasetService.getDatastores();
    $scope.datasets = DatasetService.getAllDatasetsList();

    $scope.datastores.$promise.then(function () {
      $scope.datasets = DatasetService.getAllDatasetsList();

      $scope.datasets.$promise.then(function () {

        var CellRendererDataset = function (params) {
          return '<div>' +
            '<a title="' + params.node.data.Description
            + '" href="admin-dataset/' + params.node.data.Id + '">'
            + params.node.data.Name + '</a>' +
            '</div>';
        };

        var CellRendererDatastore = function (params) {
          return '<div>' +
            '<a title="' + params.node.data.Description
            + '" href="admin-master/' + params.node.data.Id + '">'
            + params.node.data.Name + '</a>' +
            '</div>';
        };

        var datasetColumnDefs = [
          {
            field: 'Name',
            headerName: 'Dataset Name',
            cellRenderer: CellRendererDataset,
            width: 300,
            menuTabs: ['filterMenuTab'],
            filter: 'text',
            sort: 'asc'
          },
          {
            field: 'DatastoreName',
            headerName: 'Datastore Name',
            width: 280,
            menuTabs: ['filterMenuTab'],
            filter: 'text'
          },
          { field: 'ProjectName', headerName: 'Project', width: 300, menuTabs: ['filterMenuTab'], filter: 'text' },
          {
            field: 'hasActivity',
            headerName: "Activities Exist",
            width: 200,
            menuTabs: ['filterMenuTab'],
            filter: 'text',
            valueGetter: function (data) {
              if (data.data.hasActivity === "unknown") {
                return "";
              }
              else if (data.data.hasActivity === "yes") {
                return "Yes";
              }
              else if (data.data.hasActivity === "no") {
                return "No";
              }
            },
          }
        ];

        $scope.datasetGridOptions = {
          //suppressPropertyNamesCheck: true,
          columnDefs: datasetColumnDefs,
          rowData: [],
          onGridReady: function (params) {
            params.api.sizeColumnsToFit();
            setTimeout(() => {
              $scope.refreshGrid();
            }, 300)
          },
          selectedItem: null,
          rowSelection: 'single',
          onSelectionChanged: function (params) {
            $scope.datasetGridOptions.selectedItem = $scope.datasetGridOptions.api.getSelectedRows()[0];
            $scope.$apply();
            console.log($scope.datasetGridOptions.selectedItem);
          },
          onBodyScroll: function (event) {
            $scope.changeFilterIcons(event, "dataset");
          },
          onFilterModified: function (event) {
            $scope.changeFilterIcons(event, "dataset");
          },
          defaultColDef: {
            sortable: true,
            resizable: true,
            icons: {
              menu: "<span class='material-symbols-outlined' id='filter-icon' title='Filter'>filter_alt</span>", filter: "<span></span>",
            }
          },
        };

        var datastoreColumnDefs = [
          {
            field: 'Name',
            headerName: 'Datastore Name',
            cellRenderer: CellRendererDatastore,
            width: 280,
            menuTabs: ['filterMenuTab'],
            filter: 'text',
            sort: 'asc'
          },
          { field: 'TableType', headerName: 'Table Type', width: 280, menuTabs: ['filterMenuTab'], filter: 'text' },
          {
            field: 'hasDataSet',
            headerName: "Datasets Exist",
            width: 200,
            menuTabs: ['filterMenuTab'],
            filter: 'text',
            valueGetter: function (data) {
              if (data.data.hasDataset) return "Yes"; else return "No";
            },
          }
        ];

        $scope.datastoreGridOptions = {
          columnDefs: datastoreColumnDefs,
          rowData: [],
          onGridReady: function (params) {
            params.api.sizeColumnsToFit();
            setTimeout(() => {
              $scope.refreshGrid();
            }, 300)
          },
          selectedItem: null,
          rowSelection: 'single',
          onSelectionChanged: function (params) {
            $scope.datastoreGridOptions.selectedItem = $scope.datastoreGridOptions.api.getSelectedRows()[0];
            $scope.$apply();
          },
          onBodyScroll: function (event) {
            $scope.changeFilterIcons(event, "datastore");
          },
          onFilterModified: function (event) {
            $scope.changeFilterIcons(event, "datastore");
          },
          defaultColDef: {
            sortable: true,
            resizable: true,
            icons: {
              menu: "<span class='material-symbols-outlined' id='filter-icon' title='Filter'>filter_alt</span>", filter: "<span></span>",
            }
          },
        };

        $scope.datasets.forEach(async function (dataset) {

          dataset.hasActivity = "unknown";

          DatasetService.getActivities(dataset.Id, 1).then(function (result) {

            if (result.length == 1) {
              dataset.hasActivity = "yes";
            } else {
              dataset.hasActivity = "no";
            }
          })

        })

        $scope.tempDatastores = [];

        //see which datastores have a dataset already and remove any datastore in our ignore list
        $scope.datastores.forEach(function (datastore, index) {

          if (!$scope.datastoresIgnore.contains(datastore.Name)) {
            $scope.tempDatastores.push(datastore);
          }

          //iterate the datasets and mark this datastore as having a dataset if it does
          $scope.datasets.forEach(function (dataset) {
            if (dataset.DatastoreId == datastore.Id) {
              datastore.hasDataset = true;
              return;
            }
          })

          if (!datastore.hasDataset) {
            datastore.hasDataset = false;
          }

        })

        $scope.datastores = $scope.tempDatastores;

        var dataset_grid_div = document.querySelector('#datasets-list-grid') as HTMLElement;    //get the container id...
        $scope.dataset_grid = new Grid(dataset_grid_div, $scope.datasetGridOptions); //bind the grid to it.
        $scope.datasetGridOptions.api.setRowData($scope.datasets);

        var datastore_grid_div = document.querySelector('#datastores-list-grid') as HTMLElement;    //get the container id...
        $scope.datastore_grid = new Grid(datastore_grid_div, $scope.datastoreGridOptions); //bind the grid to it.
        $scope.datastoreGridOptions.api.setRowData($scope.datastores);
      })


    });

    $scope.changeFilterIcons = function (event, grid) {
      // We need to call this onFilterModified and onBodyScroll, as the elements are removed/added
      // to the DOM when scrolling horizontally on wide datasets.
      // Can also pass $scope.dataAgGridOptions to this function rather than an event.
      let filters;
      if (grid == "dataset") {
        filters = $scope.datasetGridOptions.api.getFilterModel();
      }
      else {
        filters = $scope.datastoreGridOptions.api.getFilterModel();
      }


      for (let col of event.api.columnController.columnDefs) {
        for (const span of document.querySelectorAll("span")) {
          if (span.textContent.includes(col.field) || span.textContent.includes(col.headerName)) {
            // Naming conventions vary, so lets check all of these to see if the column has a filter
            if (col.Label in filters || col.headerName in filters || col.field in filters) {
              // If column has a filter change the icon color
              // @ts-ignore
              let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];
              icon.style.color = "#007bff";
            }
            else {
              // Remove the color prop from the icon if there is no filter
              // @ts-ignore
              let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];// filterIcon.children[0];
              if (icon && icon.style) {
                icon.style.color = "#bdbdbd";
              }
            }
          }
        }
      }
    };

    $scope.createDatastore = function () {
      var modalInstance = $uibModal.open({
        templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/modal-datastore.html',
        controller: 'ModalDatastore',
        scope: $scope, //very important to pass the scope along...
        backdrop: "static",
        keyboard: false
      }).result.then(function (saved_datastore) {
        saved_datastore.hasDataset = false;
        $scope.hasNewDatastore = true;
        $scope.datastores.push(saved_datastore);
        $scope.datastoreGridOptions.api.setRowData($scope.datastores);
      });

    };

    $scope.deleteDataset = function () {
      if (confirm("Are you sure you want to delete this dataset? There is no undo.")) {
        console.log($scope.datasetGridOptions.selectedItem);
        var deleted = AdminService.deleteDataset($scope.datasetGridOptions.selectedItem.Id);
        deleted.$promise.then(function () {
          $scope.datasets.forEach(function (store, index) {
            if (store.Id == $scope.datasetGridOptions.selectedItem.Id) {
              $scope.datasets.splice(index, 1);
            }
          });
          $scope.datasetGridOptions.selectedItem = null;
          $scope.datasetGridOptions.api.setRowData($scope.datasets);
        }, function (error) {
          console.log("there was an error.");
          console.dir(error);
        })
      }
    }

    $scope.removeDatastore = function () {
      if (confirm("Are you sure you want to delete this datastore, fields, and table(s)? There is no Undo.")) {
        var deleted = AdminService.removeDatastore($scope.datastoreGridOptions.selectedItem.Id);
        deleted.$promise.then(function () {
          $scope.datastores.forEach(function (store, index) {
            if (store.Id == $scope.datastoreGridOptions.selectedItem.Id) {
              $scope.datastores.splice(index, 1);
            }
          });
          $scope.datastoreGridOptions.selectedItem = null;
          $scope.datastoreGridOptions.api.setRowData($scope.datastores);
        }, function (error) {
          console.log("there was an error.");
          console.dir(error);
        })
      }
    }

    $scope.refreshGrid = function () {
      $scope.datasetGridOptions.api.refreshCells({ force: true });
    }

    $scope.regenerateViews = function () {
      if (confirm("Are you sure you want to regenerate views for all datasets? There is no Undo.")) {
        $scope.RegenerationResults = AdminService.regenerateViews();
      }
    }
  }
];

export default admin_view;
