//modal to edit location
import { modalFiles_setupControllerForFileChooserModal } from "../../../common/components/file/modal-files";
import { loadModules } from "esri-loader";
import { GEOMETRY_SERVICE_URL, NAD83_SPATIAL_REFERENCE, uiConfig } from '../../../../config';
// import { EsriMapComponent } from "src/app/map/esri-map/esri-map.component";

var modal_edit_location = ['$scope', '$uibModal', '$uibModalInstance', 'GridService', 'CommonService', 'Upload', 'ProjectService', '$rootScope', //'PermitService',

  function ($scope, $uibModal, $uibModalInstance, GridService, CommonService, $upload, ProjectService, $rootScope) { //, PermitService) {

    console.log("Inside modal-edit-location.ts...");
    //geometryService: geometryService;

    $scope.mode = "edit";
    $scope.OriginalEasting = 0;
    $scope.OriginalNorthing = 0;
    var symbol = null;

    if ($scope.ProjectLocation_modal) {
      $scope.row = $scope.ProjectLocation_modal;
      $scope.dataGridOptions = $scope.permitLocationsGrid;
    }

    if (!$scope.row.Id) {
      $scope.mode = "new";
    }
    else {
      $scope.OriginalEasting = $scope.row.GPSEasting;
      $scope.OriginalNorthing = $scope.row.GPSNorthing;
    }

    modalFiles_setupControllerForFileChooserModal($scope, $uibModal, $scope.project.Files);

    // $scope.saveNewPoint = function (inId) {
    //   if (inId.pointId) {
    //     $scope.row.SdeObjectId = inId.pointId
    //     console.log("Created a new point! " + $scope.row.SdeObjectId);
    //
    //     var data = {
    //       ProjectId: $scope.project.Id,
    //     };
    //
    //     var target = '/api/v1/file/UploadProjectFile';
    //
    //     $scope.handleFilesToUploadRemove($scope.row, data, target, $upload, $rootScope); //when done (handles failed files, etc., sets in scope objects) then calls modalFiles_saveParentItem below.
    //
    //   } else {
    //     $scope.SaveMessage = "There was a problem saving that location.";
    //     console.dir(inId.pointId);
    //   }
    // }

    $scope.updateParentItem = function () {

      // if ($scope.ProjectLocation_modal){
      //   $scope.PermitLocations = PermitService.getPermitLocations($scope.row.PermitId);
      //   $scope.PermitLocations.$promise.then(function() {
      //     console.log("$scope.PermitLocations modal-edit-location.ts is next...");
      //     console.dir($scope.PermitLocations);
      //     $scope.permitLocationsGrid.api.setRowData($scope.PermitLocations);
      //     $scope.permitLocationsGrid.selectedItem = null;
      //     $scope.$apply();
      //   });
      // }
      // else 
      // {
      $scope.all_locations = ProjectService.getLocations($scope.project.Id);
      $scope.all_locations.$promise.then(function () {
        $scope.dataGridOptions.api.setRowData($scope.all_locations);
      });
      // }

    };

    $scope.save = function (inId) {
      console.log("Inside $scope.save...");

      //OK -- if we are saving a NEW location then start off by adding the point to the featurelayer
      if (!$scope.row.Id) {
        console.log("Adding a NEW location...");

        if (uiConfig.usesMap) {
          $uibModalInstance.close(
            // on close this information is sent to the esri-map component, and enters as the inPayload object.
            {
              'ProjectId': $scope.project.Id,
              'Location': $scope.row,
              'IsFirstRun': true
            });

        } else {
          // LocationController.cs will overwrite user input to current datetime, but API requires a time
          console.log("Adding a location to the database, not using the map");
          if ($scope.row.CreateDateTime == null) {
            let date = new Date();
            let dt = date.toJSON();
            $scope.row.CreateDateTime = dt;
            $scope.saveResult = CommonService.saveNewProjectLocation($scope.project.Id, $scope.row);
            $scope.saveResult.$promise.then(function () {
              $scope.updateParentItem();
            });
            $uibModalInstance.close();
          } else {
            $scope.saveResult = CommonService.saveNewProjectLocation($scope.project.Id, $scope.row);
            $scope.saveResult.$promise.then(function () {
              $scope.updateParentItem();
            });
            $uibModalInstance.close();
          }
        }

      } else //updating an existing...
      {
        //------------------------------------------------------------
        // The current process for updating a point is...
        // - Capture the SdeObjectId for the original point
        // - Add a new one point, with the desired specs, and set the SdeObjectId to be that of the new point
        // - Delete the old point
        //------------------------------------------------------------

        // $uibModalInstance.close(
        //   // on close this information is sent to the esri-map component
        //   {
        //     'Update': true,
        //     'ProjectId': $scope.project.Id,
        //     'Location': $scope.row,
        //     'IsFirstRun': true
        //   });
        var data = {
          ProjectId: $scope.project.Id,
        };


        // The ngOnChanges in esri-map.component.ts works with a project-level location,
        // but not from a permits-level (dataset-level) location.
        // ($scope.ProjectLocation_modal exists)
        if ($scope.ProjectLocation_modal) {
          var locationObject = {
            'Update': true,
            'ProjectId': $scope.project.Id,
            'Location': $scope.row,
          }

          //var esriMapComponent = new EsriMapComponent();

          //esriMapComponent.modalLocationEdit(locationObject);
        }
        // Capture the old SdeObjectId, so we can delete it later.
        //   $scope.OldSdeObjectId = $scope.row.SdeObjectId;
        //
        //
        //   // $scope.map.locationLayer.applyEdits([$scope.newGraphic], null, null).then(function (addResults) {
        //   if (inId.hasOwnProperty('pointId')) {
        //     // $scope.row.SdeObjectId = addResults[0].objectId;
        //     // console.log("Added new point! " + $scope.row.SdeObjectId);
        //
        //     //throw "Stopping right here...";
        //
        //     // var attributes = {
        //     //   OBJECTID: $scope.OldSdeObjectId
        //     // }
        //     // var deleteGraphic = new Graphic($scope.newPoint, null, attributes);
        //
        //     // Note:  You cannot just pass the point to delete; they must be in a graphic object,
        //     //        or the code dies in dojo-land, which is different than what is shown in
        //     //        ArcGIS documenation on the service interface.
        //     //$scope.map.locationLayer.applyEdits(null, null, pointsToDelete).then(function (deleteResults) {
        //     //$scope.map.locationLayer.applyEdits(null, null, $scope.aryDeletes).then(function (deleteResults) {
        //     // $scope.map.locationLayer.applyEdits(null, null, [deleteGraphic], function (addResults, updateResults, deleteResults) {
        //
        //     //var query = new Query();
        //     //query.objectIds = [deletes[0].objectId];
        //     //$scope.map.locationLayer.selectFeatures(query, )
        //     // console.log("deleteResults is next...");
        //     // console.dir(deleteResults[0]);
        //
        //     //.then(function (deleteResults) {
        //     if (inId.failed == "The location did not exist in SDE.") {
        //       $scope.SaveMessage = "The location did not exist in SDE.";
        //       console.dir(inId);
        //     } else if (inId.hasOwnProperty('pointId')) {
        //       console.log("Deleted old point in sdevector! " + $scope.row.SdeObjectId);
        //
        //       //CommonService.UpdateLocationAction($scope.project.Id, $scope.row.Id, $scope.row.SdeObjectId, $scope.OldSdeObjectId);
        //CommonService.updateLocationAction($scope.project.Id, $scope.row.Id, $scope.row.SdeObjectId);
        $scope.saveResult = CommonService.updateLocationAction($scope.project.Id, $scope.row.Id, $scope.row.SdeObjectId, $scope.row);
        $scope.saveResult.$promise.then(function () {
          $scope.updateParentItem();
        });
        //CommonService.updateLocationAction($scope.project.Id, $scope.row.Id, $scope.row.SdeObjectId, $scope.row);
        //     } else {
        //       $scope.SaveMessage = "There was a problem deleting that location.";
        //       console.dir(inId);
        //     }
        //     // }
        //     // ,
        //
        //     // function (err) {
        //     //   console.log("Apply Edits - Delete - failed:  " + err.message);
        //     // }
        //
        //     // );
        //
        var data = {
          ProjectId: $scope.project.Id,
        };
        //
        var target = '/api/v1/file/UploadProjectFile';
        $scope.handleFilesToUploadRemove($scope.row, data, target, $upload, $rootScope, $scope); //when done (handles failed files, etc., sets in scope objects) then calls modalFiles_saveParentItem below.
        //   } else {
        //     $scope.SaveMessage = "There was a problem adding the new location.";
        //     console.dir(inId);
        //   }
        //
        //
        //   // });
        //   // });
        //
        //   var target = '/api/v1/file/UploadProjectFile';
        //
        //  $scope.handleFilesToUploadRemove($scope.row, data, target, $upload, $rootScope); //when done (handles failed files, etc., sets in scope objects) then calls modalFiles_saveParentItem below.
        //
      }
      // }); //require

    };

    //call back from save above once the files are done processing and we're ready to save the item
    $scope.modalFile_saveParentItem = function (saveRow) {
      console.log("Insilde modal-edit-location.ts, modalFile_saveParentItem...");

      saveRow.WaterBody = undefined;

      var new_location = CommonService.saveNewProjectLocation($scope.project.Id, saveRow);
      new_location.$promise.then(function () {
        console.log("done and success!");
        //return new_location;
        //$uibModalInstance.close(new_location);
        $uibModalInstance.dismiss();
        //$scope.$apply();
        window.location.reload();
      });

    };

    //callback that is called from modalFile to do the actual file removal (varies by module)
    $scope.modalFile_doRemoveFile = function (file_to_remove, saveRow) {
      console.dir(file_to_remove);
      return ProjectService.deleteFile($scope.project.Id, file_to_remove);
    };

    //used as a filter to exclude the edit link - only show bonafide fields
    $scope.hasDbColumnName = function (field) {
      return field.hasOwnProperty('DbColumnName');
    };

    $scope.onHeaderEditingStopped = function (field) {
      //build event to send for validation
      console.log("onHeaderEditingStopped: " + field.DbColumnName);
      var event = {
        colDef: field,
        node: { data: $scope.row },
        value: $scope.row[field.DbColumnName],
        type: 'onHeaderEditingStopped'
      };

      if (GridService.validateCell(event, $scope)) {
        GridService.fireRule("OnChange", event); //only fires when valid change is made
      }

      //update our collection of header errors if any were returned
      $scope.headerFieldErrors = [];
      if ($scope.row.rowHasError) {
        $scope.row.validationErrors.forEach(function (error) {
          if (Array.isArray($scope.headerFieldErrors[error.field.DbColumnName])) {
            $scope.headerFieldErrors[error.field.DbColumnName].push(error.message);
          } else {
            $scope.headerFieldErrors[error.field.DbColumnName] = [error.message];
          }
        });
      }

    };

    //fire validation for all columns when we load (if we are editing)
    if ($scope.mode === 'edit') {
      console.log("Location Id = " + $scope.$parent.row.Id);

      $scope.dataGridOptions.columnDefs.forEach(function (field) {
        $scope.onHeaderEditingStopped(field);
      });
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss();
    };


  }
];


export default modal_edit_location;