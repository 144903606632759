import {Injectable} from '@angular/core';
import {buildFinalResults, buildRandomString10, getNumberFromPlace} from "./login-functions";
import {serviceUrl} from "../../../appjsLegacy/config";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) {
  }

  login(username, password) {
    console.log("Inside LoginSvc, login...");
    return this.http.post(serviceUrl + '/api/v1/account/login', {Username: username, Password: password});
  }

  logout() {
    console.log("Inside LoginSvc, logout...");
    return this.http.get(serviceUrl + '/api/v1/account/logout', {responseType: 'text'});
  }

  // retrieveNumber() {
  //   console.log("Inside retrieveNumber...");
  //   return this.http.get(serviceUrl + '/api/projects', {params: {id: 'id'}, responseType: 'json'},);
  // }

  

  encrypt(strText) {
    //var strServerNumber = "";
    //var strClientNumber = "";
    var strFirstNumber = "";
    var strSecondNumber = "";
    var strPassword = ""
    var originalTextList = [];

    var strFinalResult = "";
    //var strLastDigit = "";

    var strOriginalText = strText;
    //console.log("strOriginalText = " + strOriginalText);

    var oText = {
      strText: "",
      intNumber: null
    };


    oText.strText = "";
    oText.intNumber = -1;
    //oText.intSize = -1;

    strFirstNumber = buildRandomString10();
    //strFirstNumber = "8989898989"; // Use this for development/testing.
    //console.log("strFirstNumber = " + strFirstNumber);

    strSecondNumber = buildRandomString10();
    //strSecondNumber = "7878787878"; // Use this for development/testing.
    //console.log("strSecondNumber = " + strSecondNumber);

    for (let i = 0; i < strOriginalText.length; i++) {
      oText = {
        strText: "",
        intNumber: null
      };

      oText.strText = strOriginalText.substr(i, 1);
      oText.intNumber = strOriginalText.charCodeAt(i);

      originalTextList.push(oText);
    }
    //console.log("originalTextList is next...");
    //console.dir(originalTextList);

    //writeResults(originalTextList);

    var intFirstNumberLength = strFirstNumber.length;
    //console.log("intFirstNumberLength = " + intFirstNumberLength);

    for (var i = 0; i < intFirstNumberLength; i++) {
      // Step1:  1st digit tells what digit in second number to multiply by.

      // angular.forEach (originalTextList, function(item){
      for (let item of originalTextList) {
        //console.log("strFirstNumber[i] = " + strFirstNumber[i]);
        //console.log("item.strText = " + item.strText + ", ");
        //console.log("i = " + i);
        //console.log("parseInt(strFirstNumber.substr(i, 1)) = " + parseInt(strFirstNumber.substr(i, 1)));
        //console.log("strSecondNumber = " + strSecondNumber);

        if ((i === 0) || (i === 3) || (i === 6) || (i === 9)) {
          item.intNumber = item.intNumber + getNumberFromPlace(parseInt(strFirstNumber.substr(i, 1)), strSecondNumber);
        } else if ((i === 1) || (i === 4) || (i === 7)) {
          item.intNumber = item.intNumber * getNumberFromPlace(parseInt(strFirstNumber.substr(i, 1)), strSecondNumber);
        } else if ((i === 2) || (i === 5) || (i === 8)) {
          item.intNumber = item.intNumber - getNumberFromPlace(parseInt(strFirstNumber.substr(i, 1)), strSecondNumber);
        }
        //writeResults(originalTextList);
      }
    }


    strPassword = buildFinalResults(originalTextList, strFirstNumber, strSecondNumber);
    //console.log("strPassword = " + strPassword);

    return strPassword;
  }


}


// ['LoginRequest','LogoutRequest','RetrieveNumber', function(LoginRequest, LogoutRequest, RetrieveNumber){
// login_module.factory('RetrieveNumber',['$resource', function(resource){
//     return resource(serviceUrl +'/api/v1/account/getnumber', {}, {
// 			query: {method: 'GET', params: {}, isArray: false}
// 		});
//         //return resource(serviceUrl+'/data/GetNumber', {}, {
// 		//	query: {method: 'GET', params: {}, isArray: false}
// 		//});
// }]);
// //mod.factory('Project',['$resource', function($resource){
// //        return $resource(serviceUrl+'/api/projects', {}, {
// //            query: {method: 'GET', params: {id:'id'}, isArray: false}
// //        });
// //}]);
//
// login_module.factory('LoginRequest',['$resource', function(resource){
//         return resource(serviceUrl+'/api/v1/account/login');
// }]);
//
// login_module.factory('LogoutRequest', ['$resource', function(resource){
//     return resource(serviceUrl +'/api/v1/account/logout', {}, { query: {method: 'GET', params: {}, isArray: false}});
// }]);
