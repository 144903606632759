/*
*   This page loads the project details. It includes some tabs that are always populated and some tabs
*   that are conditionally shown and populated depending on the project type.
*
*/
import { Grid } from "ag-grid-community";
import 'ag-grid-enterprise';

var project_data = ['$scope', '$routeParams', 'SubprojectService', 'ProjectService', 'DatasetService', 'CommonService', 'UserService',
  '$rootScope', '$uibModal', '$sce', '$window', '$http',
  'ServiceUtilities', '$location', '$anchorScroll', '$rootScope',
  function (scope, routeParams, SubprojectService, ProjectService, DatasetService, CommonService, UserService, $rootScope, $uibModal, $sce, $window, $http,
    ServiceUtilities, $location, $anchorScroll, rootScope) {
    //console.log("Inside controllers.js, projectDatasetsController...");
    //console.log("routeParams.Id = " + routeParams.Id);
    //   scope.OnTab = "Files";
    scope.OnTab = 'Data';
    // rootScope.Tab = 'Data';
    scope.datasets = ProjectService.getProjectDatasets(routeParams.Id);
    scope.project = ProjectService.getProject(routeParams.Id);

    //once the datasets load, make sure each is configured with our scope.
    scope.datasets.$promise.then(function () {

      var visibleDatasets = [];

      scope.datasets.forEach(function (dataset) {
        DatasetService.configureDataset(dataset, scope);  // We must pass the scope along on this call.
        if (!dataset.Config.Hide)
          visibleDatasets.push(dataset);
      })

      scope.datasets = visibleDatasets;

      scope.activateDataGrid();
    });


    var linkTemplate = function (param) {

      var div = document.createElement('div');

      var linkBtn = document.createElement('a');
      linkBtn.href = param.data.activitiesRoute + '/' + param.data.Id;
      linkBtn.innerHTML = param.data.Name;

      div.appendChild(linkBtn);

      return div;
    };

    //datasets tab grid
    scope.dataGridOptions = {
      suppressPropertyNamesCheck: true,
      //data: 'datasets',
      //enableSorting: true,
      //enableFilter: true,
      //enableColResize: true,
      onGridReady: function (params) {
        params.api.sizeColumnsToFit();
      },
      columnDefs:
        [
          { field: 'Name', headerName: 'Dataset Name', cellRenderer: linkTemplate, width: 280 },
          { field: 'Description', headerName: 'Description', width: 450 },
        ],
      defaultColDef: {
        sortable: true,
        resizable: true,
      },
    };

    scope.activateDataGrid = function () {

      var ag_grid_div = document.querySelector('#data-tab-grid') as HTMLElement;    //get the container id...
      scope.datatab_ag_grid = new Grid(ag_grid_div, scope.dataGridOptions); //bind the grid to it.
      scope.dataGridOptions.api.showLoadingOverlay(); //show loading...

      scope.dataGridOptions.api.setRowData(scope.datasets);
      scope.dataGridOptions.api.sizeColumnsToFit(); //

    };

    //handle favorite toggle
    scope.isFavorite = $rootScope.Profile.isProjectFavorite(routeParams.Id);
    scope.toggleFavorite = function () {
      UserService.toggleFavoriteProject(scope, $rootScope);
    }

  }

];


export default project_data;



