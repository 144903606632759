import * as angular from 'angular';
import * as moment from 'moment';

import { Grid } from "ag-grid-community";
import 'ag-grid-enterprise';

var admin_users = ['$scope', '$uibModal', 'CommonService',
    function (scope, $uibModal, CommonService) {

        if (!scope.Profile.isAdmin())
            angular.rootScope.go("/unauthorized");

        scope.changeFilterIcons = function (event) {
            // We need to call this onFilterModified and onBodyScroll, as the elements are removed/added
            // to the DOM when scrolling horizontally on wide datasets.
            // Can also pass $scope.dataAgGridOptions to this function rather than an event.
            let filters = scope.agGridOptions.api.getFilterModel();
            console.log(filters);

            for (let col of event.api.columnController.columnDefs) {
                for (const span of document.querySelectorAll("span")) {
                    if (span.textContent.includes(col.field) || span.textContent.includes(col.headerName)) {
                        // Naming conventions vary, so lets check all of these to see if the column has a filter
                        if (col.Label in filters || col.headerName in filters || col.field in filters) {
                            // If column has a filter change the icon color
                            // @ts-ignore
                            let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];
                            icon.style.color = "#007bff";
                        }
                        else {
                            // Remove the color prop from the icon if there is no filter
                            // @ts-ignore
                            let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];// filterIcon.children[0];
                            if (icon && icon.style) {
                                icon.style.color = "#bdbdbd";
                            }
                        }
                    }
                }
            }
        };

        scope.Users = CommonService.getAllUsers();

        scope.Users.$promise.then(function () {

            var agColumnDefs = [
                { field: 'Organization.Name', headerName: 'Organization', width: 150, menuTabs: ['filterMenuTab'], filter: true, sort: 'asc' },
                { field: 'Fullname', headerName: 'Name', width: 300, menuTabs: ['filterMenuTab'], filter: 'text' },
                { field: 'Description', headerName: 'Description', width: 300, menuTabs: ['filterMenuTab'], filter: 'text' },

                {
                    headerName: "Groups", width: 120,
                    cellRenderer: function (params) {
                        return params.data.Groups.length;
                    }
                },
                {
                    headerName: 'Status', width: 150, menuTabs: ['filterMenuTab'], filter: true,
                    cellRenderer: function (params) {
                        return scope.getStatusText(params.data.Inactive);
                    }
                },
                {
                    field: 'LastLogin', headerName: 'Last login', width: 200, menuTabs: ['filterMenuTab'], filter: 'text',
                    cellRenderer: function (params) {
                        return moment(params.data.LastLogin).format("MM/DD/YYYY HH:MM");
                    }
                },
            ];

            scope.agGridOptions = {
                // showToolPanel: false,
                suppressPropertyNamesCheck: true,
                rowSelection: 'single',
                selectedItem: null,
                columnDefs: agColumnDefs,
                rowData: scope.Users,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    icons: {
                        menu: "<span class='material-symbols-outlined' id='filter-icon' title='Filter'>filter_alt</span>",
                        filter: "<span></span>",
                    }
                },
                onBodyScroll: function (event) {
                    scope.changeFilterIcons(event);
                },
                onFilterModified: function (event) {
                    scope.changeFilterIcons(event);
                },
                onRowDoubleClicked: function (params) {
                    scope.openManageUserModal(scope.agGridOptions.selectedItem);
                },
                onSelectionChanged: function (params) {
                    scope.agGridOptions.selectedItem = scope.agGridOptions.api.getSelectedRows()[0];
                    scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
                    console.dir(scope.agGridOptions.selectedItem);
                },
            };

            var ag_grid_div = document.querySelector('#users-grid') as HTMLElement;    //get the container id...
            scope.ag_grid = new Grid(ag_grid_div, scope.agGridOptions); //bind the grid to it.


        });




        scope.openManageUserModal = function (user) {

            if (user)
                scope.user = user;
            else
                scope.user = null;

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/common/components/modals/templates/modal-manage-user.html',
                controller: 'ModalManageUserCtrl',
                scope: scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            });
        };

        scope.getStatusText = function (inactive_status) {
            return (inactive_status) ? "Inactive" : "Active"; //1=inactive
        }

    }

];

export default admin_users;
