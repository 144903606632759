/*
*   This is the landing page for a project
*/
import { METADATA_ENTITY_PROJECT, METADATA_PROPERTIES, serviceUrl } from "../../../../config";
import { getParsedMetadataValues } from "../../../common/common-functions";

declare const $: any;

var project_landing = ['$scope', '$routeParams', 'SubprojectService', 'ProjectService', 'DatasetService', 'CommonService', 'UserService',
  '$rootScope', '$uibModal', '$sce', '$window', '$http',
  'ServiceUtilities', '$location', '$anchorScroll',
  function (scope, routeParams, SubprojectService, ProjectService, DatasetService, CommonService, UserService, $rootScope, $uibModal, $sce, $window, $http,
    ServiceUtilities, $location, $anchorScroll) {
    //console.log("Inside controllers.js, projectDatasetsController...");
    //console.log("routeParams.Id = " + routeParams.Id);

    scope.OnTab = "Summary";
    scope.metadataProps = METADATA_PROPERTIES;

    scope.project = ProjectService.getProject(routeParams.Id);
    scope.currentUserId = $rootScope.Profile.Id;

    /*
    scope.UserIsAdmin = false;
    scope.UserIsOwner = false;
    scope.UserIsEditor = false;
    */

    //scope.metadataList = {};
    scope.CellOptions = {}; //for metadata dropdown options


    scope.project.$promise.then(function () {
      scope.afterProjectLoaded();
    });

    scope.openProjectEditor = function () {
      scope.row = scope.project; //
      var modalInstance = $uibModal.open({
        templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/modal-edit-project.html',
        controller: 'ModalProjectEditorCtrl',
        scope: scope, //very important to pass the scope along...
        backdrop: 'static',
        windowClass: 'modal-medium',
        keyboard: false
      }).result.then(function (saved_project) {
        scope.project = saved_project;
        scope.afterProjectLoaded();
      });
    };

    scope.afterProjectLoaded = function () {
      //load the metafields for this project once it loads
      scope.project.MetaFields = CommonService.getMetadataFor(scope.project.Id, METADATA_ENTITY_PROJECT);

      //if habitat project then load those fields, too...
      scope.project.MetaFields.$promise.then(function () {

        /* -- not adding in the habitat level metafields anymore -- */
        //var habfields = CommonService.getMetadataFor(scope.project.Id, METADATA_ENTITY_HABITAT);
        //habfields.$promise.then(function () {

        //    habfields.forEach(function (habfield) {
        //        habfield.isHabitat = true;
        //        scope.project.MetaFields.push(habfield);
        //    });

        //prep the values if it is a multiselect
        scope.project.MetaFields.forEach(function (field) {
          if (field.Values && (field.ControlType == "multiselect" || field.ControlType == "multiselect-checkbox")) {
            field.Values = getParsedMetadataValues(field.Values);
          }
        });
        //});


        //console.dir(scope.project);
      });

    }

    scope.getMetaField = function (id) {

      var result = "";

      scope.project.MetaFields.forEach(function (field) {
        if (field.MetadataPropertyId == id)
          result = field;
      });

      return result;

    };


    scope.uploadFileType = "";
    scope.projectName = "";
    scope.DatastoreTablePrefix = $rootScope.DatastoreTablePrefix = "";
    scope.filesToUpload = {};

    // Get the project ID from the url.
    var theUrl = $location.url();
    //console.log("theUrl = " + theUrl);
    var theLastSlashLoc = theUrl.lastIndexOf("/");
    scope.projectId = theUrl.substring(theLastSlashLoc + 1);
    //console.log("scope.projectId = " + scope.projectId);


    scope.ShowMap = {
      Display: false,
      Message: "Show Map",
      MessageToOpen: "Show Map",
      MessageToClose: "Hide Map",
    };


    scope.toggleMap = function () {
      if (scope.ShowMap.Display) {
        scope.removeFilter(); //also clears location
        scope.ShowMap.Display = false;
        scope.ShowMap.Message = scope.ShowMap.MessageToOpen;
      } else {
        scope.ShowMap.Display = true;
        scope.ShowMap.Message = scope.ShowMap.MessageToClose;

        setTimeout(function () {
          scope.map.reposition();
          console.log("repositioned");
        }, 400);

      }
    };


    scope.openChooseMapImage = function () {
      var modalInstance = $uibModal.open({
        templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/modal-choosemap.html',
        controller: 'ModalChooseMapCtrl',
        scope: scope, //very important to pass the scope along...
        backdrop: "static",
        keyboard: false
      });
    };

    scope.openChooseSummaryImages = function () {
      var modalInstance = $uibModal.open({
        templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/modal-choosesummaryimages.html',
        controller: 'ModalChooseSummaryImagesCtrl',
        scope: scope, //very important to pass the scope along...
        backdrop: "static",
        keyboard: false
      });
    };


    scope.syncToStreamnet = function () {
      $.ajax({
        url: serviceUrl + '/api/v1/streamnet/synctostreamnet',
        type: 'GET',
        // data : formData,
        // processData: false,  // tell jQuery not to process the data
        // contentType: false,  // tell jQuery not to set contentType
        success: function (data) {
          // var output = eval("(" + data + ")");
          alert(data.join('\n'));
        },
        error: function (jqXHR, error, errorThrown) {
          if (jqXHR.status && jqXHR.status == 400) {
            alert(jqXHR.responseText + "\n\n" + "Error running sync action!");
          } else {
            alert("Error running sync action!");
          }
        }
      });

    };

    //handle favorite toggle
    scope.isFavorite = $rootScope.Profile.isProjectFavorite(routeParams.Id);
    scope.toggleFavorite = function () {
      // If the project has not loaded yet for some reason,
      // and the user tries to unfavorite the project,
      // it replaces the project in User Preferences (Project.Value) with "undefined".
      // We need to wait until the project is loaded, before we allow the user to
      // Unfavorite the project.
      //UserService.toggleFavoriteProject(scope, $rootScope);

      if (scope.project.Id)
        UserService.toggleFavoriteProject(scope, $rootScope);
      else
        alert("Please wait... the project has not loaded yet.");
    }


  }

];


export default project_landing;



