import * as angular from 'angular';
import { getJsonObjects, getPossibleValueGridObjects } from "../../../common/common-functions";

import { Grid } from "ag-grid-community";
import 'ag-grid-enterprise';

//modal to edit dataset field
var modal_admin_edit_dataset_field = ['$scope', '$uibModal', '$uibModalInstance', 'AdminService',

    function ($scope, $uibModal, $uibModalInstance, AdminService) {

        //possible values selection grid for customizing datasetfield possible values selection
        $scope.CustomPossibleValues = false;
        $scope.assignedComparator = function (one, two, nodeone, nodetwo) {
            if (nodeone.isSelected() && nodetwo.isSelected())
                return 0;

            if (!nodeone.isSelected() && !nodetwo.isSelected())
                return 0;

            if (nodeone.isSelected() && !nodetwo.isSelected())
                return 1;

            if (!nodeone.isSelected() && nodetwo.isSelected())
                return -1;

        }

        var pvColumnDefs = [
            {
                field: 'Assigned',
                headerName: 'Assigned',
                headerCheckboxSelection: true,
                checkboxSelection: true,
                width: 110,
                //maxWidth: 110,
                alwaysShowField: true,
                menuTabs: [],
                comparator: $scope.assignedComparator
            },
            { field: 'Label', headerName: 'Possible Value', sort: 'asc', menuTabs: [], }
        ];

        $scope.pvGridOptions = {
            columnDefs: pvColumnDefs,
            rowSelection: 'multiple',
            suppressRowClickSelection: false,
            suppressPropertyNamesCheck: true,
            onSelectionChanged: function (params) {
                //console.dir(params);
                $scope.pvGridOptions.selectedItems = $scope.pvGridOptions.api.getSelectedRows();
                //$scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
            },
            selectedItems: [],
            defaultColDef: {
                sortable: true,
                resizable: true,
            },
        };

        $scope.activateGrid = function () {
            if ($scope.CustomPossibleValues && $scope.field_to_edit.Field.PossibleValues) { //checkbox is checked

                if (!$scope.ag_grid) {
                    $scope.ag_grid_div = document.querySelector('#pv-grid');    //get the container id...
                    $scope.ag_grid = new Grid($scope.ag_grid_div, $scope.pvGridOptions); //bind the grid to it.

                    //handle the possible values
                    // 1 - superset of all possible values from master field - set as grid data
                    $scope.MasterPossibleValues = getPossibleValueGridObjects(getJsonObjects($scope.field_to_edit.Field.PossibleValues));
                    //console.dir($scope.MasterPossibleValues);

                    $scope.pvGridOptions.api.setRowData($scope.MasterPossibleValues);

                    // 2 - if we already have datasetfield possible values set, convert from Json and then select the nodes in master list
                    if ($scope.hasCustomPossibleValues($scope.field_to_edit)) {
                        $scope.field_to_edit.PossibleValues = getPossibleValueGridObjects(getJsonObjects($scope.field_to_edit.PossibleValues));
                        //console.dir($scope.field_to_edit.PossibleValues);

                        $scope.pvGridOptions.api.forEachNode(function (node) {
                            $scope.field_to_edit.PossibleValues.forEach(function (val) {
                                if (val.Label == node.data.Label) {
                                    node.setSelected(true);
                                }
                            });
                        });
                    }

                }
            }
        };

        $scope.save = function () {

            var fieldtosave = angular.copy($scope.field_to_edit);
            if ($scope.CustomPossibleValues) {
                //convert to a pv array/object
                var pv_to_save = {};

                //console.dir($scope.pvGridOptions.selectedItems);

                if ($scope.field_to_edit.ControlType == "select-number") {
                    pv_to_save = {}; //switch to an object
                    $scope.pvGridOptions.selectedItems.forEach(function (item) {
                        var int_id = parseInt(item.Id);
                        pv_to_save[int_id] = item.Label;
                    });
                } else {
                    $scope.pvGridOptions.selectedItems.forEach(function (item) {
                        pv_to_save[item.Id] = item.Label;
                    })
                }

                //console.dir(pv_to_save);

                fieldtosave.PossibleValues = angular.toJson(pv_to_save);
            } else {
                delete fieldtosave.PossibleValues;
            }

            var saved_field = AdminService.saveDatasetField(fieldtosave);
            saved_field.$promise.then(function () {
                $uibModalInstance.close(saved_field);
            }, function (error) {
                $scope.SaveMessage = "Error: " + error.data.ExceptionMessage;
            });

        };

        $scope.parsePossibleValuesString = function () {
            try {
                $scope.field_to_edit.Values = angular.fromJson($scope.field_to_edit.PossibleValues);
            } catch (exception) {
                $scope.field_to_edit.Values = exception.message;
            }
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };

        $uibModalInstance.opened.then(function () {

            //if the datasetfield has custom possible values, set the checkbox and show the values...
            $scope.CustomPossibleValues = $scope.hasCustomPossibleValues($scope.field_to_edit);

            //we have to wait until the modal is loaded or else our grid div is not available
            setTimeout(function () {
                if ($scope.CustomPossibleValues) {
                    $scope.activateGrid();
                }
            }, 500);
        })

        $scope.hasCustomPossibleValues = function (datasetfield) {
            return (datasetfield.PossibleValues != null && datasetfield.PossibleValues != "")
        }

    }
];

export default modal_admin_edit_dataset_field;
