import * as angular from 'angular';
import * as moment from 'moment';
import {
    getAllMatchingFromArray,
    getByField,
    getById,
    getDataGrade,
    getFilesArrayAsList,
    getMatchingByField,
    getParsedMetadataValues,
    initEdit, makeObjects
  } from "../../../common/common-functions";
import {AdminUserIds, ROWSTATUS_DELETED, SystemTimezones} from "../../../../config";

//Show this modal to prevent user from navigating, or doing anything else,
// while the save action is in progress.
var modal_save_status = ['$scope', '$uibModal','$uibModalInstance', 'Upload', '$rootScope', '$location', 'DatasetService',

    function ($scope, $uibModal, $uibModalInstance, Upload, $rootScope, $location, DatasetService,) {
        console.log("Inside modal-save-status.ts...");

        //$scope.showSuccess = true;
        $scope.showFish = true; // Start out with the fish spinning, and the Close button disabled.

        //This is a workaround for angularjs' either too loose matching or too strict...
        $scope.locequals = function (actual, expected) { return actual == expected; };

        //Handle saving the files.
        var data = {
            ProjectId: $scope.project.Id,
            DatasetId: $scope.dataset.Id,
        };

        var target = '/api/v1/file/uploaddatasetfile';

        //console.log("$scope.row is next...");
        //console.dir($scope.row);
        var saveRow = $scope.row;

        // The flow...
        // Call $scope.handleFilesToUploadRemove
        // $scope.handleFilesToUploadRemove does work and then calls dataset-edit-form.ts, $scope.modalFile_saveParentItem
        // dataset-edit-form.ts, $scope.modalFile_saveParentItem sets $scope.showFish to false in modal-save.status.ts
        // The user clicks Close on modal-save-status.html
        //      - If the user was on dataset Creel Harvest, the page remains where it is
        //      - If the user was on some other dataset, the page then navigations to the Dataset Activities page.
        $scope.handleFilesToUploadRemove(saveRow, data, target, Upload, $rootScope, $scope); //when done (handles failed files, etc., sets in scope objects) then calls modalFile_saveParentItem below.
        
        //$uibModalInstance.close();


        $scope.close = function () {
            if ($scope.background_save) // Creel Survey, Add Section button clicked.
            {
                // Close the modal, but stay on the page.
                $uibModalInstance.dismiss();

                // Set $scope.background_save to false here, as it will get reset to true by the dataset-edit-form
                // when doing a new Creel section.
                $scope.background_save = false;
                $scope.$parent.background_save = false;
            }
            else
            {
                // Close the modal, AND redirect to the Dataset Activities page.
                $location.path("/" + $scope.dataset.activitiesRoute + "/" + $scope.dataset.Id);
                $uibModalInstance.dismiss();
            }
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };
    }
];

export default modal_save_status;
