import * as angular from 'angular';
import * as moment from 'moment';

import {
  getAgGridFilterByType, getAgGridFilterParamsByType,
  getByField,
  getFilesArrayAsList,
  getParsedMetadataValues, getPossibleValues,
  instrumentsToPossibleValues, makeObjects, orderByAlpha
} from "../../../common/common-functions";
import { MAX_DATASET_RECORDS_THRESHOLD, serverUrl, SystemDefaultColumnWidth } from "../../../../config";
import { Grid } from "ag-grid-community";
import { saveAs } from 'file-saver';
import { serviceUrl, DATASET_RECORDS_EXPORT_FORMAT_THRESHOLD, REPORTSERVER_URL } from "../../../../config";
import 'ag-grid-enterprise';
//import { MatIconModule } from '@angular/material/icon';

var dataset_query = ['$scope', '$routeParams', 'DatasetService', '$location', '$uibModal', '$rootScope', 'ChartService',
  'ProjectService', 'CommonService', 'SubprojectService', 'GridService', '$timeout',
  function ($scope, $routeParams, DatasetService, $location, $uibModal, $rootScope, ChartService, ProjectService, CommonService, SubprojectService, GridService, $timeout) {
    $scope.existingChartConfigs = ['AdultWier', 'Appraisal', 'ArtificialProduction', 'BSample', 'CreelSurvey',
      'ElectroFishing', 'FishScales', 'Screwtrap', 'SnorkelFish', 'SpawningGroundSurvey', 'WaterQuality', 'WaterTemp', 'WaterTemp-standalone', 'WaterTemp-orig', 'WaterTemp-placeholder'];
    $scope.system = { loading: true, messages: [] };

    $scope.Criteria = {};
    $scope.criteriaList = [];
    $scope.AutoExecuteQuery = false;
    $scope.selectedRow = {};
    $scope.onField = {};

    $scope.instrumentIdList = [];
    $scope.instrumentAccuracyCheckList = [];

    $scope.prevTop = -1;
    $scope.lastRecord = 0;
    $scope.queryFullResults = [];
    $scope.queryTotalRecords = null;
    $scope.queryTotalRecordsRun = false;
    $scope.DatasetTotalRecordsRun = false;
    $scope.DatasetTotalRecords = -1; //"...";
    $scope.RecordGroupSize = 30;
    $scope.prevStartRow = 0;
    $scope.endRow = $scope.RecordGroupSize;
    $scope.ScrollIndex = 0;
    $scope.scrollCount = 0;
    $scope.lastVisibleRow = 18;
    $scope.firstId = 0;
    $scope.lastSeenId = 0;
    $scope.firstIx = 0;
    $scope.lastIx = 0;
    $scope.resultCount = 0;
    $scope.resultsPerPage = 1000;
    $scope.currentPage = 0;
    $scope.nextPage = true;
    $scope.recordCount = 0;
    $scope.endPadding = 5;
    $scope.queryLoaded = false;
    $scope.datasource = null;
    $scope.NumberOfRecordsToPull = -1;
    $scope.NumberOfRecordsToPullSave = $scope.NumberOfRecordsToPull;
    $scope.infGridParams = null;
    $scope.fieldCriteriaRemoved = false;
    $scope.queryResult = {
      Error: false,
      ErrorMessage: null
    };
    $scope.runningQueryWithFieldCriteria = false;
    $scope.runningQueryToLoadAllRecords = false;
    $scope.sortModel = [];
    $scope.filterModel;
    $scope.filterChanged = false;
    $scope.overlayCountBool = false;
    $scope.overlayResultBool = false;

    // Export records to csv variables
    $scope.modalInstance = null;
    $scope.isExporting = false;
    $scope.isExportClicked = false;
    $scope.isLessThanThreshold = true;
    $scope.exportText = 'Export Records';

    $rootScope.infGridParams = null;
    $rootScope.datasetId = null;
    $rootScope.queryCriteriaList = null;

    $scope.dataset = DatasetService.getDataset($routeParams.Id);

    $scope.dataset.$promise.then(function () {

      $rootScope.datasetId = $scope.dataset.Id;

      /*if (typeof $scope.dataset.Config.NumberRecordsForQueryToPull !== 'undefined')
          $scope.NumberOfRecordsToPull = $scope.dataset.Config.NumberRecordsForQueryToPull;
      else
          $scope.NumberOfRecordsToPull = 10000;

      $scope.NumberOfRecordsToPullSave = $scope.NumberOfRecordsToPull;

      //$scope.dataAgGridOptions.cacheBlockSize = $scope.NumberOfRecordsToPull;
      */


      $scope.project = ProjectService.getProject($scope.dataset.ProjectId);

      if ($scope.dataset.Datastore.TablePrefix === "WaterTemp") {
        $scope.instrumentAccuracyCheckList = ProjectService.getAllInstrumentAccuracyChecks();
      }

      if (($scope.dataset.Config) && ($scope.dataset.Config.RedirectToReportsPage)) {

        var strUrl = $scope.dataset.Config.RedirectToReportsPage;
        window.open(strUrl, '_blank');
        var strParent = "activities/" + $scope.dataset.Id;
        $location.url(strParent);
      } else {

        $scope.project.$promise.then(function () {
          $scope.activateGrid();
        });

        $scope.query = $scope.buildQuery();
        $scope.query.criteria.GetTotalCount = false;

        $scope.query.TotalRecordCount = DatasetService.queryActivities($scope.query);
        $scope.query.TotalRecordCount.$promise.then(function () {

          if (!$scope.query.TotalRecordCount) {
            return;
          } else if (!$scope.query.TotalRecordCount.$resolved) {
            return;
          }

          $scope.query.criteria.GetTotalCount = false;

          // There should only be one...
          $scope.query.TotalRecordCount.forEach(function (item) {
            $scope.DatasetTotalRecords = item.TotalRecords;
          });

          if ($scope.DatasetTotalRecords >= MAX_DATASET_RECORDS_THRESHOLD) {
            //$location.path(REPORTSERVER_URL + $scope.dataset.Datastore.TablePrefix);
            // First, get us off the dataset query page, and put us on the dataset activities page.
            location.replace("/activities/" + $scope.dataset.Id);

            // Next, open a new tab and with the report server url.
            window.open(REPORTSERVER_URL + $scope.dataset.Datastore.TablePrefix, '_blank').focus();
          }
        });
      }
    });

    $scope.clearValue = function () {
      $scope.Criteria.Value = null;
      console.dir($scope.Criteria);
    };

    $scope.changeFilterIcons = function (event) {
      // We need to call this onFilterModified and onBodyScroll, as the elements are removed/added
      // to the DOM when scrolling horizontally on wide datasets.
      // Can also pass $scope.dataAgGridOptions to this function rather than an event.
      let filters = $scope.dataAgGridOptions.api.getFilterModel();

      for (let col of event.api.columnController.columnDefs) {
        for (const span of document.querySelectorAll("span")) {
          if (span.textContent.includes(col.Label)) {
            // Naming conventions vary, so lets check all of these to see if the column has a filter
            if (col.Label in filters || col.headerName in filters || col.DbColumnName in filters) {
              // If column has a filter change the icon color
              // @ts-ignore
              let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];
              icon.style.color = "#007bff";
            }
            else {
              // Remove the color prop from the icon if there is no filter
              // @ts-ignore
              let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];// filterIcon.children[0];
              icon.style.color = "#bdbdbd";
            }
          }
        }
      }
    };

    //ag-grid - header + details --- alrid for multiple activities
    $scope.dataAgGridOptions = {
      rowBuffer: 0,
      animateRows: true,
      overlayLoadingTemplate: '<img src="assets/images/FishLoading.gif" />',
      // columnDefs: null,
      rowData: [],
      rowSelection: 'multiple',
      suppressPropertyNamesCheck: true, // this removes the console warning for custom properties, this is a known problem, see https://github.com/ag-grid/ag-grid/issues/2320
      suppressMultiSort: true,
      onBodyScroll: function (event) {
        $scope.changeFilterIcons(event);
      },
      onFilterModified: function (event) {
        $scope.changeFilterIcons(event);
      },
      rowModelType: 'serverSide',
      pagination: true,
      paginationPageSize: 1000,
      cacheBlockSize: 1000,
      maxBlocksInCache: 1,
      infiniteInitialRowCount: 1000,
      onSelectionChanged: function (params) {
        $scope.selectedRow = $scope.dataAgGridOptions.api.getSelectedRows()[0];
        $scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
      },
      // Used for bottom bar for field description
      onCellFocused: function (params) {
        //console.dir(params);
        if (!params.column)
          return;

        $scope.onField = (params.column.colDef.cdmsField);
        $scope.$apply();
      },
      onSortChanged: function () {
        // if sort is changed, start over with new index from 0
        $scope.lastSeenId = 0;
      },
      onPaginationChanged: function () {
        // check paginationGetCurrentPage() against the last page to see if we went forward or back
        if ($scope.dataAgGridOptions.api) {
          if ($scope.dataAgGridOptions.api.paginationGetCurrentPage() > $scope.currentPage) {
            $scope.nextPage = true;
          }
          else if ($scope.dataAgGridOptions.api.paginationGetCurrentPage() < $scope.currentPage) {
            $scope.nextPage = false;
          }
        }

        $scope.currentPage = $scope.dataAgGridOptions.api.paginationGetCurrentPage();
      },
      onGridReady: function (params) {
        $scope.system.loading = false;
        $scope.$apply();
        GridService.autosizeColumns($scope.dataAgGridOptions);
      },
      floatingFilter: false,
      defaultColDef: {
        editable: false,
        suppressHeaderFilterButton: false,
        sortable: true,
        resizable: true,
        enableFilter: true,
        filter: true,
        filterParams: { applyButton: true, clearButton: true },
        icons: {
          menu: "<span class='material-symbols-outlined' id='filter-icon' title='Filter'>filter_alt</span>",
        }
      },
      paginationNumberFormatter: function (params) {
        return '&nbsp' + params.value.toLocaleString() + '&nbsp';
      },
      processCellForClipboard: $scope.processCellDataForOutput,

      // Remove the Export context menu item
      getContextMenuItems: params => {
        var defaultItems = params.defaultItems; // Get the default context menu items
        var filteredItems = defaultItems.filter(item => item !== 'export');
        return filteredItems;
      }
    };

    $scope.getRowCount = function () {
      if ($scope.dataAgGridOptions.api) {
        return $scope.dataAgGridOptions.api.getDisplayedRowCount();
      } else {
        return 0;
      }
    };

    $scope.activateGrid = function () {
      //setup grid and coldefs and then go!
      $timeout(function () {

        //need to set some header field possible values manually BEFORE we load our coldefs - so that the named value will display in the grid.
        var instrument_coldef = getByField($scope.dataset.Fields, "InstrumentId", "DbColumnName");
        if (instrument_coldef) {
          instrument_coldef.Field.PossibleValues = instrumentsToPossibleValues($scope.project.Instruments);
        }

        //var hidden_header_controltypes = ["file", "hidden", "accuracy-check-select", "activity-text", "instrument-select", "post-accuracy-check-select", "qa-status-comment", "timezone-select"];
        var hidden_header_controltypes = ["file", "hidden", "activity-text", "instrument-select", "qa-status-comment"];
        //var hidden_grid_controltypes = ["hidden", "activity-text","accuracy-check-select","timezone-select","post-accuracy-check-select"];
        var hidden_grid_controltypes = ["hidden", "activity-text"];

        $scope.dataAgColumnDefs = GridService.getAgColumnDefs($scope.dataset);
        // generic index field that will always be included
        const indexField = {
          headerName: 'index',
          field: 'ix',
          width: SystemDefaultColumnWidth,
          //maxWidth: 320, Allow unlimited column width
          Label: 'ix',
          DbColumnName: 'ix',
          ControlType: 'ix',
          DatasetId: $scope.dataset.Id,
          ProjectId: $scope.dataset.ProjectId,
          hide_header: true,
          hide: "true",
        };
        $scope.dataAgColumnDefs.DetailFields.push(indexField);
        //setup any possible values that are needed - detail
        //note: the "hide" property hides the column in the results grid; the "hide_header" hides it in the header list in columns multiselect
        angular.forEach($scope.dataAgColumnDefs.DetailFields, function (fieldDef) {
          if (fieldDef.field == "QAStatusId") { //RowQAStatusId because we're in the details
            fieldDef.field = fieldDef.DbColumnName = "RowQAStatusId";
            fieldDef.PossibleValuesList = makeObjects($scope.dataset.RowQAStatuses, 'Id', 'Name');
            fieldDef.setPossibleValues(fieldDef.PossibleValuesList);
            fieldDef.hide_header = true;

          } else {
            fieldDef.PossibleValuesList = getParsedMetadataValues(fieldDef.PossibleValues);

            // If the detail fields have set possible values, use them as filter param values to use them to filter instead of default text search
            if (fieldDef.PossibleValues && fieldDef.PossibleValues.length > 0) {
              fieldDef.filterParams = {
                values: Object.values(fieldDef.PossibleValues),
                applyButton: true,
                clearButton: true,
              }
              fieldDef.filter = true;
            }
          }

          if (fieldDef.ControlType == 'file' || fieldDef.ControlType == 'hidden') {
            fieldDef.hide_header = true;
          }

          if (fieldDef.hasOwnProperty("filterParams")) {
            if (fieldDef.filterParams != null) {
              if (!fieldDef.filterParams.hasOwnProperty("apply") || !fieldDef.filterParams.hasOwnProperty("applyButton")) {
                fieldDef.filterParams.applyButton = true;
              }

              if (!fieldDef.filterParams.hasOwnProperty("clear") || !fieldDef.filterParams.hasOwnProperty("clearButton")) {
                fieldDef.filterParams.clearButton = true;
              }
            } else {
              fieldDef.filterParams = {
                applyButton: true,
                clearButton: true,
              }
            }

          } else {
            fieldDef.filterParams = {
              applyButton: true,
              clearButton: true,
            }
          }

        });

        //setup activity fields to point to the right place
        //note: the "hide" property hides the column in the results grid; the "hide_header" hides it in the header list in columns multiselect
        angular.forEach($scope.dataAgColumnDefs.HeaderFields, function (fieldDef) {
          //console.dir(fieldDef);
          if (fieldDef.field == "LocationId") {

            // The commented-out part below (here to line 258) is residual from the old page,
            // where users could add criteria at the top of the page.
            // Now the filtering is done in the grid.
            //----------------------------------------------
            //load the config so that we can check if we are supposed to include the habitat sites for this project
            try {
              $scope.project.Config = ($scope.project.Config) ? angular.fromJson($scope.project.Config) : {};
            } catch (e) {
              console.error("config could not be parsed for project" + $scope.project.Config);
              console.dir(e);
            }

            var dataset_locations = ProjectService.getDatasetLocations($scope.project.Id, $scope.dataset.Id);

            fieldDef.filterParams = {
              applyButton: true,
              clearButton: true,
              values: params => {
                dataset_locations.$promise.then(function () {
                  dataset_locations = dataset_locations.sort(orderByAlpha);
                  fieldDef.PossibleValuesList = dataset_locations; //makeObjects(dataset_locations, 'Id', 'Label'); //used in the headers
                  if (fieldDef.hasOwnProperty('setPossibleValues')) {
                    fieldDef.setPossibleValues(makeObjects(dataset_locations, 'Id', 'Label'));
                    var vals = Object.values(fieldDef.PossibleValues);
                    params.success(vals);
                  }
                })
              }
            }

            dataset_locations.$promise.then(function () {
              dataset_locations = dataset_locations.sort(orderByAlpha);
              fieldDef.PossibleValuesList = dataset_locations;
              if (fieldDef.hasOwnProperty('setPossibleValues')) {
                fieldDef.setPossibleValues(makeObjects(dataset_locations, 'Id', 'Label'));
              }
            })

          } else if (fieldDef.field == "QAStatusId") { //ActivityQAStatusId
            fieldDef.field = fieldDef.DbColumnName = "ActivityQAStatusId";
            fieldDef.PossibleValuesList = makeObjects($scope.dataset.QAStatuses, 'Id', 'Name');

            if (fieldDef.hasOwnProperty('setPossibleValues')) {
              fieldDef.setPossibleValues(fieldDef.PossibleValuesList);
            }

            fieldDef.filterParams = {
              values: Object.values(fieldDef.PossibleValues),
              applyButton: true,
              clearButton: true,
            }

            //fieldDef.hide_header = true; //hide in header
          } else if (fieldDef.field == "QAComments") { //ActivityQAStatusId
            fieldDef.field = fieldDef.DbColumnName = "ActivityQAComments";
            fieldDef.filter = getAgGridFilterByType(fieldDef.ControlType, fieldDef.DbColumnName)
          }

          if (fieldDef.ControlType == "select" || fieldDef.ControlType == "multiselect") {
            fieldDef.PossibleValuesList = getParsedMetadataValues(fieldDef.PossibleValues);

            if (fieldDef.hasOwnProperty('setPossibleValues')) {
              fieldDef.setPossibleValues(fieldDef.PossibleValuesList);
            }

            fieldDef.filterParams = {
              values: Object.values(fieldDef.PossibleValues),
              applyButton: true,
              clearButton: true,
            }

          } else if (fieldDef.ControlType == "instrument-select") {// || fieldDef.ControlType == "accuracy-check-select" || fieldDef.ControlType =="post-accuracy-check-select" || fieldDef.ControlType == "timezone-select") {
            fieldDef.PossibleValuesList = makeObjects(fieldDef.PossibleValues, 'Id', 'Label');

            if (fieldDef.hasOwnProperty('setPossibleValues')) {
              fieldDef.setPossibleValues(fieldDef.PossibleValuesList);
            }

            //fieldDef.AccuracyChecks =

          } else if (fieldDef.ControlType == "fisherman-select") {
            fieldDef.PossibleValuesList = fieldDef.PossibleValues;

            if (fieldDef.hasOwnProperty('setPossibleValues')) {
              fieldDef.setPossibleValues(fieldDef.PossibleValuesList);
            }

          } else if (fieldDef.ControlType == "number-select") {
            fieldDef.PossibleValuesList = fieldDef.PossibleValues;

            if (fieldDef.hasOwnProperty('setPossibleValues')) {
              fieldDef.setPossibleValues(fieldDef.PossibleValuesList);
            }

          } else if (fieldDef.ControlType == "accuracy-check-select") {
            fieldDef.PossibleValuesList = fieldDef.PossibleValues;

            if (fieldDef.hasOwnProperty('setPossibleValues')) {
              fieldDef.setPossibleValues(fieldDef.PossibleValuesList);
            }

          } else if (fieldDef.ControlType == "post-accuracy-check-select") {
            fieldDef.PossibleValuesList = fieldDef.PossibleValues;

            if (fieldDef.hasOwnProperty('setPossibleValues')) {
              fieldDef.setPossibleValues(fieldDef.PossibleValuesList);
            }

          }

          // If using possible values to filter, set filter to true for checkboxes instead of text search
          if (fieldDef.PossibleValues && fieldDef.PossibleValues.length > 0) {
            fieldDef.filter = true;
          }


          //hidden headers
          // @ts-ignore
          if (hidden_header_controltypes.contains(fieldDef.ControlType)) {
            fieldDef.hide_header = true;
            fieldDef.PossibleValuesList = [];
          } //just the headers

          //hidden in grid
          // @ts-ignore
          if (hidden_grid_controltypes.contains(fieldDef.ControlType)) {
            fieldDef.hide = true;
            fieldDef.PossibleValuesList = [];
          } //just the detail

          if (fieldDef.hasOwnProperty("filterParams")) {
            if (fieldDef.filterParams != null) {
              if (!fieldDef.filterParams.hasOwnProperty("apply") || !fieldDef.filterParams.hasOwnProperty("applyButton")) {
                fieldDef.filterParams.applyButton = true;
              }

              if (!fieldDef.filterParams.hasOwnProperty("clear") || !fieldDef.filterParams.hasOwnProperty("clearButton")) {
                fieldDef.filterParams.clearButton = true;
              }
            } else {
              fieldDef.filterParams = {
                applyButton: true,
                clearButton: true,
              }
            }

          } else {
            fieldDef.filterParams = {
              applyButton: true,
              clearButton: true,
            }
          }

        });

        $scope.dataAgGridOptions.columnDefs = $scope.dataAgColumnDefs.HeaderFields.concat($scope.dataAgColumnDefs.DetailFields);

        // For each column def, if possible values, set filter params values to that column defs possible values
        // angular.forEach($scope.dataAgGridOptions.columnDefs, function (colDef) {
        //   if (colDef.PossibleValuesList.length > 0) {
        //     if (colDef.PossibleValues) {
        //       colDef.filterParams.values = Object["values"](colDef.PossibleValues);
        //     }

        //   }
        // });
        console.dir($scope.dataAgGridOptions.columnDefs);
        //console.dir($scope.imported_rows);


        var ag_grid_div = document.querySelector('#query-grid') as HTMLElement;    //get the container id...
        $scope.ag_grid = new Grid(ag_grid_div, $scope.dataAgGridOptions); //bind the grid to it.


        //********************************
        // $scope.queryRecordsCount();
        $scope.dataAgGridOptions.api.setServerSideDatasource($scope.agGridDataSource);
        $scope.query = undefined;
        $scope.query = $scope.buildQuery();
        $scope.query.criteria.GetTotalCount = false;
        // $scope.executeQuery();
      }, 0);
    };

    $scope.openQueryWindow = function (p) {
      $location.path("/datasetquery/" + $scope.dataset.Id);
    };

    $scope.openDetailsWindow = function (p) {
      $location.path("/dataset-details/" + $scope.dataset.Id);
    };

    $scope.openImportWindow = function () {
      $scope.activities = null; // Dump the activities to free up memory.
      $location.path("/datasetimport/" + $scope.dataset.Id);
    };

    $scope.openDataEntry = function (p) {
      delete $rootScope.imported_rows;
      $location.path("/dataentryform/" + $scope.dataset.Id);
    };

    $scope.queryRecordsCount = function () {

      $scope.query.loading = true;
      $scope.dataAgGridOptions.api.showLoadingOverlay();

      var query = {
        datasetId: parseInt($routeParams.Id),
        tablePrefix: $scope.dataset.Datastore.TablePrefix,
        filterModel: $scope.dataAgGridOptions.api.getFilterModel()
      }

      var count = DatasetService.queryRecordCount(query).$promise.then(val => {
        $scope.queryTotalRecords = val.recordCount;
        $scope.overlayCountBool = true;
        // Purge cache to get new page count & force grid refresh
        $scope.dataAgGridOptions.api.purgeServerSideCache();

        if ($scope.overlayCountBool && $scope.overlayResultBool) {
          $scope.query.loading = false;
          $scope.dataAgGridOptions.api.hideOverlay();
        }

      });

      return count;

    };

    $scope.clearFilters = function () {
      $scope.dataAgGridOptions.api.setFilterModel(null);
      $scope.changeFilterIcons($scope.dataAgGridOptions);
      $scope.execute();
    }

    $scope.addCriteria = function () {
      // Notes...
      // When we have a list of possible values, such as the sex of a fish (["Male","Female","Unknown"]),
      // and the list is an array, we pick the item, for example "Male", and store the value in the backend.
      // However, when the list is itself an object (Fishermen, or StreamName), the possible values are
      // a collection of key value pairs, and the list is NOT an array.  In this case the Id gets stored
      // in the backend, NOT the name on the screen.

      // When the user selects only 1 item from the list, it gets converted to a number (below).
      // However, when the user selects 2 or more items from the list, the value becomes an array.
      // Therefore, we must walk this list first, and check each item against the PossibleValues.

      //possible values that are associative arrays will need to dereference from the value to the key
      /*if ($scope.Criteria.ParamFieldSelect[0].PossibleValues && !Array.isArray($scope.Criteria.ParamFieldSelect[0].PossibleValues)) {
          Object.keys($scope.Criteria.ParamFieldSelect[0].PossibleValues).forEach(function (key) {
              if ($scope.Criteria.ParamFieldSelect[0].PossibleValues[key] == $scope.Criteria.Value) {
                  //$scope.Criteria.DisplayName = $scope.Criteria.Value;
                  $scope.Criteria.Value = key; //convert it to the key instead of the value...
              }
          });
      }
      */

      if ($scope.Criteria.ParamFieldSelect[0].PossibleValues && !Array.isArray($scope.Criteria.ParamFieldSelect[0].PossibleValues)) {
        //if ((typeof $scope.Criteria.ParamFieldSelect[0].PossibleValues !== 'undefined') && (($scope.Criteria.ParamFieldSelect[0].PossibleValues) === null) || (!Array.isArray($scope.Criteria.ParamFieldSelect[0].PossibleValues))) {
        if (Array.isArray($scope.Criteria.Value)) {
          var arySearchList = [];
          var aryDisplayList = [];

          $scope.Criteria.Value.forEach(function (item) {
            // If the item is already a number, just add it to the list.
            // Otherwise, we must convert it to a number.
            if (parseInt(item)) {
              //Object.keys($scope.Criteria.ParamFieldSelect[0].PossibleValuesList).forEach(function (key) {
              $scope.Criteria.ParamFieldSelect[0].PossibleValuesList.forEach(function (key) {
                if (key.Id === parseInt(item)) {
                  arySearchList.push(item);
                  aryDisplayList.push(key.Label);
                }
              });
            } else {
              Object.keys($scope.Criteria.ParamFieldSelect[0].PossibleValuesList).forEach(function (key) {
                if ($scope.Criteria.ParamFieldSelect[0].PossibleValues[key] === item) {
                  arySearchList.push(key);
                  aryDisplayList.push(item);
                }
              });
            }
          });

          $scope.Criteria.Value = "[" + arySearchList.join() + "]";
          $scope.Criteria.DisplayName = "[" + aryDisplayList.join() + "]";
        } else {
          Object.keys($scope.Criteria.ParamFieldSelect[0].PossibleValues).forEach(function (key) {

            if ($scope.Criteria.ParamFieldSelect[0].PossibleValues[key] == $scope.Criteria.Value) {
              $scope.Criteria.DisplayName = $scope.Criteria.Value;
              $scope.Criteria.Value = key; //convert it to the key instead of the value...
            }

          });
        }
      } else {
        if (typeof $scope.Criteria.Value === 'string') {
          $scope.Criteria.DisplayName = $scope.Criteria.Value;
        } else if (typeof $scope.Criteria.Value === 'object') {
          if ($scope.Criteria.Value.hasOwnProperty('ParamFieldDateType') && $scope.Criteria.Value.ParamFieldDateType == 'between') {
            $scope.Criteria.DisplayName = $scope.Criteria.Value.BetweenFromFieldDate + " - " + $scope.Criteria.Value.BetweenToFieldDate;
          }
        } else {
          if ($scope.Criteria.ParamFieldSelect[0].PossibleValues) {
            Object.keys($scope.Criteria.ParamFieldSelect[0].PossibleValues).forEach(function (key) {

              if ($scope.Criteria.ParamFieldSelect[0].PossibleValues[key] == $scope.Criteria.Value) {
                $scope.Criteria.DisplayName = $scope.Criteria.Value;
              }

            });
          }
        }
      }


      $scope.criteriaList.push({
        DbColumnName: $scope.Criteria.ParamFieldSelect[0].DbColumnName,
        Id: $scope.Criteria.ParamFieldSelect[0].cdmsField.Id,
        Value: $scope.Criteria.Value,
        DisplayName: $scope.Criteria.DisplayName,
      });

      console.dir($scope.criteriaList);

      $scope.Criteria.Value = null;
      $scope.Criteria.DisplayName = null;

    };

    $scope.clearGrid = function () {
      // Dump the query results.
      $scope.query = null;

      // Take the grid out of Infinite Scrolling.
      $scope.lastRecord = -1;

      // *** This is the critical part ***
      // These actions are what actually clear the grid.

      // First, create a new datasource, fill it with an empty result set,
      // and take the grid out of infinite scrolling mode.
      var dataSource = {
        rowCount: 0, // behave as a regular grid.

        getRows: function (params) {
          // Empty result set.
          // Remember, a blank, null, or -1 puts the grid INTO
          // infinite scrolling mode, and a 0 takes us OUT of
          // infinite scrolling mode.
          params.successCallback([], 0);
        },

      };

      // Now, change the datasource to be the empty grid we just created.
      $scope.dataAgGridOptions.api.setDatasource(dataSource);
    };

    $scope.runQueryWithCriteria = function (params) {
      // When the page opens and the query first runs, it pulls the first set of
      // records, with no filters (get everything).
      // When the user clicks Execute Query, they have probably added
      // criteria.  Therefore, we must reset the query,
      // so that we don't append the new results onto the old result set.
      // Also, we want to start at the beginning,
      // rather than offsetting into the data (having a startRow > 0).

      // First dump the last results and reset things.
      $scope.clearGrid();
      $scope.query = undefined;

      $scope.queryTotalRecords = 0;
      $scope.DatasetTotalRecords = "...";
      $scope.queryTotalRecordsRun = false;
      $scope.DatasetTotalRecordsRun = false;
      $scope.resetQueryError();
      $scope.runningQueryWithFieldCriteria = true;
      $scope.NumberOfRecordsToPull = $scope.NumberOfRecordsToPullSave;

      // Now, set the datasource for the grid back to the real thing.
      $scope.dataAgGridOptions.api.setDatasource($scope.dataSource);

      // Update the offset and endRow.
      params.startRow = 0;
      params.endRow = $scope.NumberOfRecordsToPull;

      $scope.dataSource.getRows(params);
    };

    $scope.runQueryWithCriteriaLoadAllItems = function () {

      if ($scope.DatasetTotalRecords > 300000) {
        alert("The requested query results are currently too intense for this system.  Please contact the GIS office for support with this request.");
        //$scope.queryResult.Error = true;
        //$scope.queryResult.ErrorMessage = "The query results are currently too intense for this system.  Please contact the GIS office for support with this request.";
        return;
      }

      /*
      // Dump the last results and reset things.
      $scope.clearGrid();
      $scope.query = undefined;

      $scope.queryTotalRecords = 0;
      //$scope.queryTotalRecordsRun = false;  // Should be true still.
      //$scope.DatasetTotalRecordsRun = false; // Should be true still.
      $scope.resetQueryError();
      //$scope.runningQueryWithFieldCriteria = true; // More thoughts on this below.

      // Now, set the datasource for the grid back to the real thing.
      $scope.dataAgGridOptions.api.setDatasource($scope.dataSource);

      if ($scope.fieldCriteriaRemoved)
          $scope.runningQueryWithFieldCriteria = false;

      $scope.runningQueryToLoadAllRecords = true;
      */
      // Set the starting row and ending row, so that we can get all the records.
      $scope.infGridParams = null;
      $scope.infGridParams = {
        startRow: 0,
        endRow: $scope.DatasetTotalRecords,
        NumberOfRecordsToPull: $scope.DatasetTotalRecords
      };
      //$scope.infGridParams.startRow = 0;
      //$scope.infGridParams.endRow = $scope.DatasetTotalRecords;
      //$scope.infGridParams.NumberOfRecordsToPull = $scope.DatasetTotalRecords;

      // Copy to $root, so that the next page can access it.
      $rootScope.infGridParams = angular.copy($scope.infGridParams);
      $rootScope.criteriaList = angular.copy($scope.criterialList);

      // $scope.NumberOfRecordsToPull is set initially when the page/dataset loads.
      $scope.NumberOfRecordsToPull = $scope.DatasetTotalRecords;

      // Run the query.
      //$scope.dataSource.getRows($scope.infGridParams);


      //$location.path("/datasetqueryviewallrecords/" + $scope.dataset.Id);
      $location.path("[YourReportServerUrl]/" + $scope.dataset.Datastore.TablePrefix);

    };

    // this is the function ag-grid calls to add more data to the table
    $scope.agGridDataSource = {
      getRows: function (params) {
        $scope.query.loading = true;
        $scope.dataAgGridOptions.api.showLoadingOverlay();
        $scope.dataAgGridOptions.api.paginationSetPageSize(1000);
        $scope.DatasetTotalRecords = 1000;
        $scope.criteriaList = [];
        // always include the index field
        $scope.orderByCriteria(params);

        $scope.query = $scope.buildQuery();

        $scope.sortModel = params.request.sortModel;
        $scope.query.criteria.GetTotalCount = false;
        $scope.query.criteria.FirstId = $scope.firstId;
        $scope.query.criteria.LastSeenId = $scope.lastSeenId;
        // include a sort model and filter for that ID
        $scope.query.criteria.sortModel = params.request.sortModel;
        $scope.query.criteria.ResultsPerPage = 1000;
        $scope.query.criteria.lastRecord = $scope.query.results != undefined && $scope.query.results.length > 0 ? $scope.query.results[$scope.query.results.length - 1] : null;
        // if we need the total record count, get that first
        if ($scope.filterChanged || $scope.queryTotalRecords == null) {
          $scope.queryRecordsCount().then(() => {
            return $scope.queryActivities(params);
          });
        }
        // if the filter model hasn't changed, we don't need to query the record count
        else {
          return $scope.queryActivities(params);
        }

      }
    };

    $scope.queryActivities = function (params) {
      $scope.overlayResultBool = false;

      $scope.query.results = DatasetService.queryActivities($scope.query);

      $scope.query.results.$promise.then((item) => {
        if (item.SqlError) {
          $scope.query.loading = false;
          console.error("SQL Timeout in the backend...");

          $scope.queryResult.Error = true;
          $scope.queryResult.ErrorMessage = "The query results are currently too intense for this system.  Please contact the GIS office for support with this request.";
          //alert($scope.queryResult.ErrorMessage);
          return;
        }

        if ((item.TotalRecords) && (item.TotalRecords >= MAX_DATASET_RECORDS_THRESHOLD)) {
          $location.path("https://paluutreports.ctuir.org/Reports/browse/" + $scope.dataset.Datastore.TablePrefix);
        }


        $scope.instrumentAccuracyCheckList.forEach((accCheck) => {
          if (item.AccuracyCheckId === accCheck.Id) {
            item.AccuracyCheckId = accCheck.Bath1Grade + "-" + accCheck.Bath2Grade + " " + moment(accCheck.CheckDate).format('MMM DD YYYY');
          }
          if (item.PostAccuracyCheckId === accCheck.Id) {
            item.PostAccuracyCheckId = accCheck.Bath1Grade + "-" + accCheck.Bath2Grade + " " + moment(accCheck.CheckDate).format('MMM DD YYYY');
          }
        });

        if (item.Timezone) {
          const parsedTimezone = JSON.parse(item.Timezone);
          item.Timezone = parsedTimezone.Name;
        }

        if (item.LocationLabel) {
          item.LocationId = item.LocationLabel;
        }

        if (item.QAStatusName) {
          item.ActivityQAStatusId = item.QAStatusName;
        }

        if (item.InstrumentLabel) {
          item.InstrumentId = item.InstrumentLabel;
        }

        // $scope.queryTotalRecords++;

        const resLen = $scope.query.results.length;
        if (resLen > 0) {
          $scope.lastSeenId = $scope.query.results[resLen - 1]?.ix;
          $scope.resultCount = resLen;
        } else {
          $scope.lastSeenId = 0;
        }


        if ($scope.queryResult.Error) {
          return;
        } // Get us out of here.

        $scope.DatasetTotalRecords = $scope.query.results.length;
        $scope.overlayResultBool = true;
        if ($scope.overlayResultBool && $scope.overlayCountBool) {
          $scope.query.loading = false;
          $scope.dataAgGridOptions.api.hideOverlay();
        }
        return params.successCallback($scope.query.results, $scope.queryTotalRecords);
      });

    };


    $scope.queryActivitiesExport = function (params) {
      $scope.query.results = DatasetService.queryActivities($scope.query);

      $scope.query.results.$promise.then((item) => {
        if ($scope.queryResult.Error) {
          return;
        }

        $scope.query.loading = false;
        $scope.DatasetTotalRecords = $scope.query.results.length;
        return params.successCallback($scope.query.results.StatusCode, $scope.queryTotalRecords);
      });

    };


    $scope.executeQuery = function (params) {

      //$scope.query = $scope.buildQuery();

      //if ($scope.prevStartRow !== params.startRow)
      {
        $scope.query.results = DatasetService.queryActivities($scope.query);

        $scope.query.results.$promise.then(function () {

          if (!$scope.query.results.$resolved) {
            return;
          }

          $scope.resultCount = $scope.query.results.length;

          var count = 0;
          $scope.query.results.forEach(function (item) {
            if (item.SqlError) {
              console.error("SQL Timeout in the backend...");

              $scope.queryResult.Error = true;
              $scope.queryResult.ErrorMessage = "The query results are currently too intense for this system.  Please contact the GIS office for support with this request.";
              //alert($scope.queryResult.ErrorMessage);
              return;
            }

            if (count == 0) {
              $scope.firstId = item.ix;
            }

            // if ((item.TotalRecords) && (item.TotalRecords >= MAX_DATASET_RECORDS_THRESHOLD)) {
            //   $location.path("https://paluutreports.ctuir.org/Reports/browse/" + $scope.dataset.Datastore.TablePrefix);
            // }


            $scope.instrumentAccuracyCheckList.forEach(function (accCheck) {
              if (item.AccuracyCheckId === accCheck.Id) {
                item.AccuracyCheckId = accCheck.Bath1Grade + "-" + accCheck.Bath2Grade + " " + moment(accCheck.CheckDate).format('MMM DD YYYY');
              }
              if (item.PostAccuracyCheckId === accCheck.Id) {
                item.PostAccuracyCheckId = accCheck.Bath1Grade + "-" + accCheck.Bath2Grade + " " + moment(accCheck.CheckDate).format('MMM DD YYYY');
              }
            });

            if (item.Timezone) {
              var parsedTimezone = JSON.parse(item.Timezone);
              item.Timezone = parsedTimezone.Name;
            }

            if (item.LocationLabel) {
              item.LocationId = item.LocationLabel;
            }


            if (item.QAStatusName) {
              item.ActivityQAStatusId = item.QAStatusName;
            }


            if (item.InstrumentLabel) {
              item.InstrumentId = item.InstrumentLabel;
            }

            count++;

            if (count == $scope.query.results.length) {
              $scope.lastSeenId = item.ix;
              $scope.lastIx = item.ix;
            }
          });

          if ($scope.queryResult.Error) {
            return; // Get us out of here.
          }

        }
        );
      }


    };


    // No longer used
    // $scope.queryGetRecordCount = function() {

    //   $scope.query.criteria.GetTotalCount = true;

    //   $scope.query.TotalRecordCount = DatasetService.queryActivities($scope.query);
    //   $scope.query.TotalRecordCount.$promise.then(function() {
    //     // There should only be one...
    //     $scope.query.TotalRecordCount.forEach(function(item) {
    //       $scope.DatasetTotalRecords = item.TotalRecords;
    //     });
    //     $scope.query.criteria.GetTotalCount = undefined;
    //   });
    // };


    $scope.buildQuery = function () {
      $scope.queryCriteriaList = angular.copy($scope.criteriaList);
      $scope.queryCriteriaList.forEach(function (criteria) {
        try {
          if (Array.isArray(criteria.Value)) {
            criteria.Value = angular.toJson(criteria.Value);
          }
        } catch (e) {
          //oh well.
        }
      });
      $rootScope.queryCriteriaList = angular.copy($scope.queryCriteriaList);

      var query = null;
      query =
      {
        criteria: {
          DatasetId: $scope.dataset.Id,
          //Fields: 	  queryCriteriaList,
          Fields: [],
          Criteria: $scope.queryCriteriaList,
          TablePrefix: $scope.dataset.Datastore.TablePrefix,
          FirstId: $scope.firstId,
          LastSeenId: $scope.lastSeenId,
          ResultsPerPage: $scope.resultsPerPage,
          TotalRecords: 0,
          sortModel: $scope.sortModel,
          //startRow: params.startRow,
          //endRow: params.endRow,
          //sortModel: params.sortModel,
          filterModel: $scope.filterModel,
          nextPage: $scope.nextPage,
          //NumberOfRecordsToPull: $scope.NumberOfRecordsToPull,
          //LastRecord:  $scope.lastRecord,
          //params: params,
        },
        loading: true,
      };

      return query;
    };

    $scope.removeCriteria = function (idx) {
      $scope.criteriaList.splice(idx, 1);

      $scope.fieldCriteriaRemoved = true;

      if ($scope.AutoExecuteQuery) {
        $scope.executeQuery();
      }
    };

    $scope.orderByCriteria = function (params, lastRow) {
      if (params.request.sortModel.length > 0) {
        // append to the scope criteria for paginating using an orderby criteria
        // create a simple index so we don't have to loop through the dataset fields for each sortmodel

        $scope.fieldIndex = {};
        $scope.dataset.Fields.forEach((field, index) => {
          if (!$scope.fieldIndex.hasOwnProperty(field.DbColumnName)) {
            $scope.fieldIndex[field.DbColumnName] = index;
          }
        });

        params.request.sortModel.forEach((sm) => {
          // get the field first to establish base criteria, then just assign a value and add it to the $scope criteria
          const baseCriteria = $scope.dataset.Fields[$scope.fieldIndex[sm.colId]];
          // if it is ascending, we want the next page to be greater than
          const operator = sm.sort.toLowerCase() === 'asc' ? '>' : '<';
          // get the value of the last record

          // update the scope criteria list
        });

        // always include the index field
        $scope.criteriaList.push({
          DbColumnName: 'ix',
          Id: 'ix',
          // todo: handle differently for sortmodels
          Value: ` > ${$scope.lastSeenId}`,
          DisplayName: 'index',
        });

      }
    };

    $scope.execute = function () {
      $scope.overlayCountBool = false;
      $scope.query = undefined;
      $scope.query = $scope.buildQuery();
      $scope.lastSeenId = 0;
      $scope.query.criteria.GetTotalCount = false;
      $scope.filterChanged = true;
      $scope.filterModel = $scope.dataAgGridOptions.api.getFilterModel();
      var params = {
        request: {
          "startRow": 0,
          "endRow": 999,
          "rowGroupCols": [],
          "valueCols": [],
          "pivotCols": [],
          "pivotMode": false,
          "groupKeys": [],
          "filterModel": $scope.filterModel,
          "sortModel": []
        },
        successCallback: function (results, records) { },
      }
      params.successCallback([], 0);
      $scope.agGridDataSource.getRows(params);
      $scope.filterChanged = false
    }


    $scope.openActivity = function () {
      console.dir($scope.selectedRow);
      $location.path("/dataview/" + $scope.selectedRow.ActivityId);
    };

    //export the data - button click
    $scope.doExport = function () {

      if (!$scope.ExportFilename) {
        alert("Please enter a name for your export file like: 'TucannonJune25Export'.");
        return;
      }

      var params = {
        fileName: $scope.ExportFilename + ".xlsx",
        processCellCallback: $scope.processCellDataForOutput,
      };

      $scope.dataAgGridOptions.api.exportDataAsExcel(params);
    };

    //this is used by both export and copy functions to de-reference our values
    $scope.processCellDataForOutput = function (params) {

      //here we do any special formatting since export does NOT call cell renderers or cell formatters...
      //if ((params.column.colDef.DbColumnName == "LocationId") && (params.column.colDef.PossibleValues !== null)) {
      if ((params.column.colDef.DbColumnName == "LocationId") && ($scope.dataset.Locations !== null)) {
        //return params.column.colDef.PossibleValues[params.value];

        // For locations, overwrite the params value with the Label,
        // rather than the Id, before it goes to the file, so that
        // the text name shows in the file, rather than the Id.
        $scope.dataset.Locations.forEach(function (item) {
          if (params.value == item.Id) {
            params.value = item.Label;
          }
          //return item.Label;
        });

      }

      if ((params.column.colDef.DbColumnName == "InstrumentId") && (params.column.colDef.PossibleValues !== null)) {
        return params.column.colDef.PossibleValues[params.value];
      }

      if ((params.column.colDef.DbColumnName == "FishermanId") && (params.column.colDef.PossibleValues !== null)) {
        return params.column.colDef.PossibleValues[params.value];
      }

      if ((params.column.colDef.DbColumnName == "StreamName") && (params.column.colDef.PossibleValues !== null)) {
        return params.column.colDef.PossibleValues[params.value];
      }

      if ((params.column.colDef.DbColumnName == "ActivityQAStatusId") && (params.column.colDef.PossibleValues !== null)) {
        return params.column.colDef.PossibleValues[params.value];
      }

      if ((params.column.colDef.DbColumnName == "RowQAStatusId") && (params.column.colDef.PossibleValues !== null)) {
        return params.column.colDef.PossibleValues[params.value];
      }

      if (params.column.colDef.ControlType == 'file' || params.column.colDef.ControlType == 'link') {
        var retvalue = [];
        var files = getFilesArrayAsList(params.value);
        files.forEach(function (file) {
          if (params.column.colDef.ControlType == 'file') {
            if (file.Name) {
              retvalue.push(file.Name);
            } else {
              retvalue.push(file);
            }
          } else if (params.column.colDef.ControlType == 'link') {
            retvalue.push(file.Link);
          }
        });
        return retvalue.join();
      }

      if (params.column.colDef.ControlType == "datetime" || params.column.colDef.ControlType == "time") {

        let retval = params.value;
        try {
          retval = moment(params.value).format("YYYY-MM-DD HH:mm:ss");
        } catch (e) {
          console.dir(e);
        }
        return retval;
      }

      if (params.column.colDef.ControlType == "date" || params.column.colDef.ControlType == "activity-date") {
        let retval = params.value;
        try {
          retval = moment(params.value).format("YYYY-MM-DD");
        } catch (e) {
          console.dir(e);
        }
        return retval;
      }

      //default
      return params.value;

    };

    $scope.resetQueryError = function () {
      $scope.queryResult.Error = false;
      $scope.queryResult.ErrorMessage = null;
    };


    // Download query data records as a csv file or csv zip file, showing a progress bar in the modal
    $scope.openExportModal = function () {
      const templateUrl = 'appjsLegacy/core/datasets/components/dataset-query/templates/modal-export-records.html';

      $scope.isExportClicked = false;
      if ($scope.queryTotalRecords > DATASET_RECORDS_EXPORT_FORMAT_THRESHOLD) {
        $scope.isLessThanThreshold = false;
        $scope.modalData = { // !!! The exportFormat property needs to be set that way to allow a correct 2 ways data binding with the radio buttons in the modal
          exportFormat: 'zipped-csv',
        }
      }
      else {
        $scope.isLessThanThreshold = true;
        $scope.modalData = {
          exportFormat: 'csv',
        }
      }

      // Open the modal
      $scope.modalInstance = $uibModal.open({
        templateUrl: templateUrl,
        scope: $scope, // Pass the current scope to the modal
        backdrop: "static",
        keyboard: true
      });
    };


    $scope.exportRecords = async function () {
      // Send the displayed fields that we want to export from the grid
      try {
        let displayedFields = [];
        $scope.dataAgGridOptions.columnDefs.forEach(i => {
          if (i.ControlType != "hidden" && i.ControlType != "ix" && i.hide != true) {
            displayedFields.push(i.cdmsField.DbColumnName);
          }
        });

        $scope.isExportClicked = true;
        $scope.isExporting = true;
        $scope.exportText = 'Exporting...';

        const query = $scope.buildQuery();
        query.criteria.GetTotalCount = false; // That is expected to be false because we are exporting records
        query.criteria.ExportFormat = $scope.modalData.exportFormat;  // The export format to be sent in the request body
        query.criteria.Fields = displayedFields;

        const body = query.criteria; // The query criteria object to be sent in the request body

        // Making the post request to the server
        const response = await fetch(`${serviceUrl}/api/v1/query/querydatasetactivitiescsv`, {
          method: 'POST',
          headers: {
            accept: "application/json",
            "content-type": "application/json",
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(body) // Convert the query criteria object to JSON string and send it in the request body
        });

        // Check if the response is successful (status code 200)
        if (response.ok) {
          // Parse the content-disposition header to extract the filename
          const contentDisposition = response.headers.get('Content-Disposition');
          const filename = contentDisposition.split('filename=')[1].trim();

          // Use FileSaver.js to trigger file download
          const blob = await response.blob();
          saveAs(blob, filename);

          // Set variables to their initial state
          $scope.isExporting = false;
          $scope.exportText = 'Export Records';
          $scope.closeModalOnSuccess();
        }
        else {
          // Handle errors or non-200 responses
          const msg = await response.text();
          console.error('Error exporting records:', msg);
          $scope.isExporting = false;
          $scope.exportText = 'Export Records';
          $scope.closeModalOnError();
        }
      }
      catch (error) {
        // Handle errors
        console.error('Error exporting records:', error);
        $scope.isExporting = false;
        $scope.exportText = 'Export Records';
        $scope.closeModalOnError();
      }
    };

    $scope.closeModal = function () {
      $scope.modalInstance.close();
    };

    $scope.closeModalOnSuccess = function () {
      let alertMessage = `SUCCESS: Your ${$scope.modalData.exportFormat} file of ${$scope.queryTotalRecords} records ` +
        "has been successfully downloaded into your browser's default download location.";
      $scope.closeModalSendAlert(alertMessage);
    };

    $scope.closeModalOnError = function () {
      let alertMessage = `ERROR: An error occurred while dowloading your ${$scope.modalData.exportFormat} file of ${$scope.queryTotalRecords} records. ` +
        "Please, try again later or contact support.";
      $scope.closeModalSendAlert(alertMessage);
    };

    $scope.closeModalSendAlert = function (alertMessage: string) {
      $scope.closeModal();
      setTimeout(function () {
        alert(alertMessage);
      }, 1000);
    }
  }];


export default dataset_query;
