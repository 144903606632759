/*
*   This lets you edit possiblevalues for select/multiselct fields in your dataset...
*/
import * as angular from 'angular';

import { Grid } from "ag-grid-community";
import 'ag-grid-enterprise';


var project_lookups = ['$scope', '$routeParams', 'GridService', 'ProjectService', 'DatasetService', 'CommonService', 'UserService',
    '$rootScope', '$uibModal', '$sce', '$window', '$http',
    'ServiceUtilities', '$location', '$anchorScroll',
    function (scope, routeParams, GridService, ProjectService, DatasetService, CommonService, UserService, $rootScope, $uibModal, $sce, $window, $http,
        ServiceUtilities, $location, $anchorScroll) {
        //console.log("Inside controllers.js, projectDatasetsController...");
        //console.log("routeParams.Id = " + routeParams.Id);

        scope.OnTab = "Lookups";

        scope.project = ProjectService.getProject(routeParams.Id);
        scope.selectedLookup = null;

        //to support metadata lookup editing
        scope.metadataentities = CommonService.getMetadataEntities();
        scope.selectedEntity = null;

        scope.project.$promise.then(function () {
            //console.log("project done loading")
            try {
                /*
                //note: this is already done now in ProjectService.getProject
                if(typeof scope.project.Config == 'string')
                    scope.project.Config = angular.fromJson(scope.project.Config);
                else
                    scope.project.Config = {}
                */
                if (scope.project.Config.hasOwnProperty('Lookups')) {
                    scope.lookupTables = scope.project.Config.Lookups;
                    if (scope.lookupTables.length > 0) {
                        scope.selectLookup(scope.lookupTables[0]);
                    }
                    //console.log("scope.lookupTables is next...");
                    //console.dir(scope.lookupTables);
                }

            } catch (e) {
                console.error("config could not be parsed for project: " + scope.project.Config);
                console.dir(e);
            }

        });

        scope.deselectAll = function () {
            scope.dataGridOptions.api.setFilterModel(null)
            scope.dataGridOptions.api.onFilterChanged();
            scope.dataGridOptions.api.deselectAll();
        }

        scope.selectLookup = function (lookup) {
            console.log("selected lookup ------");
            console.dir(lookup);

            scope.selectedLookup = lookup;

            if (lookup.hasOwnProperty('Type') && lookup.Type == "Metafields") {
                scope.metadataentities.forEach(function (entity) {
                    if (entity.Id == lookup.Id) {
                        scope.selectedEntity = entity;
                        //console.log("selected entity = ");
                        //console.dir(entity);

                    }
                });
            }

            if (scope.project.Config.hasOwnProperty('ShowDelete')) {
                scope.ShowDelete = scope.project.Config.ShowDelete;

                console.log("ShowDelete = " + ((scope.dataGridOptions.selectedItems.length > 0) && (scope.ShowDelete == true)));

                //if (scope.HideItems.contains("delete"))
                //    scope.HideDelete = true;
            }

            //if a lookup doesn't have a dataset then don't try to load up the grid.
            if (lookup.DatasetId == null)
                return;

            scope.lookupItems = CommonService.getLookupItems(lookup);

            scope.selectedLookup.Dataset = DatasetService.getDataset(scope.selectedLookup.DatasetId);

            scope.selectedLookup.Dataset.$promise.then(function () {
                scope.dataGridOptions.columnDefs = null;
                //scope.dataGridOptions.columnDefs = GridService.getAgColumnDefs(scope.selectedLookup.Dataset).HeaderFields;

                console.log("HeaderFields.length = " + (GridService.getAgColumnDefs(scope.selectedLookup.Dataset).HeaderFields.length));
                //console.dir(GridService.getAgColumnDefs(scope.selectedLookup.Dataset).HeaderFields);
                console.log("DetailFields.length = " + (GridService.getAgColumnDefs(scope.selectedLookup.Dataset).DetailFields.length));
                //console.dir(GridService.getAgColumnDefs(scope.selectedLookup.Dataset).DetailFields.length);

                if (GridService.getAgColumnDefs(scope.selectedLookup.Dataset).HeaderFields.length > 0) {
                    scope.dataGridOptions.columnDefs = GridService.getAgColumnDefs(scope.selectedLookup.Dataset).HeaderFields;
                }

                if (GridService.getAgColumnDefs(scope.selectedLookup.Dataset).DetailFields.length > 0) {
                    scope.dataGridOptions.columnDefs = GridService.getAgColumnDefs(scope.selectedLookup.Dataset).DetailFields;
                }


                scope.dataGridOptions.columnDefs.unshift({ field: 'EditLink', headerName: '', cellRenderer: EditLinkTemplate, width: 50, menuTabs: [], hide: true }); //alwaysShowField: true, ?

                scope.lookupItems.$promise.then(function () {
                    if (!scope.datatab_ag_grid)
                        scope.activateDataGrid();
                    else {
                        scope.deselectAll();
                        scope.dataGridOptions.api.setRowData(scope.lookupItems);
                        scope.dataGridOptions.api.setColumnDefs(scope.dataGridOptions.columnDefs);  //redraws everything
                    }

                    //unhide the edit link column if they are owner/admin.
                    if ($rootScope.Profile.canAdminProject(scope.project)) {
                        scope.dataGridOptions.columnApi.setColumnVisible("EditLink", true);
                        scope.dataGridOptions.api.refreshHeader();
                    } else {
                        console.log("canAdminProject = false - hiding editlink")
                    }
                });


            });


        }


        var EditLinkTemplate = function (param) {

            var div = document.createElement('div');

            var editBtn = document.createElement('a'); editBtn.href = '#'; editBtn.innerHTML = 'Edit';
            editBtn.addEventListener('click', function (event) {
                event.preventDefault();
                scope.openEditModal(param.data);
            });
            div.appendChild(editBtn);
            //div.appendChild(document.createTextNode(" | "));

            /*var delBtn = document.createElement('a'); delBtn.href = '#'; delBtn.innerHTML = 'Delete';
            delBtn.addEventListener('click', function (event) {
                event.preventDefault();
                scope.removeCharacteristic(param.data);
            });
            div.appendChild(delBtn);
            */

            return div;
        };

        scope.changeFilterIcons = function (event) {
            // We need to call this onFilterModified and onBodyScroll, as the elements are removed/added
            // to the DOM when scrolling horizontally on wide datasets.
            // Can also pass $scope.dataAgGridOptions to this function rather than an event.
            let filters = scope.dataGridOptions.api.getFilterModel();

            for (let col of event.api.columnController.columnDefs) {
                for (const span of document.querySelectorAll("span")) {
                    if (span.textContent.includes(col.field) || span.textContent.includes(col.headerName)) {
                        // Naming conventions vary, so lets check all of these to see if the column has a filter
                        if (col.Label in filters || col.headerName in filters || col.field in filters) {
                            // If column has a filter change the icon color
                            // @ts-ignore
                            let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];
                            icon.style.color = "#007bff";
                        }
                        else {
                            // Remove the color prop from the icon if there is no filter
                            // @ts-ignore
                            let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];// filterIcon.children[0];
                            if (icon && icon.style) {
                                icon.style.color = "#bdbdbd";
                            }
                        }
                    }
                }
            }
        };

        //datasets tab grid
        scope.dataGridOptions = {
            //data: 'datasets',
            //enableSorting: true,
            //enableFilter: true,
            //enableColResize: true,
            suppressPropertyNamesCheck: true,
            columnDefs: [],
            rowSelection: 'multiple',
            onSelectionChanged: function (params) {
                scope.dataGridOptions.selectedItems = scope.dataGridOptions.api.getSelectedRows();
                scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
                console.dir(params);
            },
            onBodyScroll: function (event) {
                scope.changeFilterIcons(event);
            },
            onFilterModified: function (event) {
                scope.changeFilterIcons(event);
            },
            selectedItems: [],
            defaultColDef: {
                sortable: true,
                resizable: true,
                icons: {
                    menu: "<span class='material-symbols-outlined' id='filter-icon' title='Filter'>filter_alt</span>", filter: "<span></span>",
                }
            },
        };

        //note: the template links other controllers than this one, for example in admin-metafields.js
        scope.activateDataGrid = function () {
            console.log("activating local data grid");
            var ag_grid_div = document.querySelector('#lookups-grid') as HTMLElement;    //get the container id...
            scope.datatab_ag_grid = new Grid(ag_grid_div, scope.dataGridOptions); //bind the grid to it.
            scope.dataGridOptions.api.showLoadingOverlay(); //show loading...

            scope.dataGridOptions.api.setRowData(scope.lookupItems);

            if ($rootScope.Profile.canEdit(scope.project)) {
                scope.dataGridOptions.columnApi.setColumnVisible("EditLink", true);
                scope.dataGridOptions.api.refreshHeader();
            }
        };

        scope.openEditModal = function (a_selection) {
            scope.SaveMessage = null;
            scope.row = angular.copy(a_selection);

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/modal-edit-lookup-item.html',
                controller: 'ModalEditLookupItemCtrl',
                scope: scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_item) {
                //replace that item in the grid with the one we got back
                var found = false;

                scope.lookupItems.forEach(function (existing, index) {
                    if (existing.Id == saved_item.Id) {
                        console.dir("found field to replace : " + existing.Id);
                        scope.lookupItems[index] = saved_item;
                        found = true;
                    }
                });

                if (!found)
                    scope.lookupItems.push(saved_item);

                scope.deselectAll();
                scope.dataGridOptions.api.setRowData(scope.lookupItems);
                scope.SaveMessage = "Success.";

            });
        };

        scope.addItem = function (a_selection) {
            scope.SaveMessage = null;
            scope.row = {};

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/modal-edit-lookup-item.html',
                controller: 'ModalEditLookupItemCtrl',
                scope: scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_item) {
                //add that item in the grid with the one we got back
                scope.lookupItems.push(saved_item);
                scope.deselectAll();
                scope.dataGridOptions.api.setRowData(scope.lookupItems);
                scope.SaveMessage = "Success.";
            });
        };

        //Delete
        scope.deleteItems = function () {
            var lookupId = scope.selectedLookup.Id;
            scope.dataGridOptions.selectedItems.forEach(function (item) {
                var delete_item = CommonService.deleteLookupItem(item, lookupId);
                delete_item.$promise.then(function () {
                    scope.lookupItems.forEach(function (existing, index) {
                        if (existing.Id == item.Id) {
                            scope.lookupItems.splice(index);
                            scope.dataGridOptions.api.setRowData(scope.lookupItems);
                            scope.selectLookup(scope.selectedLookup);

                        }

                    }), function () {
                        alert("There was a problem deleting item(s) from " + scope.selectedLookup.Label + ".  Please contact CDMS support.");
                    };
                });
            }, function () {
                console.log("something went wrong");
            });

        };



        scope.deleteItems = function (a_selection) {

        };

        scope.removeCharacteristic = function (characteristic) {
            scope.viewCharacteristic = characteristic;
            if (!scope.viewCharacteristic)
                return;

            if (confirm("Are you sure you want to remove this characteristic from this Project?")) {
                var promise = ProjectService.removeCharacteristic(scope.viewCharacteristic.Id);

                promise.$promise.then(function () {
                    scope.project.Charactersitics.forEach(function (item, index) {
                        if (item.Id === scope.viewCharacteristic.Id) {
                            scope.project.Charactersitics.splice(index, 1);
                            scope.instrGridOptions.api.setRowData(scope.project.Charactersitics);
                        }
                    });
                });
            }
        };

        //handle favorite toggle
        scope.isFavorite = $rootScope.Profile.isProjectFavorite(routeParams.Id);
        scope.toggleFavorite = function () {
            UserService.toggleFavoriteProject(scope, $rootScope);
        }

    }

];


export default project_lookups;



