import { Component, OnInit } from '@angular/core';
import { uiConfig, loginUrl, serverUrl, successUrl, USER_PREFERENCE_LANDINGPAGE } from "../../../../appjsLegacy/config";
import { LoginService } from "../login.service";
import { Router } from "@angular/router";
// import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
// import {Observable} from "rxjs";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loggingIn: boolean;
  public loginMessage: string;
  public logoSrc: string;
  public welcomeText: string;
  public welcomeSubtext: string;
  // public login: () => void;
  public Password: string;
  public errorHeader: string | boolean;
  public error: string | boolean;
  public Username: string;
  public EncryptedPassword: any;
  public successUrl = successUrl;
  public loginUrl = loginUrl;


  constructor(private loginService: LoginService, private router: Router,) {
  }

  ngOnInit(): void {
    console.log("Inside login.component.ts, ngOnInit...");
    this.logoSrc = uiConfig.logo;
    this.welcomeText = uiConfig.welcomeText;
    this.welcomeSubtext = uiConfig.welcomeSubtext;
  }

  loginControl() {
    console.log("Inside controller LoginCtrl...");
    this.loggingIn = false;
    this.loginMessage = "Logging in...";

    // var login = () => {
    console.log("Inside function login...");

    //var strServerNumber = "";
    //var strClientNumber = "";
    var strFirstNumber = "";
    var strSecondNumber = "";

    var strOriginalText = this.Password;

    var oText = {
      strText: "",
      intNumber: -1
    };

    oText.strText = "";

    oText.intNumber = -1;
    //oText.intSize = -1;


    this.error = undefined; //clear any error
    this.errorHeader = undefined;

    if (!this.Username || !this.Password)
      return;

    // For some reason, the login runs twice, without this if check to prevent it.
    if (this.loggingIn === false) {
      this.loggingIn = true;
      try {
        this.EncryptedPassword = this.loginService.encrypt(this.Password);
        try {
          let login_try = this.loginService.login(this.Username, this.EncryptedPassword);
          console.log("Trying to login...");

          //IMPORTANT: Do not deploy the following lines to production uncommented!
          //They will show the password that was entered by the User!
          //console.dir(this);
          //console.dir(Router);

          if (login_try) {
            login_try.toPromise().then((data) => {

              //@ts-ignore
              if (data.Success) {
                console.log("login was successful...");

                //@ts-ignore
                this.loginMessage = data.Message;

                //Since there are now more than two options for routing after a successful
                // login, and to allow for this number to grow even higher, we can use
                // an enumerated constant and a case for routing.
                const LandingPage = {
                  FileDownload: "FileDownload",
                  UserPreference: "UserPreference",
                  Dashboard: "Dashboard",
                  None: ""
                }

                var landingPage = LandingPage.None;

                //Check for file redirection URL. If found, redirect to file.
                //Otherwise set successUrl to landingpage if there is a preference.
                // @ts-ignore
                if (data.isRedirect) {
                  // //@ts-ignore
                  // console.log(data.fileRedirectionURL);

                  console.log("data.isRedirect is TRUE...");
                  landingPage = LandingPage.FileDownload;

                  // //@ts-ignore
                  // window.location.href = data.fileRedirectionURL;
                } else {
                  //@ts-ignore
                  if (data.User && data.User.UserPreferences) {
                    console.log("data.User has preferences...");
                    //@ts-ignore
                    for (var i = data.User.UserPreferences.length - 1; i >= 0; i--) {
                      // @ts-ignore
                      var pref = data.User.UserPreferences[i];

                      if (pref.Name == USER_PREFERENCE_LANDINGPAGE || pref.Name == 'LandingPage') {
                        if (pref.Value !== "" && pref.Value !== "/") {
                          this.successUrl = pref.Value;
                        } else {
                          this.successUrl = successUrl;
                        }
                        // this.router.navigateByUrl(pref.Value);
                      }
                    }
                    //this.router.navigateByUrl(this.successUrl);
                    landingPage = LandingPage.UserPreference;
                  } else {
                    console.log("data.User has NO preferences set...");
                    // this.router.navigateByUrl('dashboard');
                    landingPage = LandingPage.Dashboard;
                  }
                }

                switch (landingPage) {
                  case LandingPage.Dashboard:
                    this.router.navigateByUrl('dashboard');
                    break;

                  case LandingPage.FileDownload:
                    //NOTE: Tried using this.router.navigateByUrl(data.fileRedirectionURL), but
                    // it was not actually loading the page indicated by the URL. Subsequently
                    // tried using this.router.navigate(data.fileRedirectionURL), but that threw
                    // an error from angular. Currently using window.location.href, which simulates
                    // physically clicking a link.

                    //window.location.href = data.fileRedirectionURL;

                    //@ts-ignore
                    //window.open(data.fileRedirectionURL, '_blank');
                    alert('LOGIN SUCCESSFUL!\nPlease try your file link again.');
                    this.router.navigateByUrl('dashboard');
                    break;

                  case LandingPage.UserPreference:
                    this.router.navigateByUrl(this.successUrl);
                    break;

                  default:
                    console.log("Error finding preferred path. Logging out.");
                    this.loginService.logout();
                }

              } else {
                this.loggingIn = false;
                // @ts-ignore
                // Provide more meaningful message for invalid username
                if (data.Message == "Username is Inactive.") {
                  this.errorHeader = `"${this.Username}" is not a valid username.`
                  this.error = "Please contact your System Administrator"
                } else {
                  this.errorHeader = "Failed to login";
                  // @ts-ignore
                  this.error = data.Message;
                }
                this.Password = "";

                console.warn('login failed');
                // window.location = this.loginUrl;
                this.router.navigateByUrl('login');
              }
            });
          } else {
            this.loggingIn = false;
            this.errorHeader = "Failed to login";
            this.error = 'There was a problem trying to login.  Contact the helpdesk if you need further assistance.';
            this.loginMessage = 'There was a problem trying to login.  Contact the helpdesk if you need further assistance.';
          }
        } catch (e) {
          console.dir(e);
        }
      } catch (e) {
        console.dir(e);
      }
    }
  }
}




