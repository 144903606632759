import * as angular from 'angular';
import * as moment from 'moment';
import { orderUserByAlpha } from "../../../common/common-functions";


import { Grid } from "ag-grid-community";
import 'ag-grid-enterprise';

var admin_org = ['$scope', '$uibModal', 'CommonService', 'AdminService',
    function (scope, $uibModal, CommonService, AdminService) {

        if (!scope.Profile.isAdmin())
            angular.rootScope.go("/unauthorized");

        scope.organizations = CommonService.getOrganizations();

        scope.organizations.$promise.then(function () {

            //filter to only those I can admin
            if (!scope.Profile.IsSuperAdmin) {
                var orgsAdministered = [];
                scope.organizations.forEach(function (org) {
                    if (scope.Profile.OrganizationId == org.Id) {
                        orgsAdministered.push(org);
                    }
                });
                scope.organizations.length = 0;
                scope.organizations = scope.organizations.concat(orgsAdministered);
                console.dir(orgsAdministered);
                console.dir(scope.organizations);
            }

            var agColumnDefs = [
                { field: 'Name', headerName: 'Name', width: 280, menuTabs: ['filterMenuTab'], filter: true, sort: 'asc' },
                { field: 'Description', headerName: 'Description', width: 400, menuTabs: ['filterMenuTab'], filter: 'text' },
            ];

            scope.orgGrid = {
                suppressPropertyNamesCheck: true,
                columnDefs: agColumnDefs,
                rowData: scope.organizations,
                selectedItem: null,
                rowSelection: 'single',
                onSelectionChanged: function (params) {
                    scope.orgGrid.selectedItem = scope.orgGrid.api.getSelectedRows()[0];
                    scope.selectOrganization();
                    scope.$apply();
                },
                onRowDoubleClicked: function (params) {
                    scope.openOrgModal(scope.orgGrid.selectedItem);
                },
                onBodyScroll: function (event) {
                    scope.changeFilterIcons(event, "org");
                },
                onFilterModified: function (event) {
                    scope.changeFilterIcons(event, "org");
                },
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    icons: {
                        menu: "<span class='material-symbols-outlined' id='filter-icon' title='Filter'>filter_alt</span>", filter: "<span></span>",
                    }
                },
            };

            var ag_grid_div = document.querySelector('#org-grid') as HTMLElement;    //get the container id...
            scope.ag_grid = new Grid(ag_grid_div, scope.orgGrid); //bind the grid to it.

            scope.activateGrids();

        });

        scope.activateGrids = function () {

            //groups
            var groupColumnDefs = [
                { field: 'Name', headerName: 'Name', width: 280, menuTabs: ['filterMenuTab'], filter: true, sort: 'asc' },
                { field: 'Description', headerName: 'Description', width: 400, menuTabs: ['filterMenuTab'], filter: 'text' },
                {
                    headerName: "Members", width: 120, cellRenderer: function (params) {
                        return params.data.Members.length;
                    }
                }
            ];

            scope.groupGrid = {
                suppressPropertyNamesCheck: true,
                columnDefs: groupColumnDefs,
                rowData: null,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    icons: {
                        menu: "<span class='material-symbols-outlined' id='filter-icon' title='Filter'>filter_alt</span>", filter: "<span></span>",
                    }
                },
                selectedItem: null,
                rowSelection: 'single',
                onSelectionChanged: function (params) {
                    scope.groupGrid.selectedItem = scope.groupGrid.api.getSelectedRows()[0];
                    scope.memberGrid.api.setRowData(scope.groupGrid.selectedItem.Members);
                    scope.$apply();
                },
                onRowDoubleClicked: function (params) {
                    scope.openGroupModal(scope.groupGrid.selectedItem);
                },
                onBodyScroll: function (event) {
                    scope.changeFilterIcons(event, "group");
                },
                onFilterModified: function (event) {
                    scope.changeFilterIcons(event, "group");
                },
            };

            var group_grid_div = document.querySelector('#group-grid') as HTMLElement;    //get the container id...
            scope.group_grid = new Grid(group_grid_div, scope.groupGrid); //bind the grid to it.

            //departments
            var departmentColumnDefs = [
                { field: 'Name', headerName: 'Name', width: 280, menuTabs: ['filterMenuTab'], filter: true, sort: 'asc' },
                { field: 'Description', headerName: 'Description', width: 400, menuTabs: ['filterMenuTab'], filter: 'text' },
            ];

            scope.departmentGrid = {
                suppressPropertyNamesCheck: true,
                columnDefs: departmentColumnDefs,
                rowData: null,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    icons: {
                        menu: "<span class='material-symbols-outlined' id='filter-icon' title='Filter'>filter_alt</span>", filter: "<span></span>",
                    }
                },
                selectedItem: null,
                rowSelection: 'single',
                onSelectionChanged: function (params) {
                    scope.departmentGrid.selectedItem = scope.departmentGrid.api.getSelectedRows()[0];
                    scope.$apply();
                },
                onRowDoubleClicked: function (params) {
                    scope.openDepartmentModal(scope.departmentGrid.selectedItem);
                },
                onBodyScroll: function (event) {
                    scope.changeFilterIcons(event, "department");
                },
                onFilterModified: function (event) {
                    scope.changeFilterIcons(event, "department");
                },
            };

            var department_grid_div = document.querySelector('#department-grid') as HTMLElement;    //get the container id...
            scope.department_grid = new Grid(department_grid_div, scope.departmentGrid); //bind the grid to it.

            //users
            var userColumnDefs = [
                { field: 'Fullname', headerName: 'Name', width: 280, menuTabs: ['filterMenuTab'], filter: true, sort: 'asc' },
                { field: 'Description', headerName: 'Description', width: 400, menuTabs: ['filterMenuTab'], filter: 'text' },
                { field: 'Department.Name', headerName: 'Department', width: 150, menuTabs: ['filterMenuTab'], filter: true },
                {
                    headerName: "Groups", width: 200, filter: true, menuTabs: ['filterMenuTab'],
                    valueGetter: function (params) {
                        var list = [];
                        if (!params.data.Groups)
                            return "";
                        params.data.Groups.forEach(function (item) {
                            list.push(item.Name);
                        })
                        return list.join(',');
                    }
                },
                { field: 'Email', headerName: 'Notification Email', width: 200, menuTabs: ['filterMenuTab'], filter: 'text' },
                { field: 'Cell', headerName: 'Notification Cell', width: 200, menuTabs: ['filterMenuTab'], filter: 'text' },
                {
                    headerName: 'Status', width: 150, menuTabs: ['filterMenuTab'], filter: true,
                    valueGetter: function (params) {
                        return (params.data.Inactive) ? "Inactive" : "Active"; //1=inactive
                    }
                },
                {
                    field: 'LastLogin', headerName: 'Last login', width: 200, menuTabs: ['filterMenuTab'], filter: 'text',
                    valueGetter: function (params) {
                        return moment(params.data.LastLogin).format("MM/DD/YYYY HH:MM");
                    }
                },
                { field: 'Id', headerName: 'Id', width: 80, menuTabs: ['filterMenuTab'], }
            ];

            scope.userGrid = {
                suppressPropertyNamesCheck: true,
                columnDefs: userColumnDefs,
                rowData: null,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    icons: {
                        menu: "<span class='material-symbols-outlined' id='filter-icon' title='Filter'>filter_alt</span>", filter: "<span></span>",
                    }
                },
                selectedItem: null,
                rowSelection: 'single',
                onSelectionChanged: function (params) {
                    scope.userGrid.selectedItem = scope.userGrid.api.getSelectedRows()[0];
                    scope.$apply();
                },
                onRowDoubleClicked: function (params) {
                    scope.openUserModal(scope.userGrid.selectedItem);
                },
                onBodyScroll: function (event) {
                    scope.changeFilterIcons(event, "user");
                },
                onFilterModified: function (event) {
                    scope.changeFilterIcons(event, "user");
                },
            };

            var user_grid_div = document.querySelector('#user-grid') as HTMLElement;    //get the container id...
            scope.user_grid = new Grid(user_grid_div, scope.userGrid); //bind the grid to it.

            //admins
            var adminColumnDefs = [
                { field: 'Fullname', headerName: 'Name', width: 280, menuTabs: ['filterMenuTab'], filter: true, sort: 'asc' },
                { field: 'Description', headerName: 'Description', width: 400, menuTabs: ['filterMenuTab'], filter: 'text' },
                {
                    headerName: 'Status', width: 150, menuTabs: ['filterMenuTab'], filter: true,
                    valueGetter: function (params) {
                        return (params.data.Inactive) ? "Inactive" : "Active"; //1=inactive
                    }
                },
                {
                    field: 'LastLogin', headerName: 'Last login', width: 200, menuTabs: ['filterMenuTab'], filter: 'text',
                    valueGetter: function (params) {
                        return moment(params.data.LastLogin).format("MM/DD/YYYY HH:MM");
                    }
                },
            ];

            scope.adminGrid = {
                suppressPropertyNamesCheck: true,
                columnDefs: adminColumnDefs,
                rowData: null,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    icons: {
                        menu: "<span class='material-symbols-outlined' id='filter-icon' title='Filter'>filter_alt</span>", filter: "<span></span>",
                    }
                },
                selectedItem: null,
                rowSelection: 'single',
                onSelectionChanged: function (params) {
                    scope.adminGrid.selectedItem = scope.adminGrid.api.getSelectedRows()[0];
                    scope.$apply();
                },
                onRowDoubleClicked: function(params) {
                    // Turned the next line off for now.  Users should not be able to edit a user
                    // from the Administrators grid.  A user should only be able to add or remove
                    // a user as an Administrator.
                    //scope.openAdminModal(scope.adminGrid.selectedItem);
                },
                onBodyScroll: function (event) {
                    scope.changeFilterIcons(event, "admin");
                },
                onFilterModified: function (event) {
                    scope.changeFilterIcons(event, "admin");
                },
            };

            var admin_grid_div = document.querySelector('#admin-grid') as HTMLElement;    //get the container id...
            scope.admin_grid = new Grid(admin_grid_div, scope.adminGrid); //bind the grid to it.


            //members

            scope.memberGrid = {
                suppressPropertyNamesCheck: true,
                columnDefs: userColumnDefs,
                rowData: null,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    icons: {
                        menu: "<span class='material-symbols-outlined' id='filter-icon' title='Filter'>filter_alt</span>", filter: "<span></span>",
                    }
                },
                selectedItem: null,
                rowSelection: 'single',
                onSelectionChanged: function (params) {
                    scope.memberGrid.selectedItem = scope.memberGrid.api.getSelectedRows()[0];
                    scope.$apply();
                },
                onRowDoubleClicked: function (params) {
                    scope.openMemberModal(scope.memberGrid.selectedItem);
                },
                onBodyScroll: function (event) {
                    scope.changeFilterIcons(event, "member");
                },
                onFilterModified: function (event) {
                    scope.changeFilterIcons(event, "member");
                },
            };

            var member_grid_div = document.querySelector('#member-grid') as HTMLElement;    //get the container id...
            scope.member_grid = new Grid(member_grid_div, scope.memberGrid); //bind the grid to it.

        }

        scope.changeFilterIcons = function (event, grid) {
            // We need to call this onFilterModified and onBodyScroll, as the elements are removed/added
            // to the DOM when scrolling horizontally on wide datasets.
            // Can also pass $scope.dataAgGridOptions to this function rather than an event.
            let filters;
            if (grid == "org") {
                filters = scope.orgGrid.api.getFilterModel();
            }
            else if (grid == "group") {
                filters = scope.groupGrid.api.getFilterModel();
            }
            else if (grid == "department") {
                filters = scope.departmentGrid.api.getFilterModel();
            }
            else if (grid == "user") {
                filters = scope.userGrid.api.getFilterModel();
            }
            else if (grid == "admin") {
                filters = scope.adminGrid.api.getFilterModel();
            }
            else {
                filters = scope.memberGrid.api.getFilterModel();
            }


            for (let col of event.api.columnController.columnDefs) {
                for (const span of document.querySelectorAll("span")) {
                    if (span.textContent.includes(col.field) || span.textContent.includes(col.headerName)) {
                        // Naming conventions vary, so lets check all of these to see if the column has a filter
                        if (col.Label in filters || col.headerName in filters || col.field in filters) {
                            // If column has a filter change the icon color
                            // @ts-ignore
                            let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];
                            icon.style.color = "#007bff";
                        }
                        else {
                            // Remove the color prop from the icon if there is no filter
                            // @ts-ignore
                            let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];// filterIcon.children[0];
                            if (icon && icon.style) {
                                icon.style.color = "#bdbdbd";
                            }
                        }
                    }
                }
            }
        };



        scope.selectOrganization = function () {

            scope.departmentGrid.selectedItem = scope.groupGrid.selectedItem = scope.userGrid.selectedItem = scope.adminGrid.selectedItem = scope.memberGrid.selectedItem = null;
            scope.departmentGrid.api.showLoadingOverlay(); //setRowData(null);
            scope.groupGrid.api.showLoadingOverlay(); //setRowData(null);
            scope.userGrid.api.showLoadingOverlay(); //setRowData(null);
            scope.adminGrid.api.showLoadingOverlay(); //setRowData(null);

            scope.departments = CommonService.getOrgDepartments(scope.orgGrid.selectedItem.Id);
            scope.departments.$promise.then(function () {
                scope.departmentGrid.api.setRowData(scope.departments);
            })

            scope.groups = CommonService.getOrgGroups(scope.orgGrid.selectedItem.Id);
            scope.groups.$promise.then(function () {
                scope.groupGrid.api.setRowData(scope.groups);
            })

            scope.users = CommonService.getOrgUsers(scope.orgGrid.selectedItem.Id);
            scope.users.$promise.then(function () {
                scope.userGrid.api.setRowData(scope.users);
            })

            scope.admins = CommonService.getOrgAdministrators(scope.orgGrid.selectedItem.Id);
            scope.admins.$promise.then(function () {
                scope.adminGrid.api.setRowData(scope.admins);
            })

        }

        scope.openOrgModal = function (in_item) {
            scope.SaveMessage = null;

            scope.item = (in_item) ? in_item : {};
            scope.itemType = "Organization"

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/modal-admin-item.html',
                controller: 'ModalAdminItemCtrl',
                scope: scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_item) {
                if (scope.item.hasOwnProperty('Id')) //edited
                {
                    scope.organizations.forEach(function (item, index) {
                        if (item.Id == saved_item.Id) {
                            angular.extend(item, saved_item);
                            scope.orgGrid.api.setRowData(scope.organizations);
                        }
                    });
                } else { //created
                    scope.organizations.push(saved_item);
                    scope.orgGrid.api.setRowData(scope.organizations);
                }

                scope.SaveMessage = "Success.";
            }, function (error) {
                console.dir(error);
                scope.ErrorMessage = "Failed.";
            });
        }

        scope.deleteOrganization = function () {
            if (!scope.Profile.IsSuperAdmin) {
                console.log("Permission denied.")
                return;
            }

            if (scope.departments.length > 0 || scope.groups.length > 0 || scope.users.length > 0 || scope.admins.length > 0) {
                alert("You can only delete empty organizations (first remove all departments, groups, users, and admins)");
            } else {
                if (confirm("Are you sure you want to delete this organization? It cannot be undone.")) {
                    var deleted = AdminService.removeOrganization(scope.orgGrid.selectedItem.Id);
                    deleted.$promise.then(function () {
                        scope.organizations.forEach(function (dept, index) {
                            if (dept.Id == scope.orgGrid.selectedItem.Id) {
                                scope.organizations.splice(index, 1);
                            }
                        })
                        scope.orgGrid.api.setRowData(scope.organizations);
                        scope.orgGrid.selectedItem = null;
                    }, function (error) {
                        console.dir(error);
                        alert(error.data.InnerException.ExceptionMessage);
                    })
                }
            }
        }

        scope.deleteDepartment = function () {
            if (!scope.Profile.IsSuperAdmin && (!scope.Profile.IsOrganizationAdmin || scope.Profile.OrganizationId != scope.orgGrid.selectedItem.Id)) {
                console.log("Permission denied.")
                return;
            }


            if (confirm("You can only delete departments with no users assigned. Are you sure you want to delete this department? It cannot be undone.")) {
                var deleted = AdminService.removeDepartment(scope.departmentGrid.selectedItem.Id);
                deleted.$promise.then(function () {
                    scope.departments.forEach(function (dept, index) {
                        if (dept.Id == scope.departmentGrid.selectedItem.Id) {
                            scope.departments.splice(index, 1);
                        }
                    })
                    scope.departmentGrid.api.setRowData(scope.departments);
                    scope.departmentGrid.selectedItem = null;
                }, function (error) {
                    console.dir(error);
                    alert(error.data.InnerException.ExceptionMessage);
                })
            }
        }

        scope.deleteGroup = function () {
            if (!scope.Profile.IsSuperAdmin && (!scope.Profile.IsOrganizationAdmin || scope.Profile.OrganizationId != scope.orgGrid.selectedItem.Id)) {
                console.log("Permission denied.")
                return;
            }

            if (scope.groupGrid.selectedItem.Members.length > 0) {
                alert("You can only delete empty groups (first remove all members).");
            } else {
                if (confirm("Are you sure you want to delete this group? It cannot be undone.")) {
                    var deleted = AdminService.removeGroup(scope.groupGrid.selectedItem.Id);
                    deleted.$promise.then(function () {
                        scope.groups.forEach(function (group, index) {
                            if (group.Id == scope.groupGrid.selectedItem.Id) {
                                scope.groups.splice(index, 1);
                            }
                        })
                        scope.groupGrid.api.setRowData(scope.groups);
                        scope.groupGrid.selectedItem = null;
                    }, function (error) {
                        console.dir(error);
                        alert(error.data.InnerException.ExceptionMessage);
                    })
                }
            }
        }

        scope.deleteUser = function () {
            if (!scope.Profile.IsSuperAdmin && (!scope.Profile.IsOrganizationAdmin || scope.Profile.OrganizationId != scope.orgGrid.selectedItem.Id)) {
                console.log("Permission denied.")
                return;
            }

            if (confirm("You can only delete users with no activities. Are you sure you want to delete this user? It cannot be undone.")) {
                console.log("delete user");
                var deleted = AdminService.removeUser(scope.userGrid.selectedItem.Id);
                deleted.$promise.then(function () {
                    scope.users.forEach(function (user, index) {
                        if (user.Id == scope.userGrid.selectedItem.Id) {
                            scope.users.splice(index, 1);
                        }
                    })
                    scope.userGrid.api.setRowData(scope.users);
                    scope.userGrid.selectedItem = null;
                }, function (error) {
                    console.dir(error);
                    alert(error.data.InnerException.ExceptionMessage);
                })

            }
        }



        scope.openDepartmentModal = function (in_item) {
            scope.SaveMessage = null;

            scope.item = (in_item) ? in_item : {};
            scope.itemType = "Department"

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/modal-admin-item.html',
                controller: 'ModalAdminItemCtrl',
                scope: scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_item) {
                if (scope.item.hasOwnProperty('Id')) //edited
                {
                    scope.departments.forEach(function (item, index) {
                        if (item.Id == saved_item.Id) {
                            angular.extend(item, saved_item);
                            scope.departmentGrid.api.setRowData(scope.departments);
                        }
                    });
                } else { //created
                    scope.departments.push(saved_item);
                    scope.departmentGrid.api.setRowData(scope.departments);
                }

                scope.SaveMessage = "Success.";
            }, function (error) {
                console.dir(error);
                scope.ErrorMessage = "Failed.";
            });
        }

        scope.openGroupModal = function (in_item) {
            scope.SaveMessage = null;

            scope.item = (in_item) ? in_item : {};
            scope.itemType = "Group"

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/modal-admin-item.html',
                controller: 'ModalAdminItemCtrl',
                scope: scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_item) {
                if (scope.item.hasOwnProperty('Id')) //edited
                {
                    scope.groups.forEach(function (item, index) {
                        if (item.Id == saved_item.Id) {
                            angular.extend(item, saved_item);
                            scope.groupGrid.api.setRowData(scope.groups);
                        }
                    });
                } else { //created
                    scope.groups.push(saved_item);
                    scope.groupGrid.api.setRowData(scope.groups);
                }

                scope.SaveMessage = "Success.";
            }, function (error) {
                console.dir(error);
                scope.ErrorMessage = "Failed.";
            });
        }

        scope.openUserModal = function (user) {

            if (user)
                scope.user = user;
            else
                scope.user = { Inactive: 0, OrganizationId: scope.orgGrid.selectedItem.Id };

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/modal-manage-user.html',
                controller: 'ModalManageUserCtrl',
                scope: scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_item) {
                if (scope.user.hasOwnProperty('Id')) //edited
                {
                    scope.users.forEach(function (item, index) {
                        if (item.Id == saved_item.Id) {
                            angular.extend(item, saved_item);
                            scope.userGrid.api.setRowData(scope.users);
                        }
                    });
                } else { //created
                    scope.users.push(saved_item);

                    if (saved_item.DepartmentId) { //need to set the dept object
                        scope.departments.forEach(function (dept) {
                            if (dept.Id == saved_item.DepartmentId) {
                                saved_item.Department = dept;
                            }
                        })
                    }

                    scope.users = scope.users.sort(orderUserByAlpha)
                    scope.userGrid.api.setRowData(scope.users);
                }

            });
        };

        scope.addMember = function () {

            if (scope.alreadyMember(scope.groupGrid.selectedItem, scope.selectedUserId))
                return;

            var saved_member = AdminService.saveGroupMember(scope.groupGrid.selectedItem.Id, scope.selectedUserId);
            saved_member.$promise.then(function () {
                scope.groupGrid.selectedItem.Members.push(saved_member);
                scope.groupGrid.api.setRowData(scope.groups);
                scope.memberGrid.api.setRowData(scope.groupGrid.selectedItem.Members);
                scope.setSuccessMessage("Success: Added Member")
            }, function (error) {
                scope.setErrorMessage("Error: " + error.data.ExceptionMessage);
            });

        };

        scope.addAdministrator = function () {
            if (scope.alreadyAdmin(scope.selectedUserId))
                return;

            var saved_admin = AdminService.saveOrgAdmin(scope.orgGrid.selectedItem.Id, scope.selectedUserId);
            saved_admin.$promise.then(function () {
                scope.admins.push(saved_admin);
                scope.adminGrid.api.setRowData(scope.admins);
                scope.setSuccessMessage("Success: Added Admin")
            }, function (error) {
                scope.setErrorMessage("Error: " + error.data.ExceptionMessage);
            });
        };

        scope.removeAdministrator = function () {
            var removed_admin = AdminService.removeOrgAdmin(scope.orgGrid.selectedItem.Id, scope.adminGrid.selectedItem.Id);
            removed_admin.$promise.then(function () {
                scope.admins.forEach(function (admin, index) {
                    if (admin.Id == scope.adminGrid.selectedItem.Id) {
                        scope.admins.splice(index, 1);
                    }
                });
                scope.adminGrid.api.setRowData(scope.admins);
                scope.setSuccessMessage("Success: Removed Admin")
            }, function (error) {
                scope.setErrorMessage("Error: " + error.data.ExceptionMessage);
            });
        }

        scope.removeMember = function () {
            var removed_user = AdminService.removeGroupMember(scope.groupGrid.selectedItem.Id, scope.memberGrid.selectedItem.Id);
            removed_user.$promise.then(function () {
                scope.groupGrid.selectedItem.Members.forEach(function (member, index) {
                    if (member.Id == scope.memberGrid.selectedItem.Id) {
                        scope.groupGrid.selectedItem.Members.splice(index, 1);
                    }
                });
                scope.memberGrid.api.setRowData(scope.groupGrid.selectedItem.Members);
                scope.setSuccessMessage("Success: Removed Member")
                scope.memberGrid.selectedItem = null;
            }, function (error) {
                scope.setErrorMessage("Error: " + error.data.ExceptionMessage);
            });
        }


        scope.setErrorMessage = function (message) {
            scope.SuccessMessage = null;
            scope.ErrorMessage = message;
        };

        scope.setSuccessMessage = function (message) {
            scope.SuccessMessage = message;
            scope.ErrorMessage = null;
        }

        scope.alreadyMember = function (group, userId) {
            var isMember = false;
            group.Members.forEach(function (user) {
                if (user.Id == userId) {
                    isMember = true;
                };
            });
            return isMember;
        }

        scope.alreadyAdmin = function (userId) {
            var isMember = false;
            scope.admins.forEach(function (user) {
                if (user.Id == userId) {
                    isMember = true;
                };
            });
            return isMember;
        }

        scope.activeUser = function (item) {
            return item.Inactive != 1;
        }

    }

];

export default admin_org;
