import * as angular from 'angular';
import render from 'zingchart';
import {getFilesArrayAsList, makeObjects} from "../../../common/common-functions";

import {Grid} from "ag-grid-community";
import 'ag-grid-enterprise';
import row from "ag-grid-enterprise/dist/lib/exporter/files/xml/row";

var table_editor = ['$scope', '$routeParams', 'GridService', 'ProjectService', 'DatasetService', 'CommonService', 'UserService',
  '$rootScope', '$uibModal', '$sce', '$window',
  function (scope, routeParams, GridService, ProjectService, DatasetService, CommonService, UserService, $rootScope, $uibModal, $sce, $window) {

    console.log("Inside table.ts...");

    scope.col_left = "col-12";
    scope.col_right = undefined;

    scope.project = null;

    scope.dataset = DatasetService.getDataset(routeParams.Id);

    scope.saveResults = {};

    scope.holdChildtabledata = null;

    scope.resetSaveResults = function () {
      scope.saveResults = {
        parent: {success: null, failure: null},
        child: {success: null, failure: null},
        grandchild: {success: null, failure: null},
      };
    }

    scope.resetSaveResults();

    scope.dataset.$promise.then(function () {

      scope.project = ProjectService.getProject(scope.dataset.ProjectId);

      scope.loadTable(routeParams.Id);

      scope.TableDatasetColumnDefs = GridService.getAgColumnDefs(scope.dataset);

      scope.tableGrid.columnDefs = angular.copy(scope.TableDatasetColumnDefs.HeaderFields);

      //set sort direction of first column
      var sortFirstCol = "asc";

      if (scope.tableGrid.columnDefs[0].cdmsField.Field.DataType == 'date' ||
        scope.tableGrid.columnDefs[0].cdmsField.Field.DataType == 'datetime')
        sortFirstCol = "desc";

      scope.tableGrid.columnDefs[0].sort = sortFirstCol;

      //see if we have any file fields
      scope.noteFileFields(scope.dataset, scope.TableDatasetColumnDefs.HeaderFields);

      //setup the grid!
      scope.tableGridDiv = document.querySelector('#table-grid');
      new Grid(scope.tableGridDiv, scope.tableGrid);
      scope.mpv_style = "mpv-tall ag-theme-balham gridStyle-small";

      scope.project.$promise.then(function () {
        //to populate the locations dropdown, if it exists.
        scope.project.Locations = ProjectService.getDatasetLocations(scope.project.Id, scope.dataset.Id);

        //set the possible values in the LocationId field so that our cell value will be properly populated
        scope.project.Locations.$promise.then(function () {
          angular.forEach(scope.tableGrid.columnDefs, function (fieldDef) {
            if (fieldDef.field == "LocationId") {
              fieldDef.setPossibleValues(makeObjects(scope.project.Locations, 'Id', 'Label'));

              //reset the grid to pickup the new possible values
              scope.tableGrid.api.setRowData(scope.tabledata);

              //reexpand the columns

              let allColumnIds = [];
              scope.tableGrid.columnApi.getAllColumns().forEach(function (columnDef) {

                allColumnIds.push(columnDef.colId);
              });

              scope.tableGrid.columnApi.autoSizeColumns(allColumnIds);
            }
          });
        })
      });

    });

    scope.sortTableGrid = function () {
      if ((typeof sortFirstCol === 'undefined') || (sortFirstCol == null)) {
        //set sort direction of first column
        var sortFirstCol = "asc";

        if (scope.tableGrid.columnDefs[0].cdmsField.Field.DataType == 'date' ||
          scope.tableGrid.columnDefs[0].cdmsField.Field.DataType == 'datetime')
          sortFirstCol = "desc";

        scope.tableGrid.columnDefs[0].sort = sortFirstCol;

      }
    }

    scope.tableGrid = {
      columnDefs: [],
      rowData: null,
      rowSelection: 'single',
      onSelectionChanged: function (params) {
        scope.resetSaveResults();

        scope.tableGrid.selectedItem = scope.tableGrid.api.getSelectedRows()[0];
        if (scope.tableGrid.selectedItem.ProjectName)
          scope.tableGrid.selectedItem.Name = scope.tableGrid.selectedItem.ProjectName;

        scope.selectParent(scope.tableGrid.selectedItem);
        scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
      },
      onRowDoubleClicked: function (params) {
        scope.openDataModal("parent", params.data);
      },
      selectedItem: null,
      defaultColDef: {
        editable: false,
        sortable: true,
        resizable: true,
      },
      getRowHeight: function (params) {
        return scope.calculateRowHeight(scope.dataset, params);
      },
      onFirstDataRendered: function (params) {

        let allColumnIds = [];
        scope.tableGrid.columnApi.getAllColumns().forEach(function (columnDef) {

          allColumnIds.push(columnDef.colId);
        });

        scope.tableGrid.columnApi.autoSizeColumns(allColumnIds);
      },
      onFilterChanged: function () {
        scope.tableGrid.selectedItem = null;
        scope.childtabledata = [];
        scope.childTableGrid.api.setRowData(scope.childtabledata);
        scope.$apply();
      }
    }

    scope.selectParent = function (parent) {

      if (!scope.dataset.ChildDatasetId)
        return;

      scope.zingChart('parentChart', scope.dataset.Datastore.TablePrefix, scope.tableGrid);

      if (!scope.childDataset) {

        scope.childTableGrid = {
          columnDefs: [],
          rowData: null,
          rowSelection: 'single',
          onSelectionChanged: function (params) {
            scope.childTableGrid.selectedItem = scope.childTableGrid.api.getSelectedRows()[0];
            scope.selectChild(scope.childTableGrid.selectedItem);

            if (scope.grandChildTableGrid)
              scope.grandChildTableGrid.selectedItem = null;

            scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
          },
          onRowDoubleClicked: function (params) {
            scope.openDataModal("child", params.data);
          },
          selectedItem: null,
          defaultColDef: {
            editable: false,
            sortable: true,
            resizable: true,
          },
          onFirstDataRendered: function (params) {

            let allColumnIds = [];
            scope.childTableGrid.columnApi.getAllColumns().forEach(function (columnDef) {

              allColumnIds.push(columnDef.colId);
            });

            scope.childTableGrid.columnApi.autoSizeColumns(allColumnIds);
          },
          getRowHeight: function (params) {
            return scope.calculateRowHeight(scope.childDataset, params);
          }
        }

        scope.childDataset = DatasetService.getDataset(scope.dataset.ChildDatasetId);
        scope.childDataset.$promise.then(function () {

          scope.childtabledata = DatasetService.getChildTableData(scope.dataset.ChildDatasetId, parent.Id);

          scope.childtabledata.$promise.then(function () {
            scope.childTableGrid.api.setRowData(scope.childtabledata);
            scope.zingChart('childChart', scope.childDataset.Datastore.TablePrefix, scope.childTableGrid);
          });

          scope.ChildTableDatasetColumnDefs = GridService.getAgColumnDefs(scope.childDataset);

          scope.childTableGrid.columnDefs = angular.copy(scope.ChildTableDatasetColumnDefs.HeaderFields);

          var sortFirstCol = "asc";

          if (scope.childTableGrid.columnDefs[0].cdmsField.Field.DataType == 'date' ||
            scope.childTableGrid.columnDefs[0].cdmsField.Field.DataType == 'datetime')
            sortFirstCol = "desc";

          scope.noteFileFields(scope.childDataset, scope.ChildTableDatasetColumnDefs.HeaderFields);

          scope.childTableGridDiv = document.querySelector('#child-table-grid');
          new Grid(scope.childTableGridDiv, scope.childTableGrid);
          scope.mpv_style = "mpv-short ag-theme-balham gridStyle-small";

        });
      } else {
        scope.childTableGrid.selectedItem = null;
        scope.childTableGrid.api.showLoadingOverlay();
        scope.childtabledata = DatasetService.getChildTableData(scope.dataset.ChildDatasetId, parent.Id);
        scope.childtabledata.$promise.then(function () {
          // Dump the contents of scope.holdChildtabledata before refilling it.
          //scope.holdChildtabledata = null;
          //scope.holdChildtabledata = angular.copy(scope.childtabledata);

          //scope.childtabledata.forEach(function (theRowData){
          //    if ((theRowData.ResourceLink) && (theRowData.ResourceLink !== null))
          //    {
          //var tmpResourceLink = [];
          //var tmpFromJson = JSON.parse(theRowData.ResourceLink);
          //if (Array.isArray(tmpFromJson))
          //{
          //    var tmpTheLink = "";
          //    tmpFromJson.forEach(function (theResourceLinkItem){
          //        tmpTheLink = "<a href=\"" + theResourceLinkItem.Link + "\">" + theResourceLinkItem.Link + "</a>";
          //        tmpResourceLink.push(tmpTheLink);
          //    });
          //    theRowData.ResourceLink = tmpResourceLink;
          //}
          //else
          //{
          //    theRowData.ResourceLink = tmpFromJson.Link;
          //}
          //theRowData.ResourceLink = tmpFromJson;
          //    }
          //});

          scope.childTableGrid.api.setRowData(scope.childtabledata);
          scope.zingChart('childChart', scope.childDataset.Datastore.TablePrefix, scope.childTableGrid);
        });

      }

    }

    scope.selectChild = function (child) {

      scope.resetSaveResults();

      if (!scope.childDataset.ChildDatasetId)
        return;

      if (!scope.grandChildDataset) {

        scope.grandChildTableGrid = {
          columnDefs: [],
          rowData: null,
          rowSelection: 'single',
          onSelectionChanged: function (params) {
            scope.grandChildTableGrid.selectedItem = scope.grandChildTableGrid.api.getSelectedRows()[0];
            scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
          },
          onRowDoubleClicked: function (params) {
            scope.openDataModal("grandchild", params.data);
          },
          selectedItem: null,
          defaultColDef: {
            editable: false,
            sortable: true,
            resizable: true,
          },
        }

        scope.grandChildDataset = DatasetService.getDataset(scope.childDataset.ChildDatasetId);
        scope.grandChildDataset.$promise.then(function () {
          scope.grandchildtabledata = DatasetService.getChildTableData(scope.childDataset.ChildDatasetId, child.Id);

          scope.grandchildtabledata.$promise.then(function () {
            scope.grandChildTableGrid.api.setRowData(scope.grandchildtabledata);
          });

          scope.GrandChildTableDatasetColumnDefs = GridService.getAgColumnDefs(scope.grandChildDataset);

          scope.grandChildTableGrid.columnDefs = angular.copy(scope.GrandChildTableDatasetColumnDefs.HeaderFields);

          scope.noteFileFields(scope.grandChildDataset, scope.GrandChildTableDatasetColumnDefs.HeaderFields);

          scope.grandChildTableGridDiv = document.querySelector('#grand-child-table-grid');
          new Grid(scope.grandChildTableGridDiv, scope.grandChildTableGrid);

        });
      } else {
        scope.grandChildTableGrid.selectedItem = null;
        scope.grandChildTableGrid.api.showLoadingOverlay();
        scope.grandchildtabledata = DatasetService.getChildTableData(scope.childDataset.ChildDatasetId, child.Id);
        scope.grandchildtabledata.$promise.then(function () {
          scope.grandChildTableGrid.api.setRowData(scope.grandchildtabledata);
        });

      }

    }

    scope.delete = function (type) {
      var blnContinue = false;

      scope.determineContext(type);
      //if ((type === "parent") && (scope.childtabledata.length > 0))
      if ((type === "parent") && (scope.childtabledata) && (scope.childtabledata.length > 0))
        alert("This item has an associated sub-item.  All sub-items must be removed before this item can be deleted.");
      else
        blnContinue = true;


      if (blnContinue) {
        if (scope.tableGrid.selectedItem && confirm("Are you sure you want to delete the selected row? This is irreversible.")) {
          //var datasetId = scope.context_dataset.Id; // Created but not used...
          //var projectId = scope.context_dataset.ProjectId; // Created but not used...

          //if (type === "parent")
          var deleteFile = DatasetService.deleteMultiPanelFile(type, scope.context_dataset.ProjectId, scope.context_dataset.Id, scope.context_grid.selectedItem);

          var deleted = DatasetService.deleteDataTableRow(scope.context_dataset, scope.context_grid.selectedItem);
        }

        deleted.$promise.then(function () {
          scope.context_table.forEach(function (data, index) {
            if (scope.context_grid.selectedItem.Id == data.Id)
              scope.context_table.splice(index, 1);
          });
          scope.context_grid.api.setRowData(scope.context_table);
          scope.context_grid.selectedItem = null;
        }, function (failure) {
          console.dir(failure)
          scope.saveResults[type]['failure'] = "Failed: " + failure.data.ExceptionMessage;
        });
      }
    }

    //type can be "parent","child","grandchild"
    // sets the context_grid, context_dataset, context_table, parents
    scope.determineContext = function (type) {
      if (type == "parent") {
        scope.context_dataset = scope.dataset;
        scope.context_grid = scope.tableGrid;
        scope.context_table = scope.tabledata;
      } else if (type == "child") {
        scope.context_dataset = scope.childDataset;
        scope.context_grid = scope.childTableGrid;
        scope.context_table = scope.childtabledata;
        scope.context_parentgrid = scope.tableGrid;
        scope.context_parentdataset = scope.dataset;
      } else if (type == "grandchild") {
        scope.context_dataset = scope.grandChildDataset;
        scope.context_grid = scope.grandChildTableGrid;
        scope.context_table = scope.grandchildtabledata;
        scope.context_parentgrid = scope.childTableGrid;
        scope.context_parentdataset = scope.childDataset;
      }
    }

    scope.openDataModal = function (type, params) {

      if (!$rootScope.Profile.canEdit(scope.project)) {
        console.log("not authorized to edit");
        return;
      }

      // We got to this record via a click/resetting scope.resetSaveResults(),
      // so we don't need to run it again.
      // But, if someone saves, and then opens the form right away, they we must
      // reset.
      scope.resetSaveResults();

      // We adjusted the child data, to show links properly, so set them back,
      // before opening the modal.
      if (scope.holdChildtabledata !== null) {
        scope.childtabledata = null;
        scope.childtabledata = angular.copy(scope.holdChildtabledata);
      }

      scope.saveResults[type]['failure'] = scope.saveResults[type]['success'] = null; //reset

      //sets the context_grid, context_dataset, context_table used by the modal
      scope.determineContext(type);
      //console.log("determined context for " + type);

      scope.data_type = type;
      scope.data_modal = (params) ? angular.copy(params) : GridService.getNewRow(scope.context_grid.columnDefs);

      scope.data_parentId = 0

      if (type == 'parent') {
        // Do nothing.  Parent is the top-level and doesn't have it own parent.
      } else if (type == 'child') {
        scope.data_parentId = scope.tableGrid.selectedItem.Id;
      } else if (type == 'grandchild') {
        scope.data_parentId = scope.tableGrid.selectedItem.Id;
      }

      //setup the fk relationships
      if (!params && scope.context_dataset.ChildDatasetKeyColumn != null) {
        //if (scope.context_grid.rowData !== null){ //don't know why this is testing for this, but it interferes with setting the fk...
        scope.data_modal[scope.context_dataset.ChildDatasetKeyColumn] = scope.context_parentgrid.selectedItem.Id; //link the parent FK (if exists)
        if (scope.context_parentdataset.ChildDatasetKeyColumn != null) {
          scope.data_modal[scope.context_parentdataset.ChildDatasetKeyColumn] = scope.tableGrid.selectedItem.Id; //link the grandparent FK (if exists)
        }
        //}
      }


      //console.log (' -- -- -- opening')
      //console.log("data_parentId: " + scope.data_parentId)
      //console.dir(scope.context_parentgrid)
      //console.dir(scope.tableGrid)
      //console.dir(scope.data_modal)


      var modalInstance = $uibModal.open({
        templateUrl: 'appjsLegacy/core/datasets/components/dataset-table/templates/edit-data-modal.html',
        controller: 'TableDataModal',
        scope: scope,
        backdrop: "static",
        keyboard: false,
        windowClass: 'modal-medium'
      }).result.then(function (saved) {
        //console.log(" -- done saving -- and back -- ");
        saved = saved[0]; //get the first row

        //console.log("datamodal")
        //console.dir(scope.data_modal);

        if (!scope.data_modal.hasOwnProperty('Id') || !scope.data_modal.Id) {
          //console.log("pushing to context_table ----------");
          //console.dir(scope.context_table);
          scope.context_table.push(saved);
        } else {
          //console.log("no id so looking for it in");
          //console.dir(scope.context_table);

          scope.context_table.forEach((data, index) => {
            if (data.Id == saved.Id) {
              data = angular.extend(data, saved);
              // let rowNode = scope.context_grid.api.getRowNodeId(data.Id);
              // rowNode.setData(data);

              scope.context_table[index] = data;

              //console.log("extended data with saved");
              // console.dir(data);
            }
          });

        }

        //console.log("all done, refreshing");

        scope.context_grid.api.setRowData(scope.context_table);
        scope.zingChart('parentChart', scope.dataset.Datastore.TablePrefix, scope.tableGrid);

        if (scope.childDataset)
          scope.zingChart('childChart', scope.childDataset.Datastore.TablePrefix, scope.childTableGrid);

      });

    }

    //if we have a file column, note it in a property for easy use when calculating rowheight
    scope.noteFileFields = function (dataset, columns) {
      var file_fields = [];
      columns.forEach(function (field) {
        if (field.ControlType == 'file')
          file_fields.push(field.DbColumnName);
      })
      if (file_fields.length > 0)
        dataset.file_fields = file_fields;

      //console.dir(dataset.file_fields);
    }

    //use the files, comments, description fields to determine the height of the row
    scope.calculateRowHeight = function (dataset, params) {
      var desc_length = (!params.data.Description) ? 1 : params.data.Description.length;
      var comment_length = (!params.data.Comments) ? 1 : params.data.Comments.length;
      var longest = (comment_length > desc_length) ? comment_length : desc_length;
      var comment_height = 25 * (Math.floor(longest / 45) + 1); //base our detail height on the Description field.
      comment_height = (comment_height > 75) ? 75 : comment_height; //maxheight (based on comment/description)= 3 rows

      if (dataset.hasOwnProperty('file_fields')) {
        var num_files = 0
        dataset.file_fields.forEach(function (field) {
          var these_files = getFilesArrayAsList(params.data[field]).length;
          if (these_files > num_files)
            num_files = these_files
        });

        let file_height = 25 * num_files;

        if (file_height > comment_height) {

          comment_height = file_height;
        }
      }

      dataset.Fields.forEach(field => {
        // var new_num_files = 0
        if (field.DbColumnName == 'ExternalLinks') {
          let num_files = 0
          let these_files = getFilesArrayAsList(params.data['ExternalLinks']).length;
          // try these files too
          if (these_files == 0) {
            these_files = getFilesArrayAsList(params.data['FileHyperLink']).length;
          }
          // var these_files= (temp.match(/is/g) || []).length;
          if (these_files > num_files) {
            num_files = these_files
          }

          let file_height = 25 * num_files;

          if (file_height > comment_height) {

            comment_height = file_height;
          }
        }

        if (field.DbColumnName == 'FileHyperLink') {
          let these_files = getFilesArrayAsList(params.data['FileHyperLink']).length;
          // var these_files= (temp.match(/is/g) || []).length;
          if (these_files > num_files) {
            num_files = these_files
          }

          let file_height = 25 * num_files;

          if (file_height > comment_height) {

            comment_height = file_height;
          }
        }

      });

      return comment_height;
    }

    scope.zingChart = function (chartId, tablePrefix, grid) {

      var functionName = tablePrefix + "ZingChartData";
      if (typeof window[functionName] !== "function") {
        console.log("No chart found for: " + functionName);
        return;
      }

      scope.col_left = "col-9";
      scope.col_right = "col-3";

      var chartData = window[functionName](grid);


      render({
        id: chartId,
        data: chartData
      })
    }

    //handle favorite toggle
    scope.isFavorite = $rootScope.Profile.isDatasetFavorite(routeParams.Id);
    scope.toggleFavorite = function () {
      UserService.toggleFavoriteDataset(scope, $rootScope);
    }

    scope.loadTable = function (datasetId) {
      scope.tabledata = DatasetService.getTableData(datasetId);

      scope.tabledata.$promise.then(function () {
        //console.dir(scope.tabledata);
        if (scope.dataset.Config.hasOwnProperty('OmitFirstRecord')) {
          if (scope.dataset.Config.OmitFirstRecord)
            scope.tabledata = scope.tabledata.slice(1);
        }

        scope.tableGrid.api.setRowData(scope.tabledata);
      });
    }
  }


];

export default table_editor;
