/*
*   This page loads the project files (gallery and documents)
*/
import * as angular from 'angular';
import * as moment from 'moment';
import * as $ from 'jquery';
import { convertDateFromUnknownStringToUTC } from "../../../../../appjsLegacy/core/common/common-functions";
import {
  cdmsShareUrl,
  SharingLevel, SHARINGLEVEL_GROUPREAD,
  SHARINGLEVEL_PRIVATE,
  SHARINGLEVEL_PUBLICREAD,
  SHARINGLEVEL_REFERENCE_LIBRARY
} from "../../../../config";

import { Grid } from "ag-grid-community";
import 'ag-grid-enterprise';

declare const hab_item: any;

var project_files = ['$scope', '$routeParams', 'SubprojectService',
  'ProjectService', 'DatasetService', 'CommonService', 'UserService',
  '$rootScope', '$uibModal', '$sce', '$window', '$http', '$timeout',
  'ServiceUtilities', '$location', '$anchorScroll', '$rootScope',
  function (scope, routeParams, SubprojectService,
    ProjectService, DatasetService, CommonService, UserService,
    $rootScope, $uibModal, $sce, $window, $http, $timeout,
    ServiceUtilities, $location, $anchorScroll, rootScope) {

    scope.OnTab = "Files";
    scope.project = ProjectService.getProject(routeParams.Id);

    scope.metadataList = {};
    scope.uploadFileType = "";
    scope.filesToUpload = {};

    scope.project.$promise.then(function () {

      //load all of the project's files
      scope.project.Files = ProjectService.getProjectFiles(scope.project.Id);

      //since we want a tab of images and a tab of other files,
      // sort them out into three arrays we will use to populate the tabs.
      scope.project.Images = [];
      scope.project.Docs = [];
      scope.project.SubprojectFiles = [];

      //once they load... (the docs and gallery tabs listen for this and then handle their grids.)

      scope.project.Files.$promise.then(res => {
        console.log('-------------- project FILES are loaded >>>>>>>>>>>>>>>> ');
        //console.dir(scope.project.Files);
        // console.log(scope.project.Files);
        res.forEach(function (file, key) {
          // If the user created a document and left the Title or Description blank, those fields were saved as "undefined" in the database.
          // When we read the list of files back in, the "undefined" shows on the page, and the user would rather have a blank show instead.
          file.Title = (!file.Title || file.Title === 'undefined' || typeof file.Title === 'undefined') ? "" : file.Title;
          file.Description = (!file.Description || file.Description === 'undefined' || typeof file.Description === 'undefined') ? "" : file.Description;

          //here we'll sort the files into some arrays...
          // scope.project.Docs = document tab
          // scope.project.Images = images tab
          // scope.project.SubprojectFiles = subproject files <-- TODO: someday refactor this away so that projects are just nested...

          //note: Subproject_CrppId indicates the file belongs to a subproject (not just crpp)
          if (file.DatasetId === null && file.Subproject_CrppId === null) { // Neither DatasetId nor Subproject*Id = project
            if (file.FileType.Name === "Image") { //images go to 'Gallery' tab
              scope.project.Images.push(file);
            } else { //everything else goes to 'Documents' tab
              scope.project.Docs.push(file);
            }
          }
          else if (file.DatasetId !== null && file.Subproject_CrppId === null) // DatasetId and no Subproject*Id = Dataset
          {
            // No nothing, because this is handled in dataset-edit-form.ts, $scope.dataset.Files.
          }
          else if (file.DatasetId === null && file.Subproject_CrppId !== null) // No DatasetId and Subproject*Id = Subproject
          {
            scope.project.SubprojectFiles.push(file);
          }
          else {
            console.log("This file is not a project, dataset, or subproject file.  What is it then?");
          }
        });
        console.log("OK! Done loading files for project");

        angular.element(function () {
          scope.activateGalleryGrid();
          scope.activateDocumentsGrid();
        });
      });
    });


    //return an array from the eventfiles.
    scope.getFilesArrayAsList = function (theFiles) {
      if (theFiles === undefined || theFiles === null)
        return [];

      var files = angular.fromJson(theFiles);
      return (files === null || !Array.isArray(files)) ? [] : files; //if it isn't an array, make an empty array

    }

    //return an array of file links to cdmsShareUrl (defined in config) for subproject
    scope.getSubprojectFilesArrayAsLinks = function (a_projectId, a_subprojectId, a_files) {
      var files = scope.getFilesArrayAsList(a_files);
      var retval = [];

      angular.forEach(files, function (file, value) {
        retval.push("<a href='" + cdmsShareUrl + "P/" + a_projectId + "/S/" + a_subprojectId + "/" + file.Name + "' target=\"_blank\">" + file.Name + "</a>");
      });

      return retval;
    }


    //selection to edit, callback to fire on success.
    scope.openEditFileModal = function (a_selection, a_callback) {
      scope.row = a_selection;
      scope.callback = a_callback;
      var modalInstance = $uibModal.open({
        templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/modal-edit-file.html',
        controller: 'ModalEditFileCtrl',
        scope: scope, //very important to pass the scope along...
        backdrop: "static",
        keyboard: false
      });
    };

    scope.openNewFileModal = function (a_callback) {
      scope.callback = a_callback;
      var modalInstance = $uibModal.open({
        templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/modal-upload-files.html',
        controller: 'ModalNewFileCtrl',
        windowClass: 'modal-large',
        backdrop: 'static',
        keyboard: false,
        scope: scope, //very important to pass the scope along...
        // backdrop: "static",
        // keyboard: false
      });
    };

    //removes the given file from project.Files (usually after deleting an item from docs/gallery already)
    scope.removeFromFiles = function (removed_item) {
      scope.project.Files.forEach(function (item, index) {
        //console.log("item id is " + item.Id + " looking for " + removed_item.File.Id);
        if (item.Id === removed_item.Id) {
          //console.log("FOund an ID that matches for delete");
          scope.project.Files.splice(index, 1);
        }
      });
    };


    // ************ from gallerytab **************

    var UploadedTemplate = function (param) {
      //return moment(param.node.data.UploadDate).format('L') + " by " + param.node.data.User.Fullname;
      return moment(param.node.data.UploadDate).format('L'); // Produces a format like 12/31/2021.
      //return moment(param.node.data.UploadDate).format("YYYY/MM/DD");
    };

    var UploadedByTemplate = function (param) {
      //console.dir(param);
      //console.log("uploaded by template!");
      //return moment(param.node.data.UploadDate).format('L') + " by " + param.node.data.User.Fullname;
      return param.node.data.User.Fullname;
    };

    var EditLinksTemplate = function (param) {

      var div = document.createElement('div');

      var editBtn = document.createElement('a');
      editBtn.href = '#';
      editBtn.innerHTML = 'Edit';
      editBtn.addEventListener('click', function (event) {
        event.preventDefault();
        scope.openEditFileModal(param.data, scope.afterEditGalleryFile);
      });
      div.appendChild(editBtn);
      div.appendChild(document.createTextNode(" | "));

      var delBtn = document.createElement('a');
      delBtn.href = '#';
      delBtn.innerHTML = 'Delete';
      delBtn.addEventListener('click', function (event) {
        event.preventDefault();
        scope.deleteGalleryFile(param.data);
      });
      div.appendChild(delBtn);

      return div;
    };


    var ImageTemplate = function (param) {

      var div = document.createElement('div');

      var linkBtn = document.createElement('a');
      linkBtn.href = param.data.Link;
      linkBtn.target = "_blank";

      var img = document.createElement('img');
      img.src = param.data.Link;
      img.width = 150;

      linkBtn.appendChild(img);
      div.appendChild(linkBtn);

      return div;
    };

    scope.dateComparator = function (date1, date2) {
      var date1Number = scope.monthToNum(date1);
      var date2Number = scope.monthToNum(date2);

      if (date1Number === null && date2Number === null) {
        return 0;
      }
      if (date1Number === null) {
        return -1;
      }
      if (date2Number === null) {
        return 1;
      }

      return date1Number - date2Number;
    };

    scope.monthToNum = function (date) {
      if (date === undefined || date === null || date.length !== 10) {
        return null;
      }

      var yearNumber = date.substring(6, 10);
      //if (yearNumber === 2018)
      //  console.log("Found yearNumber...");
      var monthNumber = date.substring(0, 2);
      var dayNumber = date.substring(3, 5);

      var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
      // 29/08/2004 => 20040829
      return result;
    };

    //scope.fileSelection = [];
    //scope.FileFilterOptions = {};
    //scope.GalleryFilterOptions = {};

    // -------------- gallery grid -------------
    scope.galleryGridOptions = {
      suppressPropertyNamesCheck: true,
      //enableSorting: true,
      //enableFilter: true,
      //enableColResize: true,
      rowHeight: 42,
      rowSelection: 'single',
      onSelectionChanged: function (params) {
        //console.log("selection changed!");
        //scope.galleryGridOptions.selectedItems = scope.galleryGridOptions.api.getSelectedRows();
        //console.dir(scope.galleryGridOptions.selectedItems);
        //scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
      },
      getRowHeight: function () {
        return 120;
      },
      onFilterModified: function () {
        scope.galleryGridOptions.api.deselectAll();
      },
      //selectedItems: [],
      columnDefs:
        [
          { colId: 'EditLinks', cellRenderer: EditLinksTemplate, width: 120, menuTabs: [], hide: true },
          { field: 'Title', headerName: 'Title', width: 250, sort: 'asc', menuTabs: ['filterMenuTab'], filter: 'text' },
          { headerName: 'File', cellRenderer: ImageTemplate, width: 190, menuTabs: [] },
          {
            field: 'Description',
            headerName: 'Description',
            cellStyle: { 'white-space': 'normal' },
            width: 300,
            menuTabs: ['filterMenuTab'],
            filter: 'text',
          },
          { field: 'Keyword', headerName: 'Keyword', menuTabs: ['filterMenuTab'], filter: 'text' },
          {
            headerName: 'Sharing Level', field: 'SharingLevel', width: 150,
            cellRenderer: function (params) {
              if (params.node.data.SharingLevel == SHARINGLEVEL_PRIVATE)
                return SharingLevel['SHARINGLEVEL_PRIVATE'];
              else if (params.node.data.SharingLevel == SHARINGLEVEL_PUBLICREAD)
                return SharingLevel['SHARINGLEVEL_PUBLICREAD'];
              else if (params.node.data.SharingLevel == SHARINGLEVEL_GROUPREAD)
                return SharingLevel['SHARINGLEVEL_GROUPREAD'];
              else if (params.node.data.SharingLevel == SHARINGLEVEL_REFERENCE_LIBRARY)
                return SharingLevel['SHARINGLEVEL_REFERENCE_LIBRARY'];
              else return 'Unknown';
            }, menuTabs: [],
          },
          {
            field: 'Uploaded',
            headerName: "Uploaded",
            width: 120,
            valueGetter: UploadedTemplate,
            comparator: scope.dateComparator,
            menuTabs: ['filterMenuTab'],
            filter: 'agDateColumnFilter',
            filterParams: {
              // provide comparator function
              comparator: (filterLocalDateAtMidnight, cellValue) => {
                const dateAsString = cellValue;

                if (dateAsString == null) {
                  return 0;
                }

                // In the our application, dates are stored as mm/dd/yyyy
                // We create a Date object for comparison against the filter date
                const dateParts = dateAsString.split('/');
                const day = Number(dateParts[1]);
                const month = Number(dateParts[0]) - 1;
                const year = Number(dateParts[2]);
                const cellDate = new Date(year, month, day);

                // Now that both parameters are Date objects, we can compare
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                } else if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
                return 0;
              }
            }
          },
          {
            field: 'UploadedBy',
            headerName: "Uploaded By",
            width: 200,
            valueGetter: UploadedByTemplate,
            menuTabs: ['filterMenuTab'],
            filter: 'text'
          },
        ],
      defaultColDef: {
        sortable: true,
        resizable: true,
      },
    };

    scope.activateGalleryGrid = function () {

      // ----------- Load the gallery grid -------------

      var ag_grid_div = document.querySelector('#gallery-tab-grid') as HTMLElement;    //get the container id...

      if (typeof scope.gallerytab_ag_grid === 'undefined')
        scope.gallerytab_ag_grid = new Grid(ag_grid_div, scope.galleryGridOptions); //bind the grid to it.

      scope.galleryGridOptions.api.showLoadingOverlay(); //show loading...
      scope.galleryGridOptions.api.setRowData(scope.project.Images);
      scope.galleryGridOptions.api.sizeColumnsToFit();

      //if user can edit, unhide the edit links
      if (scope.Profile.canEditProject(scope.project))
        scope.galleryGridOptions.columnApi.setColumnVisible("EditLinks", true);

    };

    //edit our project images list and then reload the grid.
    scope.afterEditGalleryFile = function (edited_item) {
      scope.project.Images.forEach(function (item, index) {
        if (item.Id === edited_item.Id) {
          angular.extend(hab_item, edited_item); //replace the data for that item
        }
      });

      scope.galleryGridOptions.api.setRowData(scope.project.Images);
      console.log("done reloading grid after editing gallery item.");
    };

    //remove an image from our project docs list and then reload the grid.
    scope.deleteGalleryFile = function (removed_item) {

      if (confirm("Are you sure you want to delete this file? (there is no undo)")) {
        var promise = ProjectService.deleteFile(scope.project.Id, removed_item);

        promise.$promise.then(function () {
          scope.project.Images.forEach(function (item, index) {
            if (item.Id === removed_item.Id) {
              scope.project.Images.splice(index, 1);
            }
          });

          scope.removeFromFiles(removed_item);

          scope.galleryGridOptions.api.setRowData(scope.project.Images);
          console.log("done reloading grid after removing image item.");
        });
      }

    };


    // ************ from the files tab *****************

    var UploadedTemplate = function (param) {
      //return moment(param.node.data.UploadDate).format('L') + " by " + param.node.data.User.Fullname;
      var tmpDateTime = null;
      //console.log("typeof param.node.data.UploadDate = " + typeof param.node.data.UploadDate);
      if (typeof param.node.data.UploadDate === "string") {
        tmpDateTime = convertDateFromUnknownStringToUTC(param.node.data.UploadDate);
        param.node.data.UploadDate = moment(tmpDateTime);
      }
      return moment(param.node.data.UploadDate).format('L');
      //return moment(param.node.data.UploadDate).format("YYYY/MM/DD");
    };

    var UploadedByTemplate = function (param) {
      //return moment(param.node.data.UploadDate).format('L') + " by " + param.node.data.User.Fullname;
      return param.node.data.User.Fullname;
    };

    var EditLinksTemplate = function (param) {

      var div = document.createElement('div');

      var editBtn = document.createElement('a');
      editBtn.href = '#';
      editBtn.innerHTML = 'Edit';
      editBtn.addEventListener('click', function (event) {
        event.preventDefault();
        scope.openEditFileModal(param.data, scope.afterEditDocsFile);
      });
      div.appendChild(editBtn);
      div.appendChild(document.createTextNode(" | "));

      var delBtn = document.createElement('a');
      delBtn.href = '#';
      delBtn.innerHTML = 'Delete';
      delBtn.addEventListener('click', function (event) {
        event.preventDefault();
        scope.deleteDocFile(param.data);
      });
      div.appendChild(delBtn);

      return div;
    };

    var LinkTemplate = function (param) {

      var div = document.createElement('div');

      var linkBtn = document.createElement('a');
      linkBtn.href = param.data.Link;
      linkBtn.innerHTML = param.data.Title;
      linkBtn.target = "_blank";
      div.appendChild(linkBtn);
      return div;
    };

    var HyperlinkTemplate = function (param) {

      var div = document.createElement('div');

      var linkBtn = document.createElement('a');
      linkBtn.href = param.data.Link;
      linkBtn.innerHTML = param.data.Name;
      linkBtn.target = "_blank";
      div.appendChild(linkBtn);
      return div;
    };

    scope.dateComparator = function (date1, date2) {
      var date1Number = scope.monthToNum(date1);
      var date2Number = scope.monthToNum(date2);

      if (date1Number === null && date2Number === null) {
        return 0;
      }
      if (date1Number === null) {
        return -1;
      }
      if (date2Number === null) {
        return 1;
      }

      return date1Number - date2Number;
    };

    scope.monthToNum = function (date) {
      if (date === undefined || date === null || date.length !== 10) {
        return null;
      }

      var yearNumber = date.substring(6, 10);
      //if (yearNumber === 2018)
      //  console.log("Found yearNumber...");
      var monthNumber = date.substring(0, 2);
      var dayNumber = date.substring(3, 5);

      var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
      // 29/08/2004 => 20040829
      return result;
    };

    scope.changeFilterIcons = function (event) {
      // We need to call this onFilterModified and onBodyScroll, as the elements are removed/added
      // to the DOM when scrolling horizontally on wide datasets.
      // Can also pass $scope.dataAgGridOptions to this function rather than an event.
      let filters = scope.docsGridOptions.api.getFilterModel();

      for (let col of event.api.columnController.columnDefs) {
        for (const span of document.querySelectorAll("span")) {
          if (span.textContent.includes(col.field) || span.textContent.includes(col.headerName)) {
            // Naming conventions vary, so lets check all of these to see if the column has a filter
            if (col.Label in filters || col.headerName in filters || col.field in filters) {
              // If column has a filter change the icon color
              // @ts-ignore
              let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];
              icon.style.color = "#007bff";
            }
            else {
              // Remove the color prop from the icon if there is no filter
              // @ts-ignore
              let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];// filterIcon.children[0];
              if (icon && icon.style) {
                icon.style.color = "#bdbdbd";
              }
            }
          }
        }
      }
    };


    // ------------- documents grid -----------------
    scope.docsGridOptions = {
      suppressPropertyNamesCheck: true,
      //enableSorting: true,
      //enableFilter: true,
      //enableColResize: true,
      rowSelection: 'single',
      onSelectionChanged: function (params) {
        //console.log("selection changed!");
        scope.docsGridOptions.selectedItems = scope.docsGridOptions.api.getSelectedRows();
        //scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
      },
      onBodyScroll: function (event) {
        scope.changeFilterIcons(event);
      },
      onFilterModified: function (event) {
        scope.changeFilterIcons(event);
        scope.docsGridOptions.api.deselectAll();
      },
      //selectedItems: [],
      columnDefs:
        [
          { colId: 'EditLinks', cellRenderer: EditLinksTemplate, width: 120, menuTabs: [], hide: true },
          //{ field: 'Name', headerName: 'File', width: 250, sort: 'asc', cellRenderer: LinkTemplate },
          //{ field: 'Title', headerName: 'Title', sort: 'asc', cellRenderer: LinkTemplate, width: 230, menuTabs: ['filterMenuTab'], filter: 'text' },
          { field: 'Title', headerName: 'Title', sort: 'asc', width: 230, menuTabs: ['filterMenuTab'], filter: 'text' },
          {
            field: 'Name',
            headerName: 'File/Link',
            cellRenderer: HyperlinkTemplate,
            width: 230,
            menuTabs: ['filterMenuTab'],
            filter: 'text'
          },
          { field: 'Description', headerName: 'Description', menuTabs: ['filterMenuTab'], filter: 'text' },
          { field: 'Keyword', headerName: 'Keyword', menuTabs: ['filterMenuTab'], filter: 'text' },
          {
            headerName: 'Sharing Level', field: 'SharingLevel', width: 150,
            cellRenderer: function (params) {
              if (params.node.data.SharingLevel == SHARINGLEVEL_PRIVATE)
                return SharingLevel['SHARINGLEVEL_PRIVATE'];
              else if (params.node.data.SharingLevel == SHARINGLEVEL_PUBLICREAD)
                return SharingLevel['SHARINGLEVEL_PUBLICREAD'];
              else if (params.node.data.SharingLevel == SHARINGLEVEL_GROUPREAD)
                return SharingLevel['SHARINGLEVEL_GROUPREAD'];
              else if (params.node.data.SharingLevel == SHARINGLEVEL_REFERENCE_LIBRARY)
                return SharingLevel['SHARINGLEVEL_REFERENCE_LIBRARY'];
              else return 'Unknown';
            }, menuTabs: [],
          },
          //{ field: 'Uploaded', headerName: "Uploaded", width: 200, valueGetter: UploadedByTemplate, menuTabs: ['filterMenuTab'], filter: 'text' },
          {
            field: 'Uploaded',
            headerName: "Uploaded",
            comparator: scope.dateComparator,
            width: 120,
            valueGetter: UploadedTemplate,
            menuTabs: ['filterMenuTab'],
            filter: 'agDateColumnFilter',
            filterParams: {
              // provide comparator function
              comparator: (filterLocalDateAtMidnight, cellValue) => {
                const dateAsString = cellValue;

                if (dateAsString == null) {
                  return 0;
                }

                // In the our application, dates are stored as mm/dd/yyyy
                // We create a Date object for comparison against the filter date
                const dateParts = dateAsString.split('/');
                const day = Number(dateParts[1]);
                const month = Number(dateParts[0]) - 1;
                const year = Number(dateParts[2]);
                const cellDate = new Date(year, month, day);

                // Now that both parameters are Date objects, we can compare
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                } else if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
                return 0;
              }
            }
          },
          {
            field: 'UploadedBy',
            headerName: "Uploaded By",
            width: 200,
            valueGetter: UploadedByTemplate,
            menuTabs: ['filterMenuTab'],
            filter: 'text'
          },
        ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        icons: {
          menu: "<span class='material-symbols-outlined' id='filter-icon' title='Filter'>filter_alt</span>", filter: "<span></span>",
        }
      },
    };


    scope.activateDocumentsGrid = function () {
      var ag_grid_div = document.querySelector('#docs-tab-grid') as HTMLElement;    //get the container id...

      if (typeof scope.docstab_ag_grid === 'undefined')
        scope.docstab_ag_grid = new Grid(ag_grid_div, scope.docsGridOptions); //bind the grid to it.

      scope.docsGridOptions.api.showLoadingOverlay(); //show loading...
      scope.docsGridOptions.api.setRowData(scope.project.Docs);
      scope.docsGridOptions.api.sizeColumnsToFit();

      //if user can edit, unhide the edit links
      if (scope.Profile.canEditProject(scope.project))
        scope.docsGridOptions.columnApi.setColumnVisible("EditLinks", true);

    };


    ///////// file handling for Documents tab

    //open the new file modal
    scope.newFile = function () {
      scope.openNewFileModal(scope.afterNewFile);
    };

    scope.newLink = function () {

      scope.row = { SharingLevel: SHARINGLEVEL_PRIVATE };
      var modalInstance = $uibModal.open({
        templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/modal-edit-link.html',
        controller: 'ModalLinkCtrl',
        windowClass: 'modal-small',
        backdrop: 'static',
        keyboard: false,
        scope: scope, //very important to pass the scope along...
        // backdrop: "static",
        // keyboard: false
      }).result.then(function (new_link) {
        scope.project.Files.push(new_link);
        scope.project.Docs.push(new_link);
        scope.docsGridOptions.api.setRowData(scope.project.Docs);
      });
    }

    //after create a new file
    scope.afterNewFile = function (new_item) {
      console.log("After saved a doc");
      //console.dir(new_item[0]);
      if (new_item[0].FileTypeId === 1) {
        var ag_grid_div = undefined;
        scope.project.Images.push(new_item[0]);
        scope.project.Files.push(new_item[0]);
        scope.galleryGridOptions.api.setRowData(scope.project.Images);
      } else {
        scope.project.Docs.push(new_item[0]);
        scope.project.Files.push(new_item[0]);
        scope.docsGridOptions.api.setRowData(scope.project.Docs); // This line refreshes the grid on the documents tab.
      }
      console.log("done reloading grid after adding docs item.");
    };


    //remove an item from our project docs list and then reload the grid.
    scope.deleteDocFile = function (removed_item) {
      if (confirm("Are you sure you want to delete this file? (there is no undo)")) {
        var promise = ProjectService.deleteFile(scope.project.Id, removed_item);

        promise.$promise.then(function () {
          scope.project.Docs.forEach(function (item, index) {
            if (item.Id === removed_item.Id) {
              scope.project.Docs.splice(index, 1);
            }
          });

          scope.removeFromFiles(removed_item);

          scope.docsGridOptions.api.setRowData(scope.project.Docs);
          console.log("done reloading grid after removing doc item.");
        });
      }
    };

    //edit our project docs list and then reload the grid.
    scope.afterEditDocsFile = function (edited_item) {
      scope.project.Docs.forEach(function (item, index) {
        if (item.Id === edited_item.Id) {
          angular.extend(hab_item, edited_item); //replace the data for that item
        }
      });

      scope.docsGridOptions.api.setRowData(scope.project.Docs);
      console.log("done reloading grid after editing doc item.");
    };


    //handle favorite toggle
    scope.isFavorite = $rootScope.Profile.isProjectFavorite(routeParams.Id);
    scope.toggleFavorite = function () {
      UserService.toggleFavoriteProject(scope, $rootScope);
    }

  }];


export default project_files;



