import * as angular from 'angular';
import {parseArrayToStringValues} from "../../../common/common-functions";

var modal_edit_project = ['$scope', '$uibModal', '$uibModalInstance', 'ProjectService', 'CommonService',
  function (scope, $uibModal, $uibModalInstance, ProjectService, CommonService) {

    if (scope.row && scope.row.Id) {
      scope.header_message = "Edit project: " + scope.project.Name;
    } else {
      scope.header_message = "Create new project";
      scope.row = scope.project;
    }

    // Note:  If the propery value similar to ({"ShowHabitatSitesForDatasets":{},...}),
    // it will fail the hasOwnProperty test.
    // If the value is similar to ({"ShowHabitatSitesForDatasets":[],...}), it will pass.
    if (scope.project.Config && scope.project.Config.hasOwnProperty("ShowHabitatSitesForDatasets")) {
      // Checking the length,
      if (typeof scope.project.Config.ShowHabitatSitesForDatasets.length !== 'undefined')
        scope.project.Config.ShowHabitatSitesForDatasetsValues = parseArrayToStringValues(scope.project.Config.ShowHabitatSitesForDatasets);
    }

    scope.SavedConfig = scope.project.Config;

    //make the row of values that map to our field directives.
    scope.project.MetaFields.forEach(function (field) {
      field.DbColumnName = field.Label = field.Name;
      if (field.Values)
        scope.row[field.DbColumnName] = field.Values;
      else
        scope.row[field.DbColumnName] = null;
    });

    scope.openChooseLookupLists = function () {

      var modalInstance = $uibModal.open({
        templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/modal-choose-project-lists.html',
        controller: 'ModalProjectConfigLists',
        scope: scope, //very important to pass the scope along...
        backdrop: "static",
        keyboard: false
      }).result.then(function (saved_field) {

      });
    };

    scope.getConfig = function () {
      var result = "";
      try {
        result = angular.toJson(scope.project.Config);
      } catch (e) {
        //nothing in config.
        console.log("couldn't toJson config: " + scope.project.Config)
      }

      return result;
    };

    scope.save = function () {

      // Turned off by CTUIR but kept, in case this sort of thing may be desireable by other organizaions.
      // if (!scope.row.Name) {
      //   alert("You must enter a Program/Project Name!");
      //   return;
      // }

      // if (!scope.row['Program'] || !scope.row['Sub-program']) {
      //   alert("You must choose a Program and Sub-program on the 'Project' tab.");
      //   return;
      // }

      scope.row.Metadata = [];

      //need to make multi-selects into json objects
      angular.forEach(scope.row.MetaFields, function (md) {
        if (scope.row[md.DbColumnName]) {

          console.log("scope.row is next...");
          console.dir(scope.row);
          //flatten multiselect values into an json array string
          if (md.ControlType == "multiselect" || md.ControlType == "multiselect-checkbox") {
            md = angular.copy(md);
            md.Values = angular.toJson(scope.row[md.DbColumnName]);
          } else {
            md.Values = scope.row[md.DbColumnName];
          }
          md.RelationId = scope.project.Id;
          md.UserId = scope.currentUserId;
          delete md.EffDt;
          scope.row.Metadata.push(md);
        }
      });

      var saveRow = angular.copy(scope.row);

      delete saveRow.Editors;
      delete saveRow.Files;
      delete saveRow.Instruments;
      delete saveRow.Editors;
      delete saveRow.Locations;
      delete saveRow.MetaFields;
      delete saveRow.Owner;

      if (scope.project.Config && scope.project.Config.hasOwnProperty('ShowHabitatSitesForDatasetsValues'))
        delete scope.project.Config.ShowHabitatSitesForDatasetsValues;

      saveRow.Config = scope.getConfig();

      var promise = ProjectService.saveProject(saveRow);
      promise.$promise.then(function (saved_project) {
        //reconstitute our saved project's config
        try {
          saved_project.Config = angular.fromJson(saved_project.Config);
        } catch (e) {
          console.log("couldn't reload config: " + saved_project.Config)
          //do nothing
        }
        $uibModalInstance.close(saved_project);
      });

    };

    scope.cancel = function () {
      scope.project.Config = scope.SavedConfig;
      $uibModalInstance.dismiss();
    };


  }
];

export default modal_edit_project;
