//controller for the main project list page.
import * as angular from 'angular';
import { METADATA_ENTITY_PROJECT, serverUrl } from "../../../../config";
import { Grid } from "ag-grid-community";
import 'ag-grid-enterprise';
// note changing $iobModal to $uibModal works
var project_list = ['$scope', 'DatasetService', 'ProjectService', 'CommonService', '$uibModal', '$window',
    function ($scope, DatasetService, ProjectService, CommonService, $uibModal, $window) {

        console.log('project list controller called');

        $scope.projects = ProjectService.getProjects();

        $scope.newProject = function () {

            $scope.project = {};
            $scope.project.MetaFields = CommonService.getMetadataProperties(METADATA_ENTITY_PROJECT);

            //if habitat project then load those fields, too...
            $scope.project.MetaFields.$promise.then(function () {

                $scope.project.MetaFields.forEach(function (field) { field.MetadataPropertyId = field.Id });

                // var habfields = CommonService.getMetadataProperties(METADATA_ENTITY_HABITAT);
                // habfields.$promise.then(function () {

                //     habfields.forEach(function (habfield) {
                //         habfield.MetadataPropertyId = habfield.Id
                //         habfield.isHabitat = true;
                //         $scope.project.MetaFields.push(habfield);
                //     });

                $scope.openNewProjectModal();

                // });
            });
        }

        $scope.openNewProjectModal = function () {
            console.log("Inside project-list.js, openAddProject...");

            const templateUrl = 'appjsLegacy/core/projects/components/project-detail/templates/modal-edit-project.html';

            //            if (typeof TRIBALCDMS_TEMPLATES !== 'undefined') {
            //                templateUrl = 'appjsLegacy/core/projects/components/project-detail/' + TRIBALCDMS_TEMPLATES + '/modal-edit-project.html';
            //            }

            var modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: 'ModalProjectEditorCtrl',
                scope: $scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_project) {
                $scope.projects = ProjectService.getProjects();
                $scope.projects.$promise.then(function () {
                    $scope.afterProjectsLoaded();
                });
            });
        };

        $scope.projects.$promise.then(function () {
            $scope.afterProjectsLoaded();
        });

        $scope.changeFilterIcons = function (event) {
            // We need to call this onFilterModified and onBodyScroll, as the elements are removed/added
            // to the DOM when scrolling horizontally on wide datasets.
            // Can also pass $scope.dataAgGridOptions to this function rather than an event.
            let filters = $scope.agGridOptions.api.getFilterModel();

            for (let col of event.api.columnController.columnDefs) {
                for (const span of document.querySelectorAll("span")) {
                    if (span.textContent.includes(col.field) || span.textContent.includes(col.headerName)) {
                        // Naming conventions vary, so lets check all of these to see if the column has a filter
                        if (col.Label in filters || col.headerName in filters || col.field in filters) {
                            // If column has a filter change the icon color
                            // @ts-ignore
                            let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];
                            console.log(icon);
                            icon.style.color = "#007bff";
                        }
                        else {
                            // Remove the color prop from the icon if there is no filter
                            // @ts-ignore
                            let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];// filterIcon.children[0];
                            if (icon && icon.style) {
                                icon.style.color = "#bdbdbd";
                            }
                        }
                    }
                }
            }
        };

        $scope.afterProjectsLoaded = function () {
            //spin through and add a "Program" field to our project that we can display easily in the grid.
            angular.forEach($scope.projects, function (project, key) {

                if (project.SubProgram && project.SubProgram != "(None)")
                    project.Program += " > " + project.SubProgram;
            });

            if ($scope.agGridOptions === undefined) {

                //define the cell renderer (template) for our "Project Name" column.
                var agCellRendererProjectName = function (params) {
                    return '<div>' +
                        '<a title="' + params.node.data.Description
                        + '" href="projects/' + params.node.data.Id + '">'
                        + params.node.data.Name + '</a>' +
                        '</div>';
                };



                var agColumnDefs = [
                    { field: 'Name', headerName: 'Project Name', cellRenderer: agCellRendererProjectName, width: 300, sort: 'asc', menuTabs: ['filterMenuTab'], filter: 'text' },
                    { field: 'Program', headerName: 'Program', width: 220, menuTabs: ['filterMenuTab'], filter: true },
                    { field: 'ProjectType', headerName: 'Type', width: 130, menuTabs: ['filterMenuTab'], filter: true },
                    {
                        field: 'DatasetsExist',
                        headerName: 'Datasets Exist',
                        width: 130,
                        menuTabs: ['filterMenuTab'],
                        valueGetter: function (data) {
                            if (data.data.hasDataset === "unknown") {
                                return "";
                            }
                            else if (data.data.hasDataset === "yes") {
                                return "Yes";
                            }
                            else if (data.data.hasDataset === "no") {
                                return "No";
                            }
                        },
                        filter: true
                    }
                ];

                $scope.agGridOptions = {
                    suppressPropertyNamesCheck: true,
                    animateRows: true,
                    rowSelection: 'single',
                    //enableSorting: true,
                    //enableFilter: true,
                    //enableColResize: true,
                    // showToolPanel: false,
                    columnDefs: agColumnDefs,
                    rowData: $scope.projects,
                    debug: false,
                    onGridReady: function (params) {
                        params.api.sizeColumnsToFit();
                        setTimeout(() => {
                            $scope.refreshGrid();
                        }, 300)
                    },
                    onBodyScroll: function (event) {
                        $scope.changeFilterIcons(event);
                    },
                    onFilterModified: function (event) {
                        $scope.changeFilterIcons(event);
                    },
                    defaultColDef: {
                        sortable: true,
                        resizable: true,
                        icons: {
                            menu: "<span class='material-symbols-outlined' id='filter-icon' title='Filter'>filter_alt</span>",
                            filter: "<span></span>",
                        }
                    },
                    onSelectionChanged: function (params) {
                        $scope.agGridOptions.selectedItem = $scope.agGridOptions.api.getSelectedRows()[0];
                        $scope.$apply();
                    },
                };

                var ag_grid_div = document.querySelector('#project-list-grid') as HTMLElement;    //get the container id...
                $scope.ag_grid = new Grid(ag_grid_div, $scope.agGridOptions); //bind the grid to it.
                //scope.agGridOptions.api.showLoadingOverlay(); //show loading...

            } else {
                console.log("or else")
                $scope.agGridOptions.api.setRowData($scope.projects);
                $scope.refreshGrid();
            }

            $scope.projects.forEach(async function (project) {
                project.hasDataset = "unknown";
                ProjectService.getProjectDatasets(project.Id).$promise.then(function (result) {
                    console.log(result.length);
                    if (result.length > 0) {
                        project.hasDataset = "yes";
                    } else {
                        project.hasDataset = "no";
                    }
                })
            })

            $scope.refreshGrid = function () {
                $scope.agGridOptions.api.refreshCells({ force: true });
            }

            $scope.deleteProject = function () {
                if (confirm("Are you sure you want to delete this project? There is no undo.")) {
                    console.log($scope.agGridOptions.selectedItem);
                    var deleted = ProjectService.deleteProject($scope.agGridOptions.selectedItem.Id);
                    deleted.$promise.then(function () {
                        $scope.projects.forEach(function (store, index) {
                            if (store.Id == $scope.agGridOptions.selectedItem.Id) {
                                $scope.projects.splice(index, 1);
                            }
                        });
                        $scope.agGridOptions.selectedItem = null;
                        $scope.agGridOptions.api.setRowData($scope.projects);
                    }, function (error) {
                        console.log("there was an error.");
                        console.dir(error);
                    })
                }
            }

            // var project_grid_div = document.querySelector('#project-list-grid') as HTMLElement;    //get the container id...
            // $scope.project_grid = new Grid(project_grid_div, $scope.agGridOptions); //bind the grid to it.
            // $scope.agGridOptions.api.setRowData($scope.projects);
        }
    }
];

export default project_list;
