import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppjsComponent} from './appjs.component';
import {ProfileService} from "../login/profile.service";
//import {ConfirmService} from "../confirm/confirm.service";

const routes: Routes = [
  {path: '**', component: AppjsComponent, canActivate: [ProfileService]},
  //{path: '**', component: AppjsComponent, canActivate: [ConfirmService]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppjsRoutingModule { }
