import * as angular from 'angular';
import { valueFormatterArrayToList } from "../../../common/common-functions";
import { Grid } from "ag-grid-community";
import 'ag-grid-enterprise';

/*
*   This page loads the metafields (entities and properties).
*/

var admin_metafields = ['$scope', '$routeParams', 'GridService', 'ProjectService', 'DatasetService', 'CommonService', 'UserService',
  '$rootScope', '$uibModal', '$sce', '$window', '$http',
  'ServiceUtilities', 'ConvertStatus', '$location', '$anchorScroll',
  function (scope, routeParams, GridService, ProjectService, DatasetService, CommonService, UserService, $rootScope, $uibModal, $sce, $window, $http,
    ServiceUtilities, ConvertStatus, $location, $anchorScroll) {

    scope.entities = CommonService.getMetadataEntities();

    scope.entities.$promise.then(function () {
      if (!scope.selectedEntity) //only set if it isn't already
        scope.selectedEntity = scope.entities[0];

      scope.activateDataGrid();
    });

    scope.showEntityProperties = function (entity) {
      if (!entity)
        return;

      if (scope.selectedEntity && scope.selectedEntity.Id != entity.Id) {
        console.log("Showing entity properties -- ");
        console.dir(entity);
        scope.selectedEntity = entity;
        if (scope.dataGridOptions.api)
          scope.dataGridOptions.api.setRowData(scope.selectedEntity.Properties);
      }
    };

    var EditLinkTemplate = function (param) {

      var div = document.createElement('div');

      var editBtn = document.createElement('a');
      editBtn.href = '#';
      editBtn.innerHTML = 'Edit';
      editBtn.addEventListener('click', function (event) {
        event.preventDefault();
        scope.openEditModal(param.data);
      });
      div.appendChild(editBtn);

      return div;
    };

    scope.changeFilterIcons = function (event) {
      // We need to call this onFilterModified and onBodyScroll, as the elements are removed/added
      // to the DOM when scrolling horizontally on wide datasets.
      // Can also pass $scope.dataAgGridOptions to this function rather than an event.
      let filters = scope.dataGridOptions.api.getFilterModel();

      for (let col of event.api.columnController.columnDefs) {
        for (const span of document.querySelectorAll("span")) {
          if (span.textContent.includes(col.field) || span.textContent.includes(col.headerName)) {
            // Naming conventions vary, so lets check all of these to see if the column has a filter
            if (col.Label in filters || col.headerName in filters || col.field in filters) {
              // If column has a filter change the icon color
              // @ts-ignore
              let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];
              icon.style.color = "#007bff";
            }
            else {
              // Remove the color prop from the icon if there is no filter
              // @ts-ignore
              let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];// filterIcon.children[0];
              if (icon && icon.style) {
                icon.style.color = "#bdbdbd";
              }
            }
          }
        }
      }
    };

    scope.dataGridOptions = {
      //data: 'datasets',
      //enableSorting: true,
      //enableFilter: true,
      //enableColResize: true,
      suppressPropertyNamesCheck: true,
      columnDefs: [
        { field: 'EditLink', headerName: '', cellRenderer: EditLinkTemplate, width: 50, menuTabs: [], hide: true },
        { field: 'Name', headerName: 'Name', width: 180, menuTabs: ['filterMenuTab'], filter: 'text' },
        {
          field: 'Description',
          headerName: 'Description',
          cellStyle: { 'white-space': 'normal' },
          width: 300,
          menuTabs: ['filterMenuTab'],
          filter: 'text'
        },
        { field: 'DataType', headerName: 'DataType', width: 100, menuTabs: ['filterMenuTab'], filter: 'text' },
        { field: 'ControlType', headerName: 'Control Type', width: 100, menuTabs: ['filterMenuTab'], filter: 'text' },
        {
          field: 'PossibleValues',
          headerName: 'Possible Values',
          width: 350,
          menuTabs: ['filterMenuTab'],
          filter: 'text',
          valueFormatter: function (params) {
            return valueFormatterArrayToList(params.node.data.PossibleValues);
          }
        },
      ],
      rowSelection: 'single',
      onSelectionChanged: function (params) {
        scope.dataGridOptions.selectedItems = scope.dataGridOptions.api.getSelectedRows();
        scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
      },
      onBodyScroll: function (event) {
        scope.changeFilterIcons(event);
      },
      onFilterModified: function (event) {
        scope.changeFilterIcons(event);
      },
      selectedItems: [],
      defaultColDef: {
        sortable: true,
        resizable: true,
        icons: {
          menu: "<span class='material-symbols-outlined' id='filter-icon' title='Filter'>filter_alt</span>", filter: "<span></span>",
        }
      },
    };

    scope.activateDataGrid = function () {
      console.log("activating grid...");

      var ag_grid_div = document.querySelector('#properties-grid') as HTMLElement;    //get the container id...
      scope.datatab_ag_grid = new Grid(ag_grid_div, scope.dataGridOptions); //bind the grid to it.
      scope.dataGridOptions.api.showLoadingOverlay(); //show loading...
      scope.dataGridOptions.api.setRowData(scope.selectedEntity.Properties);

      if (scope.project) {
        scope.project.$promise.then(function () {
          //unhide the edit link column if they are the owner or editor.
          if ($rootScope.Profile.canAdminProject(scope.project)) {
            scope.dataGridOptions.columnApi.setColumnVisible("EditLink", true);
            scope.dataGridOptions.api.refreshHeader();
          }
        });
      } else if ($rootScope.Profile.isAdmin()) { //let the super or org admin still edit if there is no project.
        scope.dataGridOptions.columnApi.setColumnVisible("EditLink", true);
        scope.dataGridOptions.api.refreshHeader();
      }


    };

    scope.openEditModal = function (a_selection) {
      scope.SaveMessage = null;
      scope.field_to_edit = angular.copy(a_selection);
      scope.field_to_edit.MetadataEntityId = scope.selectedEntity.Id;
      var modalInstance = $uibModal.open({
        templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/modal-edit-metadataproperty.html',
        controller: 'ModalEditMetadataPropertyCtrl',
        scope: scope, //very important to pass the scope along...
        backdrop: "static",
        keyboard: false
      }).result.then(function (saved) {
        //replace that location in the grid with the one we got back
        var found = false;
        scope.selectedEntity.Properties.forEach(function (existing, index) {
          if (existing.Id == saved.Id) {
            console.dir("found field to replace : " + existing.Id);
            scope.selectedEntity.Properties[index] = saved;
            found = true;
          }
        });

        if (!found)
          scope.selectedEntity.Properties.push(saved);

        scope.dataGridOptions.api.setRowData(scope.selectedEntity.Properties);
        scope.SaveMessage = "Success.";
      });
    };


    scope.addProperty = function () {
      scope.openEditModal({});
    }

    scope.deleteProperty = function () {
      scope.dataGridOptions.selectedItems.forEach(function (property) {
        var delete_prop = CommonService.deleteMetadataProperty(property);
        delete_prop.$promise.then(function () {
          scope.selectedEntity.Properties.forEach(function (existing, index) {
            if (existing.Id == property.Id) {
              console.dir("found field to remove : " + existing.Id);
              scope.selectedEntity.Properties.splice(index);
              scope.dataGridOptions.api.setRowData(scope.selectedEntity.Properties);
            }
          });
        }, function () {
          console.log("something went wrong");
        });
      });

    };


  }

];

export default admin_metafields;
