import * as angular from 'angular';
import { Grid } from "ag-grid-community";
import 'ag-grid-enterprise';
// declare const agGrid: any;


var landing_page = ['$scope', '$rootScope', '$location', 'DatasetService', 'UserService', '$window',
    function ($scope, $rootScope, $location, DatasetService, UserService, $window) {
        $scope.myprojects = UserService.getMyProjectsList();
        $scope.mydatasets = UserService.getMyDatasetsList();
        $scope.searchResults = false;

        //if the user doesn't have any projects or datasets, bounce them to the projects page
        $scope.myprojects.$promise.then(function () {
            $scope.checkIfEmpty();
        })

        $scope.results = [];

        $scope.submitSearch = function () {
            $scope.results = [];
            $scope.searchResults = true;
            $scope.searching_search_term = $scope.search_term;

            $scope.search_projects = UserService.searchProjects($scope.search_term);
            $scope.search_projects.$promise.then(function () {
                $scope.search_projects.forEach(function (project) {
                    $scope.results.push(
                        {
                            "Name": project.Name,
                            "Description": project.Description,
                            "Type": "Project",
                            "Link": "/projects/" + project.Id
                        })
                })
                $scope.agGridOptions.api.setRowData($scope.results)
            })

            $scope.search_datasets = UserService.searchDatasets($scope.search_term);
            $scope.search_datasets.$promise.then(function () {
                $scope.search_datasets.forEach(function (dataset) {
                    DatasetService.configureDataset(dataset);
                    $scope.results.push(
                        {
                            "Name": dataset.Name,
                            "Description": dataset.Description,
                            "Link": "/" + dataset.activitiesRoute + "/" + dataset.Id,
                            "Type": "Dataset"
                        })
                })
                $scope.agGridOptions.api.setRowData($scope.results)
            })

            $scope.search_files = UserService.searchFiles($scope.search_term);
            $scope.search_files.$promise.then(function () {
                $scope.search_files.forEach(function (file) {
                    $scope.results.push(
                        {
                            "Name": file.Name,
                            "Description": file.Description,
                            "Link": file.Link,
                            "Type": "File"
                        })
                })
                $scope.agGridOptions.api.setRowData($scope.results)
            })

        }

        $scope.clearSearch = function () {
            $scope.search_term = "";
            $scope.searchResults = false;
            $scope.results = [];
            $scope.searching_search_term = "";
        }

        var getFileLink = function (params) {
            return '<div>' +
                '<a title="' + params.node.data.Name
                + '" href="' + params.node.data.Link + '" target=\"_blank\">'
                + params.node.data.Name + '</a>' +
                '</div>';
        };

        var agColumnDefs = [
            { field: 'Type', headerName: 'Type', width: 200, menuTabs: ['filterMenuTab'], sort: 'asc', filter: true },
            { field: 'Name', headerName: 'Match', width: 400, cellRenderer: getFileLink, menuTabs: ['filterMenuTab'], filter: 'text' },
            { field: 'Description', headerName: 'Description', width: 500, menuTabs: ['filterMenuTab'], filter: 'text' },

        ];

        $scope.changeFilterIcons = function (event) {
            // We need to call this onFilterModified and onBodyScroll, as the elements are removed/added
            // to the DOM when scrolling horizontally on wide datasets.
            // Can also pass $scope.dataAgGridOptions to this function rather than an event.
            let filters = $scope.agGridOptions.api.getFilterModel();

            for (let col of event.api.columnController.columnDefs) {
                for (const span of document.querySelectorAll("span")) {
                    if (span.textContent.includes(col.field) || span.textContent.includes(col.headerName)) {
                        // Naming conventions vary, so lets check all of these to see if the column has a filter
                        if (col.Label in filters || col.headerName in filters || col.field in filters) {
                            // If column has a filter change the icon color
                            // @ts-ignore
                            let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];
                            console.log(icon);
                            icon.style.color = "#007bff";
                        }
                        else {
                            // Remove the color prop from the icon if there is no filter
                            // @ts-ignore
                            let icon: HTMLElement = span.parentNode.parentNode.children[0].children[0];// filterIcon.children[0];
                            if (icon && icon.style) {
                                icon.style.color = "#bdbdbd";
                            }
                        }
                    }
                }
            }
        };

        $scope.agGridOptions = {
            suppressPropertyNamesCheck: true,
            animateRows: true,
            // showToolPanel: false,
            columnDefs: agColumnDefs,
            rowData: $scope.results,
            //onGridReady: function (params) {
            //    params.api.sizeColumnsToFit();
            //},
            onBodyScroll: function (event) {
                $scope.changeFilterIcons(event);
            },
            onFilterModified: function (event) {
                $scope.changeFilterIcons(event);
            },
            defaultColDef: {
                sortable: true,
                resizable: true,
                icons: {
                    menu: "<span class='material-symbols-outlined' id='filter-icon' title='Filter'>filter_alt</span>",
                    filter: "<span></span>",
                }
            },
        };

        var ag_grid_div = document.querySelector('#search-grid') as HTMLElement;    //get the container id...
        $scope.ag_grid = new Grid(ag_grid_div, $scope.agGridOptions); //bind the grid to it.


        $scope.mydatasets.$promise.then(function () {

            angular.forEach($scope.mydatasets, function (dataset, key) {
                //need to bump this to get the route
                DatasetService.configureDataset(dataset);
            });

            $scope.checkIfEmpty();

        });

        $scope.checkIfEmpty = function () {
            //console.dir($scope.myprojects);
            //console.dir($scope.mydatasets);
            if ($scope.myprojects.$resolved && $scope.mydatasets.$resolved) {
                if ($scope.myprojects.length == 0 && $scope.mydatasets.length == 0)
                    angular.rootScope.go("/projects");
            }
        };



    }];

export default landing_page;
